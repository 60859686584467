import React from 'react';
import { PlateContent, useEditorContainerRef, useEditorRef } from '@udecode/plate-common/react';
import type { PlateContentProps } from '@udecode/plate-common/react';


export const EditorContainer = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  const editor = useEditorRef();
  const containerRef = useEditorContainerRef();

  return (
    <div
      id={editor.uid}
      ref={containerRef}
      className={className}
      {...props}
    />
  );
};

const Editor = React.forwardRef<HTMLDivElement, PlateContentProps>(
  ({ className, disabled, readOnly, ...props }, ref) => {

    return (
      <div ref={ref} className="relative w-full">
        <PlateContent
          className='relative whitespace-pre-wrap break-words outline-none'
          disableDefaultStyles
          readOnly={disabled ?? readOnly}
          aria-disabled={disabled}
          {...props}
        // DOCUMENTATION: pass empty obj to scrollSelectionIntoView prop to disable the default focus behavior
        // CRITICAL NOTE: in case of void elements, {children} should be placed before the void code part
        />
      </div>
    );
  }
);
Editor.displayName = 'Editor';

export { Editor };

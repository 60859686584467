import React, { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { useLazyGetBrandImagesQuery, useLazyGetUploadedImagesQuery } from "../../../store/reducers/api/images/images";
import ImageCard from "../ImageCard/ImageCard";
import { BrandImage, UploadedImage } from "../../../store/reducers/api/api.types";
import { useAppSelector } from "../../../store/hooks/redux-hooks";
import BlankCard from "../ImageCard/BlankCard";
import PhIcon from "../../Icon/PhIcon";

interface DragItem {
  files: File[];
}

type Props = {
  setUploadedImage: (image: UploadedImage) => void;
  uploadImage: (file: File) => void;
  brandImages: boolean;
  setImageFromBrand: (image: BrandImage) => void;
  setProgrammaticImage: (image_url: string, image_source: string) => void;
  programmaticImage: boolean
}

const UploadTab: React.FC<Props> = ({
  setUploadedImage, uploadImage, brandImages, setProgrammaticImage, setImageFromBrand, programmaticImage
}) => {

  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([])
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)
  const [getUploadedImages] = useLazyGetUploadedImagesQuery()
  const [getBrandImages] = useLazyGetBrandImagesQuery()
  const inputRef: React.LegacyRef<HTMLInputElement> = useRef(null);
  const [accountImages, setAccountImages] = useState<BrandImage[]>([])
  const [buyerImages, setBuyerImages] = useState<BrandImage[]>([])
  const template = useAppSelector(state => state.page_navigation.template)
  const [showBrandImages, setShowBrandImages] = useState<string | null>(null)

  useEffect(() => {
    fetchUploadedImages()
  }, [])

  useEffect(() => {
    if (brandImages) {
      fetchBrandImages()
    }
  }, [brandImages])


  const fetchBrandImages = async () =>
    await getBrandImages()
      .then(({ data }) => {
        if (data) {
          setAccountImages(data.account_images.images)
          setBuyerImages(data.buyer_images.images)
        }
      })
      .catch((error) => console.log(`Error fetching brand images: ${error}`))

  const fetchUploadedImages = async () =>
    await getUploadedImages(page.toString())
      .then(({ data }) => {
        if (data) {
          const totalImages = data.total_images;
          const updatedImages = [...uploadedImages, ...data.images];
          setUploadedImages(updatedImages);
          if (updatedImages.length < totalImages) {
            setPage(page + 1)
            setHasMore(true)
          } else {
            setHasMore(false)
          }
        }
      }).catch((error) => console.log(`Error fetching uploaded images: ${error}`))

  const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      uploadImage(e.target.files[0])
    }
  };

  const handleUploadImageClick = () => inputRef.current?.click()

  const handleFileDrop = useCallback(async (_item: DragItem, monitor: DropTargetMonitor) => {
    if (monitor) {
      const dragItem = monitor.getItem() as DragItem;
      const imageFiles = dragItem.files.filter(file => file.type.startsWith('image/'));
      if (imageFiles.length === 0) {
        return;
      }
      const file = imageFiles[0] as File
      uploadImage(file)
    }
  }, []);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop: handleFileDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  }), [handleFileDrop]);

  return (
    <InfiniteScroll
      dataLength={uploadedImages.length}
      next={fetchUploadedImages}
      hasMore={hasMore}
      loader={<div className="text-sm brand-text text-center">Loading . . . </div>}
      endMessage={
        <div className="pt-4 mb-12 pb-8 brand-sub-text text-xs text-center">
          {uploadedImages.length === 0 ? "No uploaded images found." : "All images loaded"}
        </div>
      }
      scrollableTarget="scrollable-dialog-content-id"
    >
      <div className="px-6 pb-5 flex flex-col gap-4">
        <div
          ref={drop}
          className={`
          flex p-5 justify-between border border-dashed border-primary rounded-xl 
          ${isOver ? 'bg-[#3C82F633]' : 'bg-white'}
          `}>
          <div className="flex gap-4">
            <PhIcon name="uploadSimple" className="text-gray-400" size={38} />
            <div className="flex flex-col gap-2">
              <span className="brand-text font-semibold text-sm">Upload Image</span>
              <span className=" barnd-subtext text-xs">Add your image by clicking “Select from PC” or simply drag and drop it here</span>
            </div>
          </div>
          <input ref={inputRef} type="file" multiple={false} onChange={handleUploadChange} className="hidden" />
          <button onClick={handleUploadImageClick} className="brand_btn-primary" >
            Select from PC
          </button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {showBrandImages ?
            <>
              <BlankCard
                iconName="arrowUDownLeft"
                label="Back to library"
                onClick={setShowBrandImages.bind(setShowBrandImages, null)}
              />
              {showBrandImages === 'account'
                ? accountImages.map((image, i) => <ImageCard image={image} setImage={setImageFromBrand} key={i} />)
                : buyerImages.map((image, i) => <ImageCard image={image} setImage={setImageFromBrand} key={i} />)
              }
            </>
            :
            <>
              {
                template && programmaticImage && (
                  <>
                    <BlankCard
                      iconName="image"
                      label="Use Brand Logo"
                      onClick={setProgrammaticImage.bind(setProgrammaticImage, '{{BUYER_LOGO}}', 'brand')}
                    />
                    <BlankCard
                      iconName="user"
                      label="Use Profile Image"
                      onClick={setProgrammaticImage.bind(setProgrammaticImage, '{{PROFILE_IMAGE}}', null)}
                    />
                  </>
                )
              }
              {brandImages &&
                <>
                  {accountImages.length > 0 &&
                    <BlankCard
                      iconName="folder"
                      label="Your Brand"
                      onClick={setShowBrandImages.bind(setShowBrandImages, 'account')}
                    />
                  }
                  {buyerImages.length > 0 &&
                    <BlankCard
                      iconName="folder"
                      label="Buyer Brand"
                      onClick={setShowBrandImages.bind(setShowBrandImages, 'buyer')}
                    />
                  }
                </>
              }
              {uploadedImages.map((image, i) =>
                image.urls.small && <ImageCard image={image} setImage={setUploadedImage} key={i} />
              )}
            </>
          }
        </div>
      </div>
    </InfiniteScroll>
  )
}

export default UploadTab;
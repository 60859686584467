import React from "react";
import PhIcon, { PhIconName } from "../../../../../shared/Icon/PhIcon";


type Props = {
  onChange: (alignment: string) => void
  activeValue: string
  label?: string
  data: {
    key: string
    value: string
    icon?: string
  }[]
}


const AiSelector: React.FC<Props> = ({ onChange, activeValue, label, data }) => {
  return (
    <div className="flex flex-col gap-2">
      {label && <div className="brand-text text-sm font-medium">{label}</div>}
      <div className='p-1 border border-[#DFD9D980] rounded-xl bg-gray-100 flex items-center gap-2 w-full'>
        {data.map((item, i) =>
          <button
            key={i}
            onClick={onChange.bind(onChange, item.key)}
            className={`
            w-1/2 px-3 py-2 rounded-lg brand-font-family text-xs font-medium transition-all duration-300 ease-in-out flex items-center justify-center
            ${activeValue === item.key ? "text-white bg-secondary-dark" : " text-secondary-dark bg-transparent hover:bg-white"} 
          `}
          >
            {item.icon ? <PhIcon name={item.icon as PhIconName} size={16} className={`${activeValue === item.key ? "text-white" : " text-secondary-dark"}`} /> : item.value}
          </button>
        )}
      </div>
    </div>
  )
}

export default AiSelector;
import React from "react";

import PhIcon, { PhIconName } from "../Icon/PhIcon";
import Tooltip from "../ToolTip/Tooltip";

type Props = {
  icon: PhIconName
  onClick?: () => void
  disabled?: boolean
  variant?: 'default' | 'delete'
  size?: number
  tooltip: string
  tooltipSide?: 'top' | 'right' | 'bottom' | 'left'
  container?: string
}

const BlockSidebarTrigger: React.FC<Props> = ({ icon, onClick, disabled, variant = 'default', size = 16, tooltip, tooltipSide = 'right', container }) => {
  return (
    <Tooltip content={tooltip} side={tooltipSide} sideOffset={10} container={container}>
      <div
        onClick={onClick}
        className={`
          w-8 h-8 flex items-center justify-center hover:bg-gray-900 rounded-[6px] 
          ${disabled ? "cursor-not-allowed" : "cursor-pointer"} group/sidebarTrigger
          `}
      >
        <PhIcon
          name={icon}
          size={size}
          className={` 
        ${variant === 'delete' ? 'text-[#F46262]' : 'text-gray-300 group-hover/sidebarTrigger:text-white'} 
        `} />
      </div>
    </Tooltip>
  )
}

export default BlockSidebarTrigger;
import React from "react";

import { PlateElementEmbed } from "../../../plate-config/Plugins/Embed/Embed.plugin";
import { EmbedConfig, EmbedPlatform } from "../config/config";
import EmbedViewController from "./EmbedViewController/EmbedViewController";
import ResizableEmbed from "./ResizableEmbed/ResizableEmbed";


type Props = {
  element: PlateElementEmbed
  platform: EmbedConfig
  controlsVisible: boolean
}

const EmbedIframe: React.FC<Props> = ({ element, platform, controlsVisible }) => {

  const nonResizablePlatforms = [EmbedPlatform.Facebook, EmbedPlatform.Twitter, EmbedPlatform.TikTok]
  const isNonResizable = nonResizablePlatforms.includes(platform.name)

  return (
    <div className='w-full block'>
      <div
        className={`flex
  ${element.align === 'left' ? 'justify-start' : ''}
  ${element.align === 'center' ? 'justify-center' : ''}   
  ${element.align === 'right' ? 'justify-end' : ''}
  `}>
        {
          isNonResizable
            ?
            <div className={`rounded-xl overflow-hidden`}>
              <EmbedViewController platform={platform} element={element} />
            </div>
            :
            <ResizableEmbed element={element} platform={platform} isActive={controlsVisible} />
        }
      </div>
    </div>
  )
}

export default EmbedIframe
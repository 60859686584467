import React, { useEffect, useState } from 'react';
import { withRef } from '@udecode/cn';
import { findNode, getParentNode } from '@udecode/plate-common';
import { PlateElement, findNodePath, isEditorReadOnly } from '@udecode/plate-common/react';
import { useSelected } from 'slate-react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, horizontalListSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { createDefaultButtonElement } from '../../plate-config/Plugins/Button/Button.plugin';
import { PlateButtonGroupElement } from '../../plate-config/Plugins/Button/ButtonGroup.plugin';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { ColumnPlugin } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';
import { Popover, PopoverContent, PopoverAnchor } from "../../shared/Popover/Popover";
import PhIcon from '../../shared/Icon/PhIcon';
import ButtonGroupMenubar from './ButtonGroupMenubar/ButtonGroupMenubar';
import Tooltip from '../../shared/ToolTip/Tooltip';


const ButtonGroupElement = withRef<typeof PlateElement>(
  ({ children, editor, element, className, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const block = element as PlateButtonGroupElement
    const isReadOnly = isEditorReadOnly(editor)
    const selected = useSelected()
    const [open, setOpen] = useState(false)

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        if (!selected) {
          return setOpen(false)
        }
        const isChildMenuOpen = block.children.map(child => child.menuOpen).includes(true);
        if (isChildMenuOpen) {
          setOpen(false);
        } else {
          setOpen(selected);
        }
      }, 100);
      return () => clearTimeout(timeoutId);
    }, [block, selected]);

    const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const parentNode = getParentNode(editor, nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
    const childNotifications = block.children.map(child => {
      const missingUrl =
        (child.link_type === "external" && !child.url) ||
        (child.link_type === "internal" && !child.internal_page_id) ||
        (child.link_type === "resource" && !child.internal_resource_id && !child.internal_template_resource_slot_id)
      const titleLimit = !!child.content ? child.content?.length >= 30 : false
      return missingUrl || titleLimit
    })
    const hasNotifications = childNotifications.includes(true)

    const onButtonAdd = () => {
      const path = findNodePath(editor, block)!
      editor.insertNodes(createDefaultButtonElement(), { at: [...path, children.length] })
    }
    const preventDefault = (e: Event) => e.preventDefault();
    const onElClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (isReadOnly) return
      if (e.currentTarget === e.target) {
        setOpen(!open)
      }
    }
    const sensors = useSensors(
      useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
    const handleDragEnd = (event: any) => {
      const { active, over } = event;
      if (active.id && over.id && active.id !== over.id) {
        const oldIndex = block.children.findIndex(item => item.id === active.id);
        const newIndex = block.children.findIndex(item => item.id === over.id);
        if (oldIndex !== -1 && newIndex !== -1) {
          arrayMove(block.children, oldIndex, newIndex);
          const node = findNode(editor, { at: [], match: (n: any) => n.id === block.children[oldIndex].id })!
          const path = [...node[1]]
          editor.moveNodes({ at: node[1], to: path.slice(0, -1).concat(newIndex) })
        }
      }
    };

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={block}
        className={`
        rounded-lg transition-all duration-300 ease-in-out px-[33px] mx-[-33px] py-2
        ${!isReadOnly
            ? `${selected
              ? 'border-sky-50 border hover:border-sky-50'
              : 'hover:border-sky-50 border border-transparent'}`
            : ''
          } 
        ${isElColumnChild
            ? isLastColElement ? "mb-0" : "mb-4"
            : `page-block button-group-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
        ${className}`
        }
        data-id={block.id}
        {...props}
      >
        <Popover open={isReadOnly ? false : open} onOpenChange={setOpen}>
          <PopoverAnchor
            onClick={onElClick}
            className={`
            flex flex-wrap max-w-full items-center gap-2 relative
            ${block.align === 'center' ? 'justify-center' : ''}
            ${block.align === 'right' ? 'justify-end' : ''}
            `}
          >
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext
                items={block.children}
                strategy={horizontalListSortingStrategy}
              >
                {children}
              </SortableContext>
              {!isReadOnly && selected && children.length < 3 && (
                <Tooltip content={'Add Button'} side={'right'} sideOffset={10} container={block.id} >
                  <div
                    onClick={onButtonAdd}
                    className={`bg-white outline-none items-center shadow-md rounded rounded-s flex justify-center w-6 h-6 ${hasNotifications ? "mb-5" : ""}`}
                  >
                    <PhIcon name="plus" size={16} />
                  </div>
                </Tooltip>
              )}
            </DndContext>
          </PopoverAnchor>
          <PopoverContent
            onOpenAutoFocus={preventDefault}
            onCloseAutoFocus={preventDefault}
            onInteractOutside={setOpen.bind(setOpen, false)}
            side='top'
            sideOffset={5}
            align='center'
          >
            <ButtonGroupMenubar block={block} />
          </PopoverContent>
        </Popover>
      </PlateElement>
    )
  })

export default ButtonGroupElement

import React, { useEffect, useState } from 'react';
import { flip, offset, UseVirtualFloatingOptions } from '@udecode/plate-floating';
import {
  FloatingLinkUrlInput,
  LinkOpenButton,
  useFloatingLinkEdit,
  useFloatingLinkEditState,
  useFloatingLinkInsert,
  useFloatingLinkInsertState,
  LinkFloatingToolbarState,
} from '@udecode/plate-link/react';
import validator from 'validator';
import * as SeparatorPrimitive from '@radix-ui/react-separator';

import PhIcon from '../../../shared/Icon/PhIcon';

const floatingOptions: UseVirtualFloatingOptions = {
  placement: 'bottom-start',
  middleware: [
    offset(12),
    flip({
      padding: 12,
      fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
    }),
  ],
};

export interface LinkFloatingToolbarProps {
  state?: LinkFloatingToolbarState;
}

export function LinkFloatingToolbar({ state }: LinkFloatingToolbarProps) {
  const insertState = useFloatingLinkInsertState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  });
  const {
    props: insertProps,
    ref: insertRef,
    hidden,
    textInputProps,
  } = useFloatingLinkInsert(insertState);

  const editState = useFloatingLinkEditState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  });
  const {
    props: editProps,
    ref: editRef,
    editButtonProps,
    unlinkButtonProps,
  } = useFloatingLinkEdit(editState);

  const [inputValue, setInputValue] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (inputValue && !validator.isURL(inputValue, { require_protocol: true })) {
      setError("Invalid URL")
    } else {
      setError(null)
    }
  }, [inputValue])

  useEffect(() => {
    const updateInputValue = (event: Event) => {
      const target = event.target as HTMLInputElement;
      setInputValue(target.value.trim() ? target.value : null);
    };
    const inputElement = document.getElementById('link-input') as HTMLInputElement | null;
    if (inputElement) {
      inputElement.addEventListener('input', updateInputValue);
      return () => {
        inputElement.removeEventListener('input', updateInputValue);
      };
    }
  }, []);

  const input = (
    <div className="link_toolbar min-w-[315px] flex-col items-start" style={{ padding: '12px' }}>
      <div className="flex items-center gap-2 w-full">
        <div className="flex items-center">
          <PhIcon name='globe' size={16} color='brand-text' />
        </div>
        <FloatingLinkUrlInput
          id={'link-input'}
          className={`
          flex w-full rounded-md bg-transparent file:border-0 
          file:bg-background file:text-sm file:font-medium text-base unset_input
          focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50
          border-none focus-visible:ring-transparent focus:bg-gray-100 h-9 px-1 py-0`}
          placeholder="Paste link"
        />
      </div>
      {error && (
        <div className='flex items-center w-full gap-2 mt-1 animate-fadeIn bg-[#FB2C2C33] border-l-4 border-[#F46262] p-2'>
          <div className="flex items-center ">
            <PhIcon name='warning' size={16} className='text-[#F46262]' />
          </div>
          <span className='text-xs brand-text'>{error}</span>
        </div>
      )}
      <SeparatorPrimitive.Root orientation="horizontal" className='shrink-0 bg-black bg-opacity-10 h-[1px] w-full my-1' />
      <div className="flex items-center gap-2 w-full">
        <div className="flex items-center">
          <PhIcon name='pencil' size={16} color='brand-text' />
        </div>
        <input
          className={`
          flex w-full rounded-md bg-transparent file:border-0 
          file:bg-background file:text-sm file:font-medium text-base unset_input
          focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50
          border-none focus-visible:ring-transparent focus:bg-gray-100 h-9 px-1 py-0`}
          placeholder="Text to display"
          {...textInputProps}
        />
      </div>
    </div>
  );

  const editContent = editState.isEditing ? (
    input
  ) : (
    <div className="link_toolbar">
      <button
        type="button"
        className="link_toolbar_btn text-sm brand-text"
        {...editButtonProps}
      >
        Edit link
      </button>
      <SeparatorPrimitive.Root orientation="vertical" className='shrink-0 bg-black h-4 bg-opacity-10 w-[1px]' />
      <LinkOpenButton
        target='_blank'
        className="link_toolbar_btn"
      >
        <PhIcon name='arrowSquareOut' size={16} color='text-blue-500' />
      </LinkOpenButton>
      <SeparatorPrimitive.Root orientation="vertical" className='shrink-0 bg-black h-4 bg-opacity-10 w-[1px]' />
      <button
        type="button"
        className="link_toolbar_btn"
        {...unlinkButtonProps}
      >
        <PhIcon name='linkBreak' size={16} color='brand-text' />
      </button>
    </div>
  );

  if (hidden) return null;

  return (
    <>
      <div ref={insertRef}  {...insertProps} >
        {input}
      </div>
      <div ref={editRef} {...editProps} >
        {editContent}
      </div>
    </>
  );
}

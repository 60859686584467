import React, { useRef, useState } from "react";

import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import BuilderNavMenu from "../../../../shared/BuilderNavMenu/BuilderNavMenu";
import BuilderNavMenuToolbar from "../../../../shared/BuilderNavMenu/BuilderNavMenuToolbar/BuilderNavMenuToolbar";
import { useUpdateNavLinksAttributeMutation } from "../../../../store/reducers/api/navbar/navbar.api";

type Props = {
  navWidth: number
}

const Menu: React.FC<Props> = ({ navWidth }) => {
  const [value, setValue] = useState<string>("");
  const [isHovered, setIsHovered] = useState(false)
  const { footer_menu_buttons, footer_text_color, nav_router, template } = useAppSelector(state => state.page_navigation)
  const { readOnly, isLandingPage, editorContainerWidth } = useAppSelector(state => state.page_addendums)
  const menuRef = useRef<HTMLDivElement>(null);
  const [updateNavLinksAttribute, { isLoading }] = useUpdateNavLinksAttributeMutation()

  const onLinkAdd = async () => await updateNavLinksAttribute([
    ...footer_menu_buttons,
    {
      background_color: null, href: null, kind: "footer", position: footer_menu_buttons.length,
      text: null, text_color: null, url_type: null, anchor_page_id: null
    }
  ])
  const onLinkDelete = async (id: string) => {
    const updatedLinks = footer_menu_buttons.map(btn => btn.id === id ? { ...btn, _destroy: true } : btn)
    await updateNavLinksAttribute(updatedLinks)
  }
  const onOrderUpdate = async (items: any) => {
    const updatedLinks = items.map((item: any, index: number) => ({ ...item, position: index }))
    await updateNavLinksAttribute(updatedLinks)
  }

  const onLinkUpdate = async (id: string, title: string | null, url_type: string, section_id?: string, page_id?: string, anchor_page_id?: string) => {
    const updatedLinks = footer_menu_buttons.map(btn => btn.id === id
      ? {
        ...btn,
        text: title,
        url_type,
        href: url_type === 'section' ? section_id! : page_id!,
        anchor_page_id: anchor_page_id ? anchor_page_id : null
      }
      : btn)
    await updateNavLinksAttribute(updatedLinks)
  }

  const readOnlyMode = readOnly || isLandingPage
  const itemsToDisplay = readOnlyMode
    ? footer_menu_buttons.filter(link => {
      if (link.href && link.url_type === 'section') {
        if (template) {
          return true
        }
        const page = nav_router.find(page => page.id === link.anchor_page_id)
        return page && (isLandingPage ? page.status === 'active' : page.status !== 'building')
      }
      if (link.href && link.url_type === 'page') {
        const page = nav_router.find(page => page.id === link.href)
        return page && (isLandingPage ? page.status === 'active' : page.status !== 'building')
      }
      return false
    })
    : footer_menu_buttons
  const textColor = footer_text_color ? `${footer_text_color}` : 'text-white';
  const shouldCollapse = navWidth > 0 ? navWidth - 150 < itemsToDisplay.length * 112 : false
  const isMobile = editorContainerWidth === 'mobile' || shouldCollapse

  return (
    <section
      className="flex justify-center group/nav_menu min-h-10"
      onMouseEnter={setIsHovered.bind(setIsHovered, true)}
      onMouseLeave={setIsHovered.bind(setIsHovered, false)}
      ref={menuRef}
    >
      <div
        className={`
        flex w-full items-center relative justify-center rounded-lg px-8 py-2 min-w-[266px]
        border border-transparent ${readOnlyMode ? "" : "hover:border-[#3C82F699]"}
        `}
      >
        <BuilderNavMenu
          itemsToDisplay={itemsToDisplay}
          onOrderUpdate={onOrderUpdate}
          text_color={textColor}
          onLinkDelete={onLinkDelete}
          onLinkUpdate={onLinkUpdate}
          isMobile={isMobile}
          isLoading={isLoading}
        />
        {
          !readOnlyMode && !itemsToDisplay.length &&
          <span className={`text-sm ${textColor} cursor-pointer`} onClick={setValue.bind(setValue, "footer_menu")} >
            Click To Manage Menu Links
          </span>
        }
        {!readOnlyMode &&
          <BuilderNavMenuToolbar
            value={value}
            setValue={setValue}
            showTrigger={isHovered}
            onLinkAdd={onLinkAdd}
            onOrderUpdate={onOrderUpdate}
            onLinkDelete={onLinkDelete}
            itemsToDisplay={itemsToDisplay}
            isLoading={isLoading}
          />
        }
      </div>
    </section>
  );
}

export default Menu;
import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as MenuBar from "@radix-ui/react-menubar";

import PhIcon from '../../../shared/Icon/PhIcon';
import { useAppSelector } from '../../../store/hooks/redux-hooks';
import { Popover, PopoverContent, PopoverTrigger, PopoverPortal } from "../../../shared/Popover/Popover";
import BlockMenubarTrigger from '../../BlockMenubarControls/BlockMenubarTrigger';
import BlockMenubarContent from '../../BlockMenubarControls/BlockMenubarContent';
import BlockMenubarSelector from '../../BlockMenubarControls/BlockMenubarSelector';
import AnchorSelector from './AnchorSelector/AnchorSelector';
import { NavigationLink } from '../../../store/reducers/api/api.types';
import ToolbarSeparator from '../../ToolbarControls/ToolbarSeparator';
import BlockMenubarInput from '../../BlockMenubarControls/BlockMenubarInput';

interface MenuListItemProps {
  item: NavigationLink,
  text_color: string,
  onLinkDelete: (id: string) => void,
  onLinkUpdate: (id: string, title: string | null, url_type: string, section_id?: string, page_id?: string, anchor_page_id?: string) => void,
  isLoading?: boolean,
  closeMenu?: () => void
}

const MenuListItem: React.FC<MenuListItemProps> = ({
  item, text_color, onLinkDelete, onLinkUpdate, isLoading, closeMenu
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({ id: item.id! });
  const [open, setOpen] = useState(false);
  const { readOnly, isLandingPage } = useAppSelector(state => state.page_addendums)
  const { nav_router, template, current_page_id } = useAppSelector(state => state.page_navigation)
  const [value, setValue] = useState<string>("");
  const [title, setTitle] = useState(item.text || '')
  const [urlType, setUrlType] = useState(item.url_type || 'section')
  const [href, setHref] = useState(item.href || '')
  const [anchorPageId, setAnchorPageId] = useState(item.anchor_page_id)

  const textColor = text_color ? `${text_color}` : 'text-black';
  const style = { transform: CSS.Transform.toString(transform), transition };
  const readOnlyMode = readOnly || isLandingPage

  const onNavLinkClick = () => {
    if (!readOnlyMode) return;
    if (item.href && item.url_type === 'section') {
      const activeRoute = nav_router.find(route => route.id === item.anchor_page_id)
      if (activeRoute) {
        window.location.href = isLandingPage ? `${activeRoute.landing_link}#${item.href}` : `${activeRoute.builder_link}#${item.href}`
        !isLandingPage && window.history.pushState("", document.title, window.location.pathname + window.location.search);
        closeMenu && closeMenu()
      }
      return
    }
    if (item.href && item.url_type === 'page') {
      const activeRoute = nav_router.find(route => route.id === item.href)
      if (activeRoute) {
        window.location.href = isLandingPage ? activeRoute.landing_link : activeRoute.builder_link
        closeMenu && closeMenu()
      }
    }
  }
  const preventDefault = (e: Event) => e.preventDefault();
  const onSubmit = () => {
    const updatedTitle = !!title && title.trim().length > 0 ? title : null;
    const section_id = urlType === 'section' ? href : undefined;
    const page_id = urlType === 'page' ? href : undefined;
    const anchor_page_id = anchorPageId || undefined
    onLinkUpdate(item.id!, updatedTitle, urlType, section_id, page_id, anchor_page_id)
  }
  const onTitleChange = (title: string | null) => setTitle(title || '')
  const onTypeChange = (url_type: string) => {
    setUrlType(url_type)
    if (url_type === 'section') {
      setAnchorPageId(template ? null : current_page_id!)
    } else {
      setAnchorPageId(null)
    }
  }
  const onHrefChange = (href: string) => {
    setHref(href)
    if (urlType === 'section') {
      setAnchorPageId(template ? null : current_page_id!)
    } else {
      setAnchorPageId(null)
    }
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="flex items-center justify-center gap-1 select-none group/nav_menu_item relative"
      id={item.id!}
    >
      {!readOnlyMode &&
        <div
          {...listeners}
          ref={setActivatorNodeRef}
          className={`absolute top-[-2px] left-[-27px] cursor-grab w-6 h-6 rounded-[4px] 
            bg-white shadow-md flex justify-center items-center outline-none opacity-0 
            group-hover/nav_menu_item:opacity-100 transition-opacity duration-200
        `}
          onClick={setOpen.bind(setOpen, !open)}
        >
          <PhIcon name="dotsSixVertical" size={16} className='brand-text' weight='bold' />
        </div>
      }
      <span
        onClick={onNavLinkClick}
        className={`${textColor} text-sm font-medium text-nowrap paragraph-font-family ${readOnlyMode ? "cursor-pointer" : "cursor-default"}`}>
        {item.text?.trim() ? item.text : 'Link'}
      </span>
      <Popover open={readOnlyMode ? false : open} onOpenChange={setOpen}>
        <PopoverTrigger asChild><div className="absolute top-0 left-0" /></PopoverTrigger>
        <PopoverPortal>
          <PopoverContent
            onOpenAutoFocus={preventDefault}
            onCloseAutoFocus={preventDefault}
            side='top'
            align="start"
            alignOffset={15}
          >
            <MenuBar.Root value={value} onValueChange={setValue} className="element_topbar_relative_wrapper" >
              <MenuBar.Menu value="menu_link-url">
                <BlockMenubarTrigger
                  isDropdown
                  labelIcon='link'
                  isOpened={value === 'menu-link'}
                  tooltip='Link'
                  containerId={item.id!}
                />
                <MenuBar.Portal>
                  <BlockMenubarContent itemClassName='w-[270px]'>
                    <BlockMenubarInput
                      label="Title"
                      maxLength={15}
                      onChange={onTitleChange}
                      value={title}
                      disabled={isLoading}
                    />
                    {!template &&
                      <BlockMenubarSelector
                        activeKey={urlType}
                        onSelect={onTypeChange}
                        data={[{ key: 'page', value: 'Page' }, { key: 'section', value: 'Anchor' }]}
                      />}
                    <AnchorSelector url_type={urlType} href={href} anchorPageId={anchorPageId} onHrefChange={onHrefChange} />
                    <ToolbarSeparator variant="horizontal" />
                    <button disabled={isLoading} onClick={onSubmit} className="brand_btn-primary w-full">
                      Apply
                    </button>
                  </BlockMenubarContent>
                </MenuBar.Portal>
              </MenuBar.Menu>
              <MenuBar.Separator className="element_topbar_separator" />
              <MenuBar.Menu value="menu_link-delete">
                <BlockMenubarTrigger
                  labelIcon="trash"
                  onClick={onLinkDelete.bind(onLinkDelete, item.id)}
                  isDelete
                  tooltip='Delete'
                  containerId={item.id!}
                />
              </MenuBar.Menu>
            </MenuBar.Root>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </div>
  );
};

export default MenuListItem;
import React, { useRef, useState } from "react";
import { isEditorReadOnly, useEditorRef } from "@udecode/plate-common/react";
import { findNode, getNodeParent } from "@udecode/plate-common";

import { PlateTestimonialElement } from "../../../plate-config/Plugins/Testimonial/Testimonial.plugin";
import PhIcon from "../../../shared/Icon/PhIcon";
import ImagePicker from "../../../shared/ImagePicker/ImagePicker";
import { useAppSelector } from "../../../store/hooks/redux-hooks";
import { colorClassToRGB } from "../../../utils/color.util";
import { PlateTestimonialGroupElement } from "../../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin";
import AutoHeightTextarea from "../../../shared/AutoHeightTextarea/AutoHeightTextarea";
import { useElementWidth } from "../../../hooks/useElementWidth";


type Props = {
  block: PlateTestimonialElement
}

const CardLayout: React.FC<Props> = ({ block }) => {
  const editor = useEditorRef()
  const [showImagePicker, setShowImagePicker] = useState(false)
  const template = useAppSelector(state => state.page_navigation.template)
  const [testimonial, setTestimonial] = useState(block.content as string)
  const [userName, setUserName] = useState(block.user_name as string)
  const [userPosition, setUserPosition] = useState(block.user_position as string)
  const activePalette = useAppSelector(state => state.page_colors.activePalette)
  const isReadOnly = isEditorReadOnly(editor)
  const parentRef = useRef<HTMLImageElement>(null)
  const parentWidth = useElementWidth(parentRef)

  const node = findNode(editor, { at: [], match: { id: block.id } })!
  const parentNode = getNodeParent(editor, node[1]) as PlateTestimonialGroupElement;
  const bg_color = parentNode.card_bg_color as string || 'color-white'
  const bg_opacity = typeof parentNode.card_bg_opacity === 'number' ? parentNode.card_bg_opacity : 100
  const textColor = parentNode.text_color ? parentNode.text_color : 'text-black'

  const setImageFromPicker = (image_url: string, image_source: string, image_external_id: string) =>
    editor.setNodes(
      { image_external_id, image_source, image_url } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onImageUnset = () =>
    editor.setNodes(
      { image_external_id: null, image_source: null, image_url: null, } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onTestimonialChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTestimonial(e.target.value)
    editor.setNodes(
      { content: e.target.value } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  }
  const onUserNameChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserName(e.target.value)
    editor.setNodes(
      { user_name: e.target.value } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  }
  const onUserPositionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserPosition(e.target.value)
    editor.setNodes(
      { user_position: e.target.value } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  }
  const getInitials = (fullName: string): string => {
    const names = fullName.split(' ');
    let initials = '';
    for (const name of names) {
      if (name.length > 0) {
        initials += name[0];
      }
    }
    return initials.slice(0, 2).toUpperCase();
  }

  return (
    <div
      style={{ backgroundColor: colorClassToRGB(bg_color, bg_opacity, activePalette.colors)! }}
      className="flex items-center flex-col p-4 rounded-2xl w-full border border-black/20 shadow-md"
      ref={parentRef}
    >
      <div className="flex w-full gap-2">
        <div
          onClick={setShowImagePicker.bind(setShowImagePicker, true)}
          className={`
            w-[56px] h-[56px] min-w-[56px] min-h-[56px] flex items-center gap-4 flex-col bg-gray-100 
            rounded-full justify-center ${isReadOnly ? "" : "cursor-pointer"}`}
        >
          {
            block.image_url
              ?
              <img
                src={block.image_url}
                alt="img"
                className={`w-[56px] h-[56px] rounded-full ${isReadOnly ? "" : "cursor-pointer"} object-cover`}
                draggable={false}
              />
              :
              <>
                {
                  isReadOnly
                    ?
                    <div className="text-2xl brand-subtext capitalize">
                      {block.user_name ? getInitials(block.user_name) : ""}
                    </div>
                    : <PhIcon name='fileImage' size={20} className="brand-subtext" />
                }
              </>
          }
        </div>
        <div className="flex flex-col flex-grow gap-1 p-1">
          <AutoHeightTextarea
            value={userName || ''}
            placeholder={'Name Surname'}
            onChange={onUserNameChange}
            textClasses={`${textColor} text-base unset_input header-font-family`}
            disabled={isReadOnly}
            paramToUpdate={parentWidth}
          />
          <AutoHeightTextarea
            value={userPosition || ''}
            placeholder={'Position'}
            onChange={onUserPositionChange}
            textClasses={`${textColor} text-base  unset_input paragraph-font-family`}
            disabled={isReadOnly}
            paramToUpdate={parentWidth}
          />
        </div>
      </div>
      <div className="p-1 flex w-full">
        <AutoHeightTextarea
          value={testimonial || ''}
          onChange={onTestimonialChange}
          textClasses={`${textColor} text-base unset_input paragraph-font-family`}
          placeholder='Type testimonial here...'
          disabled={isReadOnly}
        />
      </div>
      <ImagePicker
        currentImageUrl={block.image_url as string}
        backgroundImageSource={block.image_source as string}
        modalOpen={showImagePicker}
        onImageChange={setImageFromPicker}
        onImageUnset={onImageUnset}
        onModalClose={setShowImagePicker.bind(setShowImagePicker, false)}
        brandImages={!template}
      />
    </div>
  )
}

export default CardLayout
import React, { useState } from 'react';
import { withRef } from '@udecode/cn';
import { PlateElement } from '@udecode/plate-common/react';
import { getMentionOnSelectItem } from '@udecode/plate-mention';
import {
  InlineCombobox,
  InlineComboboxContent,
  InlineComboboxEmpty,
  InlineComboboxInput,
  InlineComboboxItem,
} from '../InlineCombobox/InlineCombobox';

import { useAppSelector } from '../../store/hooks/redux-hooks';

const onSelectItem = getMentionOnSelectItem();

export const MentionInputElement = withRef<typeof PlateElement>(
  ({ className, ...props }, ref) => {
    const { children, editor, element } = props;
    const [search, setSearch] = useState('');

    const contacts = useAppSelector(state => state.page_contacts)
    const contactsToMention = [...contacts.contacts, ...contacts.users].map(contact => ({ key: contact.id, text: contact.name }))

    const getInitials = (name: string) => {
      let parts = name.split(' ');
      if (parts.length === 1) {
        return parts[0][0].toUpperCase();
      } else {
        return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase();
      }
    }

    return (
      <PlateElement
        as="span"
        data-id={element.id}
        data-slate-value={element.value}
        ref={ref}
        {...props}
        className={`rounded-lg p-1 bg-gray-100 ${className}`}
        style={{ fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit' }}
      >
        <InlineCombobox
          element={element}
          setValue={setSearch}
          showTrigger={false}
          trigger="@"
          value={search}
        >
          <InlineComboboxInput placeholder='Select User' />
          <InlineComboboxContent className="max-h-[240px] max-w-[260px] border border-white/20 rounded-xl p-3">
            <InlineComboboxEmpty>No results found</InlineComboboxEmpty>
            {contactsToMention.map((item) => (
              <InlineComboboxItem
                key={item.key}
                onClick={() => onSelectItem(editor, { text: item.key }, search)}
                value={item.key}
                className='rounded-lg p-0'
              >
                <div className="p-2 gap-[6px] flex w-full items-center">
                  <div
                    className='border border-gray-300 py-[5px] px-[8px] rounded-full text-black'
                    style={{ fontSize: '10px' }}
                  >
                    {getInitials(item.text)}
                  </div>
                  <div className="text-xs text-black">{item.text}</div>
                </div>
              </InlineComboboxItem>
            ))}
          </InlineComboboxContent>
        </InlineCombobox>
        {children}
      </PlateElement>
    );
  }
);

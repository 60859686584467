import React from "react";

import SwitchControl from "../SwitchControl/SwitchControl";

type Props = {
  value: boolean;
  onChange: (val: boolean) => void;
  label: string;
}

const BlockMenubarToggle: React.FC<Props> = ({ value, onChange, label }) => {

  const onValueChangeHandler = (val: boolean) => onChange(val);

  return (
    <div className="flex items-center justify-between">
      <span className="brand-text font-medium text-sm cursor-default" >{label}</span>
      <SwitchControl
        checked={value}
        onChange={onValueChangeHandler}
      />
    </div>
  );
}

export default BlockMenubarToggle;
import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import * as Accordion from "@radix-ui/react-accordion";

import ColorOption from "./ColorOption/ColorOption";
import FontOption from "./FontOption/FontOption";
import PhIcon from "../../../shared/Icon/PhIcon";
import Tooltip from "../../../shared/ToolTip/Tooltip";


const SettingsDropdown: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [activeOption, setActiveOption] = useState('color');

  const preventDefault = (e: React.MouseEvent) => e.preventDefault();

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild className="focus-visible:outline-none">
        <div>
          <Tooltip content={'Styles'} side={'bottom'} sideOffset={5} container="nav_tooltip_anchor" >
            <button className="brand_btn-secondary w-11 h-11 min-w-11" style={{ padding: 0 }}>
              <PhIcon name="faders" size={18} className="text-gray-900" />
            </button>
          </Tooltip>
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          className="w-[280px] flex flex-col items-center rounded-lg shadow-md bg-white z-[4]"
        >
          <DropdownMenu.Item
            onClick={preventDefault}
            className="outline-none flex items-center justify-between gap-[10px] w-full p-4"
          >
            <span className="brand-text text-sm font-medium cursor-default">Variables & Styles</span>
            <div onClick={setOpen.bind(setOpen, !open)} className="cursor-pointer" >
              <PhIcon name="x" size={16} className="brand-text" />
            </div>
          </DropdownMenu.Item>
          <DropdownMenu.Item className="outline-none w-full" onClick={preventDefault}>
            <Accordion.Root
              type="single"
              collapsible
              orientation="horizontal"
              className="w-full"
              value={activeOption}
              onValueChange={setActiveOption}
            >
              <Accordion.Item className="w-full p-4 border-b border-t border-[#D9D9D9]" value="color">
                <Accordion.Trigger className="flex items-center justify-between w-full accordion_trigger">
                  <span className="brand-text text-sm font-medium">Global Colors</span>
                  <div className="accordion_chevron cursor-pointer">
                    <PhIcon name="caretUp" className="brand-text" size={16} />
                  </div>
                </Accordion.Trigger>
                <Accordion.Content className="flex gap-4 flex-col">
                  <ColorOption />
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item className="w-full p-4" value="font">
                <Accordion.Trigger className="flex items-center justify-between w-full accordion_trigger">
                  <span className="brand-text text-sm font-medium">Global Font</span>
                  <div className="accordion_chevron cursor-pointer">
                    <PhIcon name="caretUp" className="brand-text" size={16} />
                  </div>
                </Accordion.Trigger>
                <Accordion.Content>
                  <FontOption />
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root >
  );
}

export default SettingsDropdown;
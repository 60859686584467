import React from "react"

import { PlateElementAiTextBlock } from "../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin"
import ListItem from "../ListItem/ListItem"

type Props = {
  element: PlateElementAiTextBlock
}


const Sources: React.FC<Props> = ({ element }) => {

  const usedSources = [
    ...(element.details.source_buyer_summary ? ["Company Summary"] : []),
    ...(element.details.source_buyer_contact ? ["Contact Summary"] : []),
    ...(element.details.source_persona ? ["Buyer Persona"] : []),
    ...(element.details.source_buyer_industry_challenges ? ["Industry Challenges"] : []),
    ...(element.details.source_seller_summary ? ["Seller Summary"] : []),
    ...(element.details.source_seller_benefits ? ["Seller Benefits"] : []),
    ...(element.details.source_seller_testimonials ? ["Seller Testimonials"] : [])
  ]

  return (
    <ListItem label="Sources" value={!!usedSources.length
      ?
      <ul>
        {usedSources.map((source, index) => (
          <li key={index}>
            {source}
          </li>
        ))}
      </ul>
      : "No sources used"
    }
    />
  )
}

export default Sources;

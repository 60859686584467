import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import TimeAgo from 'react-timeago'
import timeAgoEnStrings from 'react-timeago/lib/language-strings/en'
import timeAgoBuildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import { useAppSelector } from "../../../store/hooks/redux-hooks";
import { useSaveSectionDataMutation } from "../../../store/reducers/api/sections/sections";
import PhIcon from "../../../shared/Icon/PhIcon";
import { NavRouter } from "../../../store/reducers/page_navigation/page_navigation";
import FullScreenSpinner from "../../../shared/LoadingComponents/FullScreenSpinner";
import Tooltip from "../../../shared/ToolTip/Tooltip";

type Props = {
  hideRouteName: boolean
}

const NavigationDropdown: React.FC<Props> = ({ hideRouteName }) => {
  const { nav_router, room_slug } = useAppSelector((state) => state.page_navigation);
  const { sections, lastUpdated } = useAppSelector(state => state.page_sections)
  const [saveEditorStateToApi, { isLoading }] = useSaveSectionDataMutation()
  const [open, setOpen] = useState(false)

  const active_route = nav_router.find((route) => route.isActive);
  const route_name = active_route?.name || 'Page';
  const addPagePath = room_slug ? `/rooms/${room_slug}/new_page/templates` : '/'
  const timeAgoFormatter = timeAgoBuildFormatter(timeAgoEnStrings)
  const routeNameShortcut = route_name.length > 10 ? `${route_name.slice(0, 10)}...` : route_name;

  const preventDefault = (e: Event) => e.preventDefault();
  const onAddNewPage = () => window.open(addPagePath as string, '_blank', 'noopener,noreferrer');
  const onRouteOpen = (route: NavRouter) => window.location.href = route.builder_link
  const syncWithAPI = async () => await saveEditorStateToApi(sections)

  return (
    <>
      <DropdownMenu.Root open={open} onOpenChange={setOpen} >
        <DropdownMenu.Trigger asChild>
          <button className={`p-2 h-11 rounded-xl border bg-white ${open ? "active_input" : "border-gray-300 hover:bg-gray-100"} outline-none`} >
            <Tooltip content={'Navigation'} side={'bottom'} sideOffset={17} container={'nav_tooltip_anchor'} >
              <div className="flex gap-2 items-center">
                <PhIcon name="filePlus" size={18} color="brand-text" />
                <span className={`text-black text-sm font-medium whitespace-nowrap ${hideRouteName ? "hidden" : ""}`} >
                  {routeNameShortcut}
                </span>
                <PhIcon name="caretDown" size={18} color="brand-text" />
              </div>
            </Tooltip>
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            side="bottom"
            sideOffset={5}
            className="flex flex-col items-center rounded-lg shadow-md bg-white p-2 z-[4] min-w-[280px] gap-2"
          >
            {
              nav_router.map((route, i) => (
                <DropdownMenu.Item key={i} className="outline-none w-full" onSelect={preventDefault} id={route.id}>
                  <button
                    className={`nav_dropdown_btn brand-font-family ${route.isActive ? "active" : ""}`}
                    onClick={onRouteOpen.bind(onRouteOpen, route)}
                    disabled={route.status === 'building' || route.isActive || route.status === 'missing_info'}
                  >
                    {route.status === 'building' &&
                      <Tooltip content={'Page is still building. Refresh page to see if page is ready.'} side={'top'} sideOffset={10} container={route.id} >
                        <div className="flex w-full justify-between">
                          <span className="text-sm text-[#373737]">
                            {route.name.length > 20 ? `${route.name.slice(0, 25)}...` : route.name}
                          </span>
                          <PhIcon name="spinnerGap" size={16} className="text-gray-500" />
                        </div>
                      </Tooltip>
                    }
                    {route.status === 'draft' &&
                      <Tooltip content={'Page is draft. Will be hidden from your prospects.'} side={'top'} sideOffset={10} container={route.id} >
                        <div className="flex w-full justify-between cursor-pointer">
                          <span className="text-sm text-gray-500 ">
                            {route.name.length > 20 ? `${route.name.slice(0, 25)}...` : route.name}
                          </span>
                          <span className="text-gray-400 text-xs">Draft</span>
                        </div>
                      </Tooltip>
                    }
                    {route.status === 'missing_info' &&
                      <Tooltip content={'Page is not build yet. Missing Information.'} side={'top'} sideOffset={10} container={route.id} >
                        <div className="flex w-full justify-between">
                          <span className="text-sm text-[#373737]">
                            {route.name.length > 20 ? `${route.name.slice(0, 25)}...` : route.name}
                          </span>
                          <PhIcon name="warning" size={16} className="text-gray-500" />
                        </div>
                      </Tooltip>
                    }
                    {route.status !== 'draft' && route.status !== 'building' && route.status !== 'missing_info' &&
                      <div className="flex w-full justify-between cursor-pointer">
                        <span className="text-sm text-[#373737]">{route.name.length > 25 ? `${route.name.slice(0, 25)}...` : route.name}</span>
                        {route.isActive && <PhIcon name="check" size={16} className="text-[#777777]" />}
                      </div>
                    }
                  </button>
                </DropdownMenu.Item >
              ))
            }
            <DropdownMenu.Separator className="bg-gray-300 h-[1px] w-full" />
            <DropdownMenu.Item className="outline-none w-full" onSelect={preventDefault}>
              <button
                className="brand_btn-secondary flex flex-col w-full"
                onClick={syncWithAPI}
                disabled={isLoading}
              >
                <span className="brand-text text-sm font-medium gap-[2px]">Save Page</span>
                <span className="text-gray-500 brand-font-family text-xs"> Last Saved: &nbsp;
                  <TimeAgo live={true} date={lastUpdated} formatter={timeAgoFormatter} />
                </span>
              </button>
            </DropdownMenu.Item>
            <DropdownMenu.Item className="outline-none w-full" onSelect={preventDefault}>
              <button className="brand_btn-primary w-full" onClick={onAddNewPage} disabled={isLoading} >
                <PhIcon name="plus" size={16} className="mr-2" /> New Page
              </button>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      {isLoading && <FullScreenSpinner />}
    </>
  );
}

export default NavigationDropdown;
import React, { useEffect } from 'react';
import WebFont from 'webfontloader';

import { useAppSelector } from '../../store/hooks/redux-hooks';
import { generateGlobalColorVariables, generateGlobalFontVariables } from '../../utils/variables.util';

type Props = {
  children: React.ReactNode
}

const GlobalStylesLoader = ({ children }: Props) => {
  const page_fonts = useAppSelector(state => state.page_fonts);
  const page_colors = useAppSelector(state => state.page_colors);
  const { paragraph_font, paragraphAvailableWeights, header_font, headerAvailableWeights } = page_fonts
  useEffect(() => {
    // This should prevent unlimited amount of connections to google fonts
    const removeGoogleFontsLinks = () => {
      const links = document.head.querySelectorAll('link[rel="stylesheet"][href^="https://fonts.googleapis.com"]');
      links.forEach(link => {
        document.head.removeChild(link);
      });
    };
    removeGoogleFontsLinks();
    WebFont.load({
      google: {
        families: [
          'DM Sans:100,200,300,400,500,600,700,800,900',
          'Libre Franklin:100,200,300,400,500,600,700,800,900',
          `${paragraph_font}:${paragraphAvailableWeights.join(',')}`,
          `${header_font}:${headerAvailableWeights.join(',')}`
        ]
      },
      classes: false,
    });
  }, [
    paragraph_font,
    paragraphAvailableWeights,
    header_font,
    headerAvailableWeights
  ]);
  const fontStyles = generateGlobalFontVariables(page_fonts)
  const colorStyles = generateGlobalColorVariables(page_colors.activePalette)
  const allStyles = { ...fontStyles, ...colorStyles };

  useEffect(() => {
    Object.entries(allStyles).forEach(([key, value]) => {
      document.body.style.setProperty(key, value as any);
    });
    return () => {
      Object.keys(allStyles).forEach(key => {
        document.body.style.removeProperty(key);
      });
    };
  }, [allStyles]);
  return <> {children} </>
}

export default GlobalStylesLoader
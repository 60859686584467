import React from "react";

import PhIcon from "../../../shared/Icon/PhIcon";

type Props = {
  onModalClose: () => void;
}

const LibraryHeader: React.FC<Props> = ({ onModalClose }) => {
  return (
    <div className="p-5 flex justify-between border-b border-gray-300">
      <span className="brand-text text-base font-semibold">Section Templates</span>
      <div onClick={onModalClose} >
        <PhIcon name="x" className="brand-text" />
      </div>
    </div>
  )
}

export default LibraryHeader;
import React, { useState } from "react";
import validator from 'validator';

import { useAppSelector } from "../../store/hooks/redux-hooks";
import PhIcon from "../Icon/PhIcon";
import BlockMenubarSelector from "./BlockMenubarSelector";

type Props = {
  linkType: string | null;
  url: string | null;
  onExternalUrlChange: (url: string) => void;
  internal_page_id: string | null;
  onInternalPageIdChange: (pageId: string) => void;
  onDynamicUrlChange: (value: string) => void;
  internal_template_resource_slot_id: string | null;
  onInternalTemplateResourceSlotIdChange: (resourceSlotId: string) => void;
  internal_resource_id: string | null;
  onInternalResourceIdChange: (resourceId: string) => void;
}

const BlockMenubarLink: React.FC<Props> = ({
  linkType, url, onExternalUrlChange, internal_page_id, internal_template_resource_slot_id, internal_resource_id,
  onInternalPageIdChange, onDynamicUrlChange, onInternalTemplateResourceSlotIdChange, onInternalResourceIdChange
}) => {
  const { resource_slots, resources } = useAppSelector(state => state.page_resources)
  const { all_pages, current_page_id } = useAppSelector(state => state.page_navigation)
  const template = useAppSelector(state => state.page_navigation.template)
  const [linkVariant, setLinkVariant] = useState(linkType || "external")
  const [externalUrl, setExternalUrl] = useState<string>(linkVariant === "external" && typeof url == 'string' ? url : '')
  const [error, setError] = useState<string | null>(null)

  const activePages = all_pages.filter(page => page.status === 'active');
  const LINK_OPTIONS = [
    { key: "external", value: "Link" },
    ...(template ? [{ key: "dynamic", value: "Dynamic" }] : [{ key: "internal", value: "Pages" }]),
    ...(resources.length > 0 ? [{ key: "resource", value: "Resources" }] : []),
    ...(template && resource_slots.length > 0 ? [{ key: "resource", value: "Resources" }] : [])
  ]
  const DYNAMIC_LINKS = [
    { key: "transcript", value: "{{LINKS.TRANSCRIPT}}", button: "Transcript Page" },
    { key: "book-meetings", value: "{{LINKS.BOOK_MEETINGS}}", button: "Book Meetings URL" },
  ]

  const onExternalUrlChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    setExternalUrl(event.target.value)
  }

  const onExternalUrlAcceptHandler = () => {
    if (validator.isURL(externalUrl, { require_protocol: true })) {
      setError(null)
      onExternalUrlChange(externalUrl as string)
    } else {
      setError("Invalid URL")
    }
  }

  const onInternalPageIdChangeHandler = (pageId: string) => {
    setExternalUrl("")
    onInternalPageIdChange(pageId)
  }

  const onDynamicUrlChangeHandler = (value: string) => {
    setExternalUrl("")
    onDynamicUrlChange(value)
  }

  const onInternalTemplateResourceSlotIdChangeHandler = (resourceSlotId: string) => {
    setExternalUrl('')
    onInternalTemplateResourceSlotIdChange(resourceSlotId)
  }

  const onInternalResourceIdChangeHandler = (resourceId: string) => {
    setExternalUrl('')
    onInternalResourceIdChange(resourceId)
  }

  const onTabChange = (tab: string) => {
    setLinkVariant(tab)
    setError(null)
  }

  return (
    <div className="flex flex-col w-full gap-4 min-w-[220px]">
      <BlockMenubarSelector
        activeKey={linkVariant}
        data={LINK_OPTIONS}
        onSelect={onTabChange}
        btnClass="min-w-[84px]"
      />
      {linkVariant === "external" && (
        <div className="flex w-full flex-col">
          <div className="flex w-full bg-gray-100 rounded p-1">
            <input
              type='text'
              placeholder="https://example.com"
              onChange={onExternalUrlChangeHandler}
              value={externalUrl}
              className="text-base brand-text unset_input w-[90%] p-1 outline-none placeholder:text-[#2c27273a] rounded-xl border-none
              focus:outline-none focus:shadow-none focus:drop-shadow-none focus:ring-0 focus:border-none bg-transparent"
            />
            <div onClick={onExternalUrlAcceptHandler} className="flex items-center justify-center w-[10%] cursor-pointer" >
              <PhIcon name='check' size={16} color='brand-text' />
            </div>
          </div>
          {error && (
            <div className="flex w-full items-center text-sm brand-text mt-2 animate-fadeIn rounded bg-[#FB2C2C33] border-l-4 border-[#F46262] p-2">
              <PhIcon name='warning' size={16} className='text-[#F46262] mr-2' />
              {error}
            </div>
          )}
        </div>
      )}
      {linkVariant === "internal" && (
        <div className="flex flex-col gap-1 w-full">
          {activePages.map((page, i) => {
            const isActive = page.id === internal_page_id
            const disabled = page.id === current_page_id
            return (
              <div
                key={i}
                onClick={isActive || disabled ? undefined : onInternalPageIdChangeHandler.bind(onInternalPageIdChangeHandler, page.id)}
                className={`flex justify-between items-center p-2 rounded-lg hover:bg-gray-100
                  ${isActive ? "bg-gray-100 cursor-not-allowed" : ""} 
                  ${disabled ? "bg-gray-100 opacity-50 cursor-not-allowed" : ""} `}
              >
                <div className="flex gap-1">
                  <PhIcon name='file' size={16} className='brand-text' />
                  <div className="brand-text text-sm cursor-default">{page.name}</div>
                </div>
                {isActive && <PhIcon name='check' size={16} className='brand-text' />}
              </div>
            )
          }
          )}
        </div>
      )}
      {linkVariant === "dynamic" &&
        <div className="flex flex-col gap-1 w-full">
          {DYNAMIC_LINKS.map((dynamicLink, i) => {
            const isActive = url == dynamicLink.value
            return (
              <div
                key={i}
                onClick={isActive ? undefined : onDynamicUrlChangeHandler.bind(onDynamicUrlChangeHandler, dynamicLink.value)}
                className={`flex justify-between items-center p-2 rounded-lg hover:bg-gray-100
                  ${isActive ? "bg-gray-100 cursor-not-allowed" : ""}`}
              >
                <div className="flex gap-1">
                  <PhIcon name='file' size={16} className='brand-text' />
                  <div className="brand-text text-sm cursor-default">{dynamicLink.button}</div>
                </div>
                {isActive && <PhIcon name='check' size={16} className='brand-text' />}
              </div>
            )
          }
          )}
        </div>
      }
      {
        linkVariant === "resource" &&
        <div className="flex flex-col gap-1 w-full">
          <>
            {template && resource_slots.length > 0 &&
              <>
                {resource_slots.map((resourceSlot, i) => {
                  const isActive = internal_template_resource_slot_id == resourceSlot.id
                  return (
                    <div
                      key={i}
                      onClick={isActive ? undefined : onInternalTemplateResourceSlotIdChangeHandler.bind(onInternalTemplateResourceSlotIdChangeHandler, resourceSlot.id)}
                      className={`flex justify-between items-center p-2 rounded-lg hover:bg-gray-100
                  ${isActive ? "bg-gray-100 cursor-not-allowed" : ""}`}
                    >
                      <div className="flex gap-1">
                        <PhIcon name='bookOpenText' size={16} className='brand-text' />
                        <div className="brand-text text-sm cursor-default">{resourceSlot.about}</div>
                      </div>
                      {isActive && <PhIcon name='check' size={16} className='brand-text' />}
                    </div>
                  )
                }
                )}
              </>
            }
            {
              !template && resources.length > 0 &&
              <>
                {resources.map((resource, i) => {
                  const isActive = internal_resource_id == resource.id
                  return (
                    <div
                      key={i}
                      onClick={isActive ? undefined : onInternalResourceIdChangeHandler.bind(onInternalResourceIdChangeHandler, resource.id)}
                      className={`flex justify-between items-center p-2 rounded-lg hover:bg-gray-100
                  ${isActive ? "bg-gray-100 cursor-not-allowed" : ""}`}
                    >
                      <div className="flex gap-1">
                        <PhIcon name='bookOpenText' size={16} className='brand-text' />
                        <div className="brand-text text-sm cursor-default">{resource.name}</div>
                      </div>
                      {isActive && <PhIcon name='check' size={16} className='brand-text' />}
                    </div>
                  )
                }
                )}
              </>
            }
          </>
        </div>
      }
    </div>
  );
};


export default BlockMenubarLink;
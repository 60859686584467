import React from "react";

import PhIcon, { PhIconName } from "../../../shared/Icon/PhIcon";
import Tooltip from "../../../shared/ToolTip/Tooltip";

type Props = {
  icon: PhIconName
  tooltip: string
}

const FixedToolbarTrigger: React.FC<Props> = ({ icon, tooltip }) => {
  return (
    <Tooltip content={tooltip} side={'left'} sideOffset={5} container="menubar_fixed" >
      <div
        className={`w-9 h-9 flex items-center justify-center hover:bg-gray-900 rounded-lg cursor-pointer `}
      >
        <PhIcon name={icon} size={20} className="text-white" />
      </div>
    </Tooltip>
  )
}

export default FixedToolbarTrigger;
import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { WEIGHTS } from "../../../../../store/reducers/page_fonts/page_fonts.config";
import PhIcon from "../../../../../shared/Icon/PhIcon";


type Props = {
  activeWeight: number;
  availableWeights: number[];
  onWeightChange: (weight: number) => void;
  width?: number;
}

const WeightSelector: React.FC<Props> = ({
  activeWeight, availableWeights, onWeightChange, width
}) => {
  const [open, setOpen] = useState(false);

  const onWeightChangeHandler = (weight: number) => {
    onWeightChange(weight);
    setOpen(false);
  }
  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild  >
        <div
          className={`
            p-2 rounded-lg border flex justify-between gap-2 items-center outline-none w-[30%] cursor-pointer
            ${open ? "active_input text-gray-500" : "border-gray-300 text-brand"} hover:border-gray-400
          `}
        >
          <span className="brand-text text-base font-normal capitalize">{activeWeight}</span>
          <PhIcon name="caretDown" size={16} className="text-black" />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="dropdown_content max-h-[250px] z-[4]"
          align="end"
          style={{ width }}
        >
          {
            WEIGHTS.map(({ value }, i) => {
              const supported = availableWeights.includes(value)
              return (
                <DropdownMenu.Item
                  key={i}
                  onClick={supported ? onWeightChangeHandler.bind(onWeightChangeHandler, value) : undefined}
                  className={`dropdown_item ${value === activeWeight ? 'active' : ''} ${supported ? '' : 'disabled'}`}
                >
                  {supported ? (
                    <>
                      <span className="brand-text text-base">{value}</span>
                      {value === activeWeight && <PhIcon name="check" size={16} className="text-black" />}
                    </>
                  ) : (
                    <>
                      <span className="brand-text text-base">{value}</span>
                      {value === activeWeight && <PhIcon name="check" size={16} className="text-black" />}
                    </>
                  )}
                </DropdownMenu.Item>
              );
            })
          }
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

export default WeightSelector;
import React from "react";
import { useEditorRef } from "@udecode/plate-common/react";
import { addRangeMarks, findNode } from "@udecode/plate-common";

import { PlateSectionElement } from "../../../../plate-config/Plugins/Section/Section.plugin";
import { findSectionTextNodes } from "../../../../utils/plate.util";
import ToolbarColorSelector from "../../../../shared/ToolbarControls/ToolbarColorSelector";
import ToolbarSeparator from "../../../../shared/ToolbarControls/ToolbarSeparator";
import ToolbarSelector from "../../../../shared/ToolbarControls/ToolbarSelector";
import BlockMenubarHeader from "../../../../shared/BlockMenubarControls/BlockMenubarHeader";


type Props = {
  onMenuClose: () => void;
  sectionElement: PlateSectionElement
}

const Font: React.FC<Props> = ({ onMenuClose, sectionElement }) => {
  const editor = useEditorRef()

  const onFontColorChange = (font_color: string) => {
    editor.setNodes(
      { font_color } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    const textNodes = findSectionTextNodes(sectionElement)
    textNodes.forEach((node: any) => {
      const nodeData = findNode(editor, { at: [], match: (n: any) => n.id === node.id })!
      addRangeMarks(editor, { font_color }, { at: nodeData[1] })
    })
  }

  const onFontSizeChange = (font_size: string) => {
    editor.setNodes(
      { font_size } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    const textNodes = findSectionTextNodes(sectionElement)
    textNodes.forEach((node: any) => {
      const nodeData = findNode(editor, { at: [], match: (n: any) => n.id === node.id })!
      addRangeMarks(editor, { font_size }, { at: nodeData[1] })
    })
  }

  const onFontAlignmentChange = (font_alignment: string) => {
    editor.setNodes(
      { font_alignment } as Partial<Node>,
      { at: [], match: (n: any) => n.id === sectionElement.id }
    )
    const textNodes = findSectionTextNodes(sectionElement)
    textNodes.forEach((node: any) => {
      editor.setNodes(
        { align: font_alignment } as Partial<Node>,
        { at: [], match: (n: any) => n.id === node.id }
      )
    })
  }

  return (
    <section className="flex flex-col gap-4">
      <BlockMenubarHeader label="Font Settings" onClose={onMenuClose} />
      <ToolbarColorSelector
        label="Color"
        color={sectionElement.font_color || ""}
        onColorChange={onFontColorChange}
        wrapperClassName="w-full"
      />
      <ToolbarSeparator variant="horizontal" />
      <ToolbarSelector
        activeValue={sectionElement.font_size || "medium"}
        label="Size"
        onValueChange={onFontSizeChange}
        data={[{ key: "small", value: "S" }, { key: "medium", value: "M" }, { key: "large", value: "L" }]}
      />
      <ToolbarSeparator variant="horizontal" />
      <ToolbarSelector
        activeValue={sectionElement.font_alignment || "left"}
        label="Alignment"
        onValueChange={onFontAlignmentChange}
        data={[
          { key: "left", value: 'left', icon: "textAlignLeft" },
          { key: "center", value: 'center', icon: 'textAlignCenter' },
          { key: "right", value: "right", icon: "textAlignRight" }]
        }
      />
      <ToolbarSeparator variant="horizontal" />
      <div className="border border-gray-300 bg-gray-100 rounded-lg p-2 brand-text text-sm">
        <div className="font-semibold pb-1 cursor-default">Need more options?</div>
        <div className="font-light cursor-default">
          Highlight any text to bring up the toolbar
        </div>
      </div>
    </section>
  );
}

export default Font;
import React from "react";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";

import PhIcon from "../../../shared/Icon/PhIcon";

type Props = {
  setNodeRef: (node: HTMLElement | null) => void,
  containerStyles: {
    transform: string | undefined;
    transition: string | undefined;
  }
  isReadOnly: boolean,
  attributes: DraggableAttributes
  listeners: SyntheticListenerMap | undefined
  setActivatorNodeRef: (node: HTMLElement | null) => void,
  onDnDClick: () => void,
  measureRef: React.RefObject<HTMLSpanElement>
  content: string
  inputRef: React.RefObject<HTMLInputElement>
  onContentChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  bgColor: string | null
  textColor: string,
  activeSize: string,
  missingUrl: boolean
  onWrapperClick: () => void
  hasNotifications: boolean
}


const PreviewButton: React.FC<Props> = ({
  setNodeRef, containerStyles, isReadOnly, attributes, listeners, setActivatorNodeRef, onDnDClick, hasNotifications,
  measureRef, content, inputRef, onContentChange, bgColor, textColor, activeSize, missingUrl, onWrapperClick
}) => {

  return (
    <div
      ref={setNodeRef}
      style={containerStyles}
      {...attributes}
      className="relative group/btn_dnd flex flex-col items-center"
      onClick={onWrapperClick}
    >
      {!isReadOnly &&
        <div
          {...listeners}
          ref={setActivatorNodeRef}
          className={`absolute top-[-2px] left-[-27px] cursor-grab w-6 h-6 rounded-[4px] 
                    bg-white shadow-md flex justify-center items-center outline-none opacity-0 
                      group-hover/btn_dnd:opacity-100 transition-opacity duration-200
                    `}
          onClick={onDnDClick}
        >
          <PhIcon name="dotsSixVertical" size={16} className='text-black' weight='bold' />
        </div>
      }
      <span
        ref={measureRef}
        className="absolute overflow-hidden whitespace-pre"
        aria-hidden="true"
        style={{ visibility: 'hidden', height: '0' }}
      >
        {content}
      </span>
      <input
        type="text"
        ref={inputRef}
        value={content}
        placeholder="Click here"
        onChange={onContentChange}
        maxLength={30}
        style={{
          ...(bgColor &&
          {
            backgroundColor: bgColor,
            borderColor: (bgColor === 'rgba(255, 255, 255, 1)' || bgColor === '#ffffff') ? "#333333" : bgColor,
            borderStyle: 'solid'
          })
        }}
        className={`
                    outline-none border-none focus:border-none focus:outline-none focus:shadow-none 
                    focus:drop-shadow-none focus:ring-0 rounded-2xl font-medium text-base text-center
                    border min-w-[125px] flex unset_input
                    ${textColor} ${!bgColor ? 'bg-[#333333] border-[#333333]' : ''}
                    ${activeSize === 'small' ? 'px-4 py-[10px] h-10 text-sm' : ''}
                    ${activeSize === 'medium' ? 'px-5 py-3 text-base h-12' : ''}
                    ${activeSize === 'large' ? 'px-6 py-[18px] text-base h-[60px]' : ''}
                     `}
      />
      <div className={`flex w-full ${content.length >= 30 ? "justify-between" : "justify-center"} ${hasNotifications ? "min-h-5 pt-1" : ""} brand-font-family`}>
        {missingUrl &&
          <div className="text-gray-700 flex text-xs text-center">
            <PhIcon name='linkBreak' size={16} className="text-gray-700 mx-1 visible" />
            No link added
          </div>
        }
        {content.length >= 30 &&
          <div className="text-gray-700 text-xs text-center"> 0 characters left (max 30) </div>
        }
      </div>
    </div>
  );
}


export default PreviewButton;
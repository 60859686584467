import React from "react";

import Checkbox from "../../../../../shared/Inputs/Checkbox";


type Props = {
  onChange: (value: boolean) => void
  value: boolean
}


const AdditionalListParams: React.FC<Props> = ({ onChange, value }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="brand-text text-sm font-medium">Advanced Options</div>
      <div className="flex items-center gap-2">
        <Checkbox value={value} setValue={onChange} label="Extra spacing between paragraphs" htmlFor="extra-space" />
      </div>
    </div>
  )
}

export default AdditionalListParams;




import React, { useEffect, useState } from "react";


export const useElementWidth = (ref: React.RefObject<HTMLDivElement | HTMLButtonElement>) => {
  const [clientWidth, setClientWidth] = useState<number | null>(null);

  const calculateAndSetHeight = () => {
    if (ref.current) {
      const element = ref.current;
      const width = element.clientWidth;
      setClientWidth(width);
    }
  };

  useEffect(() => {
    calculateAndSetHeight();

    const element = ref.current;
    const resizeObserver = new ResizeObserver(calculateAndSetHeight);
    const mutationObserver = new MutationObserver(calculateAndSetHeight);

    if (element) {
      resizeObserver.observe(element);

      mutationObserver.observe(element, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true,
      });
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [ref]);

  return clientWidth;
}
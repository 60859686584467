import React, { useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";


type Props = {
  url: string
}

const Calendly = ({ url }: Props) => {
  const [height, setHeight] = useState('500px');
  const onPageHeightResize = (e) => {
    setHeight(e.data.payload.height)
  };

  useCalendlyEventListener({
    onPageHeightResize
  })

  return <InlineWidget url={url} styles={{ height }} />
}

export default Calendly;
import React, { useEffect, useState } from 'react';
import { select, setMarks, getMarks, removeMark, getNodeParent } from '@udecode/plate-common';
import { useEditorRef, focusEditor } from '@udecode/plate-common/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import * as MenuBar from "@radix-ui/react-menubar";

import { TextColorLeafPlugin } from '../../../plate-config/Plugins/TextColor/TextColor.plugin';
import { TextHighlightLeafPlugin } from '../../../plate-config/Plugins/TextHighlight/TextHighlight.plugin';
import { colorClassToRGB, systemColorsArray, textColorFromHex } from '../../../utils/color.util';
import { useAppSelector } from '../../../store/hooks/redux-hooks';
import PhIcon from '../../../shared/Icon/PhIcon';
import BlockMenubarTrigger from '../../../shared/BlockMenubarControls/BlockMenubarTrigger';
import BlockMenubarContent from '../../../shared/BlockMenubarControls/BlockMenubarContent';
import BlockMenubarSelector from '../../../shared/BlockMenubarControls/BlockMenubarSelector';
import BlockMenubarColors from '../../../shared/BlockMenubarControls/BlockMenubarColors';

type Props = {
  value?: string;
}

const Colors: React.FC<Props> = ({ value }) => {
  const editor = useEditorRef()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [type, setType] = useState<string>(TextColorLeafPlugin.key)
  const [color, setColor] = useState('')
  const [activeBg, setActiveBg] = useState('black')
  const { activePalette } = useAppSelector(state => state.page_colors)
  const marks = getMarks(editor)

  const nodeParent = editor.selection?.anchor?.path ? getNodeParent(editor, editor.selection?.anchor.path) : null;
  const isBlockquote = nodeParent ? nodeParent?.type === BlockquotePlugin.key : false;

  useEffect(() => {
    if (type === TextColorLeafPlugin.key) {
      setColor(marks?.font_color as string || '')
    }
    if (type === TextHighlightLeafPlugin.key) {
      setColor(marks?.text_highlight as string || '')
    }
    if (type === 'blockquote') {
      setColor(nodeParent?.line_color as string || '')
    }
  }, [type, marks])

  useEffect(() => {
    if (marks?.font_color) {
      const fontColor = marks?.font_color as string
      setActiveBg(fontColor
        ? fontColor.startsWith('landing')
          ? systemColorsArray.find(systemColour => systemColour.name === fontColor)!.color
          : colorClassToRGB(fontColor, 100, activePalette.colors)!
        : 'black')
    }
  }, [marks])

  const onColorChange = (color: string) => {
    if (type === 'blockquote') {
      editor.setNodes({ line_color: color } as Partial<Node>, { at: [], match: (n: any) => n.id === nodeParent?.id })
      setPopoverOpen(false)
      return
    }
    setColor(color)
    select(editor, editor.selection!);
    focusEditor(editor);
    setMarks(editor, {
      ...(type === TextColorLeafPlugin.key && { 'font_color': color }),
      ...(type === TextHighlightLeafPlugin.key && { 'text_highlight': color }),
    });
    setPopoverOpen(false)
  }

  const onColorUnset = () => {
    if (type === 'blockquote') {
      editor.setNodes({ line_color: null } as Partial<Node>, { at: [], match: (n: any) => n.id === nodeParent?.id })
      setPopoverOpen(false)
      return
    }
    removeMark(editor, { key: type === TextColorLeafPlugin.key ? 'font_color' : 'text_highlight' })
    setPopoverOpen(false)
    select(editor, editor.selection!);
    focusEditor(editor);
  }

  return (
    <MenuBar.Menu value="float_colors">
      <BlockMenubarTrigger
        isDropdown isColor styleColor={activeBg} isOpened={value === 'float_colors'}
        tooltip="Font Color"
        containerId="floating-toolbar"
      />
      <MenuBar.Portal>
        <BlockMenubarContent>
          <BlockMenubarSelector
            activeKey={type}
            onSelect={setType}
            data={[
              { key: TextColorLeafPlugin.key, value: "Text" },
              { key: TextHighlightLeafPlugin.key, value: "Highlight" },
              ...(isBlockquote ? [{ key: 'blockquote', value: 'Line' }] : [])
            ]}
          />
          <BlockMenubarColors
            color={color}
            onColorChange={onColorChange}
            label="Theme Colors"
          />
          <div className="w-full flex flex-col gap-2">
            <span className="font-medium text-sm brand-font-family text-gray-700">System Colors</span>
            <section className={`flex gap-1 flex-wrap w-[176px]`}>
              {systemColorsArray.map((backgroundColor, i) =>
                <div className="p-[2.5px] h-8 w-8" key={i}>
                  <button
                    className="rounded-full border border-gray-300 flex items-center justify-center w-[27.5px] h-[27.5px]"
                    style={{ backgroundColor: backgroundColor.color }}
                    onClick={onColorChange.bind(onColorChange, backgroundColor.name)}
                  >
                    {backgroundColor.name === color &&
                      <PhIcon name={'check'} size={16} className={`text-${textColorFromHex(backgroundColor.color)}`} />
                    }
                  </button>
                </div>
              )}
            </section>
          </div>
          {
            !!color &&
            <section className='mt-4 w-full'>
              <div className='bg-black opacity-10 h-[1px]' />
              <div onClick={onColorUnset}
                className="cursor-pointer mt-4 flex justify-center gap-1"
              >
                <PhIcon name="arrowUDownLeft" size={16} className="brand-text" />
                <span className='text-sm font-medium brand-text'>Reset to default</span>
              </div>
            </section>
          }
        </BlockMenubarContent>
      </MenuBar.Portal>
    </MenuBar.Menu>
  );
}

export default Colors;

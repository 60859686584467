import React from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  variant?: "horizontal" | "vertical";
  className?: string;
}

const ToolbarSeparator: React.FC<Props> = ({ variant = 'vertical', className }) =>
  <div
    className={twMerge(variant === 'vertical' ? "toolbar_separator" : "toolbar_separator_horizontal", 'bg-gray-100', className)}
    contentEditable={false}
    suppressContentEditableWarning
  />

export default ToolbarSeparator;


import React, { useState } from "react";

import { PagePalette } from "../../../../../store/reducers/page_colors/page_colors";
import ColorSelectorButton from "../../../../../shared/Button/ColorSelectorButton";
import ColorSelectorDashedButton from "../../../../../shared/Button/ColorSelectorDashedButton";

type Props = {
  colors: string[]
  overridePallet: () => void
  customColors: string[]
  activePalette: PagePalette
}

const DefaultColors: React.FC<Props> = ({ colors, overridePallet, customColors, activePalette }) => {
  const [warning, setWarning] = useState(false)

  return (
    <div className="flex w-full gap-4 flex-col">
      <div className="flex w-full flex-wrap gap-1">
        {colors.map((backgroundColor, i) =>
          <ColorSelectorButton backgroundColor={backgroundColor} key={i} />
        )}
        <ColorSelectorDashedButton
          onClick={setWarning.bind(setWarning, !warning)}
          disabled={warning}
          iconName="plus"
          iconClassName="text-black"
        />
      </div>
      <div className={`${warning ? 'flex' : 'hidden'} border rounded-lg bg-gray-100 border-gray-300 p-3 gap-2 flex-col items-start `}>
        <div className="flex w-full gap-1 flex-wrap">
          {customColors.map((backgroundColor, i) => <ColorSelectorButton backgroundColor={backgroundColor} key={i} />)}
        </div>
        <span className="brand-text text-sm">
          {`The following custom colors will be replaced with ${activePalette.palette === "buyer" ? "the prospect's" : "your brand's"} colors`}
        </span>
        <button className="brand_btn-primary w-full" onClick={overridePallet} >
          Continue
        </button>
      </div>
    </div>
  );
}

export default DefaultColors;
import React from "react"

import { PlateElementAiTextBlock } from "../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin"
import ListItem from "./ListItem/ListItem"
import Sources from "./Sources/Sources"
import { useAppSelector } from "../../../store/hooks/redux-hooks"
import PromptItem from "./PromptItem/PromptItem"

type Props = {
  element: PlateElementAiTextBlock
  onActiveViewChange: (view: string) => void
  onEditModalOpen: () => void
}

const AiTextBlockSummary: React.FC<Props> = ({ element, onActiveViewChange, onEditModalOpen }) => {
  const availableResources = useAppSelector(state => state.page_resources.resource_slots)
  const resourcesToDisplay = availableResources.filter(slot => element.details.template_resource_slot_ids.includes(slot.id))

  const matchingLengthDescription = `${element.details.length_min} ${element.details.length_type}`
  const standardLengthDescription = `${element.details.length_min} to ${element.details.length_max} ${element.details.length_type}`
  const lengthPresentDescription = element.details.length_min && element.details.length_max && element.details.length_min === element.details.length_max ? matchingLengthDescription : standardLengthDescription
  const lengthDescription = element.details.length_min && element.details.length_max ? lengthPresentDescription : "Length is missing"

  const listKindDescription = () => {
    switch (element.details.list_kind) {
      case "bolded_summary":
        return "items with bolded summary"
      case "question_answer":
        return "question and answer pairs"
      case "objection":
        return "concerns addressed"
      case "bolded_summary_paragraph":
        return "paragraphs with summary"
      default:
        return "items"
    }
  }

  const listStyleDescriptor = () => {
    switch (element.details.list_kind) {
      case "objection":
        return ""
      case "bolded_summary_paragraph":
        return ""
      default:
        return element.details.list_style === "unordered" ? "bulleted " : "numbered "
    }
  }

  const matchingListItemsDescription = `${element.details.list_items_min} ${listStyleDescriptor()}${listKindDescription()}`
  const standardListItemsDescription = `${element.details.list_items_min} to ${element.details.list_items_max} ${listStyleDescriptor()}${listKindDescription()}`
  const listItemsDescription = element.details.list_items_min && element.details.list_items_max && element.details.list_items_min === element.details.list_items_max ? matchingListItemsDescription : standardListItemsDescription
  const itemsDescription = element.details.list_items_min && element.details.list_items_max ? listItemsDescription : "Number of items is missing"

  const alignOptions = element.details.kind !== "list" || (element.details.kind === "list" && (
    element.details.list_kind === "feature_benefit" ||
    element.details.list_kind === "objection" ||
    element.details.list_kind === "bolded_summary_paragraph"
  ))
  return (
    <div className="min-h-[400px] bg-gray-100 rounded flex w-full flex-col gap-4 items-start justify-center p-8 text-left">
      <div className="flex w-full justify-between">
        <h3 className="text-left text-xl font-semibold brand-text">
          {element.details.kind.replace(/^./, (match) => match.toUpperCase())} AI Text
        </h3>
        <div className="flex gap-2">
          <button onClick={onActiveViewChange.bind(onActiveViewChange, "preview")} className="brand_btn-secondary medium" >
            Preview
          </button>
          <button onClick={onEditModalOpen} className="brand_btn-secondary medium"> Edit </button>
        </div>
      </div>
      <div className="dl-items text-left">
        <PromptItem prompt={element.details.prompt as string} />
        {element.details.kind === "list" && <ListItem label="Number of Items" value={itemsDescription} />}
        <ListItem label={element.details.kind === "list" ? "Items Length" : "Length"} value={lengthDescription} />
        {alignOptions &&
          <ListItem label="Alignment" value={element.details.alignment.replace(/^./, (match) => match.toUpperCase())} />
        }
        <ListItem
          label="Text Color"
          value={element.primary_color
            ? <div className={`w-[27.5px] h-[27.5px] rounded-full border border-gray-300 background-${element.primary_color} mt-1`} />
            : "Section Text Color"
          }
        />
        <Sources element={element} />
        <ListItem
          label="Resources"
          value={!!resourcesToDisplay.length
            ?
            <ul> {resourcesToDisplay.map(resource => <li key={resource.id}> {resource.about} </li>)} </ul>
            : "No resources used"
          }
        />
      </div>
    </div>
  )
}

export default AiTextBlockSummary
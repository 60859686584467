import React from "react";

type Props = {
  onModalClose: () => void;
  idToAdd: number
  onTestimonialsAdd: () => void
}

const LibraryFooter: React.FC<Props> = ({ onModalClose, idToAdd, onTestimonialsAdd }) => {
  return (
    <div className="p-5 flex border-t border-gray-300 justify-start gap-4">
      <div
        className={`
          items-center p-2 rounded-xl text-sm font-medium text-nowrap brand-text border border-transparent bg-primary
          ${idToAdd === 0 ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-100'}
          `}
        onClick={idToAdd === 0 ? undefined : onTestimonialsAdd}
      >
        Choose selected
      </div>
      <div
        className="items-center p-2 rounded-xl text-sm font-medium text-nowrap brand-text border cursor-pointer border-gray-100 bg-white"
        onClick={onModalClose}
      >
        Cancel
      </div>
    </div>
  )
}

export default LibraryFooter;
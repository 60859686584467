import React from "react";


type Props = {
  backgroundColor?: string
  onClick?: () => void
  btnClassName?: string
  wrapperClassName?: string
}

const ColorSelectorResetButton: React.FC<Props> = ({
  backgroundColor, onClick, btnClassName, wrapperClassName
}) => {

  return (
    <div className={`p-[2.5px] h-8 w-8 ${wrapperClassName}`}>
      <button
        className={`
          rounded-full border border-gray-900/20 flex items-center chessboard-background 
          justify-center w-[27.5px] h-[27.5px] ${btnClassName}
          ${onClick ? "cursor-pointer" : "cursor-default"}
          `}
        style={{ backgroundColor: backgroundColor }}
        onClick={onClick}
      >
        <div className="w-[1px] h-full bg-red-500 transform rotate-45 rounded-full" />
      </button>
    </div>
  );
}

export default ColorSelectorResetButton;
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["columns", "composeField"]
  static values = {
    strategy: String,
    columnClasses: { type: String, default: "" }
  }

  connect() {
    this.formButton = document.getElementById("revveal-submit-button")
    switch (this.strategyValue) {
      case 'search':
        this.search();
        break;
      case 'compose':
        this.compose();
        break;
      default:
        console.error("Invalid content type");
    }
  }

  search() {
    this.columnsTarget.classList.remove(...this.parseClasses())
    this.composeFieldTargets.forEach(field => field.classList.add("hidden"))
  }

  compose() {
    this.columnsTarget.classList.add(...this.parseClasses())
    this.composeFieldTargets.forEach(field => field.classList.remove("hidden"))
  }

  parseClasses() {
    return this.columnClassesValue.split(' ').filter(Boolean)
  }
}

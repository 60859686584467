import React, { useState } from "react";
import Tooltip from "../../../../shared/ToolTip/Tooltip";
import { TemplateVariable } from "../../../../store/reducers/api/api.types";
import PhIcon from "../../../../shared/Icon/PhIcon";


type Props = {
  variable: TemplateVariable
}


const VariableItem: React.FC<Props> = ({ variable }) => {

  const [tooltipText, setTooltipText] = useState('Copy');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value)
      .then(() => {
        setTooltipText('Copied!');
        setTooltipOpen(true);
        setTimeout(() => {
          setTooltipText('Copy')
          setTooltipOpen(false);
        }, 5000);
      });
  };


  return (
    <>
      <div className="flex justify-between" id={'variable_tooltip_anchor'}>
        <span className="brand-text text-xs font-medium">{variable.name}</span>
        <Tooltip
          content={tooltipText}
          side={'top'}
          sideOffset={10}
          container="variable_tooltip_anchor"
          open={tooltipOpen}
          onOpenChange={setTooltipOpen}
        >
          <div onClick={handleCopy.bind(handleCopy, variable.value)} >
            <PhIcon name="copy" size={16} className="text-gray-700 opacity-0 group-hover/var_item:opacity-100" />
          </div>
        </Tooltip>
      </div>
      <span className="brand-font-family text-xs text-black/70">{variable.about}</span>
    </>
  );
}


export default VariableItem;
import React, { useEffect, useState } from "react";
import { useEditorRef } from "@udecode/plate-common/react";

import PageWidthSelector from "./PageWidthSelector/PageWidthSelector";
import NavigationDropdown from "./NavigationDropdown/NavigationDropdown";
import SettingsDropdown from "./SettingsDropdown/SettingsDropdown";
import PageStatus from "./PageStatus/PageStatus";
import PageModeSwitcher from "./PageModeSwitcher/PageModeSwitcher";
import PageLogo from "./PageLogo/PageLogo";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import TemplateDetails from "./TemplateDetails/TemplateDetails";
import VariablesDropdown from "./VariablesDropdown/VariablesDropdown";

type ScreenSize = 'mobile' | 'extra-small' | 'small' | 'base' | 'medium' | 'large';

export const getScreenSize = (template: boolean): ScreenSize => {
  const width = window.innerWidth;
  if (template) {
    if (width < 725) return 'mobile';
    if (width >= 725 && width <= 1035) return 'small';
    if (width >= 1035 && width <= 1165) return 'base';
    if (width >= 1165 && width <= 1255) return 'medium';
  } else {
    if (width < 615) return 'mobile';
    if (width >= 615 && width <= 785) return 'extra-small';
    if (width >= 785 && width <= 900) return 'small';
    if (width >= 900 && width <= 1100) return 'base';
    if (width >= 1100 && width <= 1295) return 'medium';
  }
  return 'large';
};

const PageHeaderNav: React.FC = () => {
  const template = useAppSelector((state) => state.page_navigation.template);
  const { readOnly, isLandingPage } = useAppSelector((state) => state.page_addendums);
  const editor = useEditorRef();
  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize(template));

  useEffect(() => {
    const handleResize = () => setScreenSize(getScreenSize(template))
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const readOnlyMode = readOnly || isLandingPage;

  // Deselect the editor when clicking outside of the editor
  const onNavWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!readOnlyMode) editor.deselect();
  }

  const showPageModeSwitcher = template
    ? (screenSize === 'large' || screenSize === 'medium' || screenSize === 'base')
    : (screenSize === 'large' || screenSize === 'medium');
  const showNavigationDropdown = screenSize === 'large' || screenSize === 'medium' || screenSize === 'base';
  const showSettingsDropdown = screenSize !== 'mobile'
  const showTemplateDetails = screenSize === 'large'
  const showVariablesDropdown = screenSize === 'large' || screenSize === 'medium'
  return (
    <nav
      className="w-full flex justify-between items-center h-[68px] px-6 py-5 bg-white border-b border-gray-100 fixed top-0 z-[4] shadow-md"
      onClick={onNavWrapperClick}
      id="nav_tooltip_anchor"
    >
      <section className="flex justify-between w-[40%]">
        <PageLogo />
      </section>
      <PageWidthSelector />
      <section className="flex gap-2 w-[40%] justify-end items-center">
        {showPageModeSwitcher && <PageModeSwitcher />}
        {!template && showNavigationDropdown && <NavigationDropdown hideRouteName={screenSize !== 'large'} />}
        {template && showTemplateDetails && <TemplateDetails />}
        {showSettingsDropdown && <SettingsDropdown />}
        {template && showVariablesDropdown && <VariablesDropdown />}
        <PageStatus />
      </section>
    </nav>
  );
}

export default PageHeaderNav;
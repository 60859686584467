import React, { useState } from "react"

import ImagePicker from "../../../../shared/ImagePicker/ImagePicker";
import { PlateAssessmentElement } from "../../../../plate-config/Plugins/Assessment/Assessment.plugin";
import PhIcon from "../../../../shared/Icon/PhIcon";

type Props = {
  element: PlateAssessmentElement;
  onBackgroundImageChange: (image_url: string, image_source: string, image_external_id: string) => void;
  onImageUnset: () => void;
}

const BackgroundMenu = ({ element, onBackgroundImageChange, onImageUnset }: Props) => {
  const [showImagePicker, setShowImagePicker] = useState(false)

  return (
    <>
      <div
        className="btn btn-small btn-white"
        onClick={setShowImagePicker.bind(setShowImagePicker, true)}
      >
        <PhIcon name={"image"} size={16} />
      </div>
      <ImagePicker
        currentImageUrl={element.image_url as string}
        backgroundImageSource={element.image_source as string}
        modalOpen={showImagePicker}
        onImageChange={onBackgroundImageChange}
        onImageUnset={onImageUnset}
        onModalClose={setShowImagePicker.bind(setShowImagePicker, false)}
      />
    </>
  )
}

export default BackgroundMenu

import React, { useCallback, useRef } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useEditorRef } from '@udecode/plate-common/react';

import { useAddUserImageMutation } from '../../../store/reducers/api/images/images';
import { PlateImageElement } from '../../../plate-config/Plugins/Image/Image.plugin';
import PhIcon from '../../../shared/Icon/PhIcon';

interface DragItem {
  files: File[];
}

type Props = {
  element: PlateImageElement
  openPicker: () => void
}

const ImagePlaceholder = ({ element, openPicker }: Props) => {

  const editor = useEditorRef()
  const [uploadUserImage] = useAddUserImageMutation()
  const inputRef = useRef<HTMLInputElement>(null);

  const setUploadedImageHandler = (image_external_id: string, image_url: string) =>
    editor.setNodes(
      { image_external_id, image_source: "upload", image_url, internal_page_id: null, link_type: "external" } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )

  const handleUploadChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    if (!files || !files[0].type.startsWith('image/')) return;
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData()
      formData.append("image[uploaded_image]", e.target.files[0])
      await uploadUserImage(formData).then((response) => {
        if ("data" in response) {
          setUploadedImageHandler(response.data.id, response.data.urls.regular)
        }
      })
    }
  };

  const onUploadImageClick = () => inputRef && inputRef.current?.click();

  const handleFileDrop = useCallback(async (_item: DragItem, monitor: DropTargetMonitor) => {
    if (monitor) {
      const dragItem = monitor.getItem() as DragItem;
      const imageFiles = dragItem.files.filter(file => file.type.startsWith('image/'));
      if (imageFiles.length === 0) {
        return;
      }
      const file = imageFiles[0] as File
      const formData = new FormData()
      formData.append("image[uploaded_image]", file)
      await uploadUserImage(formData).then((response) => {
        if ("data" in response) {
          setUploadedImageHandler(response.data.id, response.data.urls.regular)
        }
      })
    }
  }, []);

  const [{ }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop: handleFileDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  }), [handleFileDrop]);

  return (
    <div
      ref={drop}
      className='bg-gray-100 rounded-lg py-[105px] px-5'
    >
      <div className='flex flex-col items-center justify-center'>
        <div
          className='flex items-center justify-center p-[10px] mb-[10px] opacity-30 cursor-pointer transition-transform duration-200 hover:scale-[110%]'
          onClick={openPicker}
        >
          <PhIcon name='image' size={90} className='brand-subtext' />
        </div>
        <span className='text-base text-center brand-subtext'>Add your image by clicking or simply drag and drop it here</span>
        <div className='flex mt-10 gap-[42px] flex-wrap justify-center'>
          <button onClick={openPicker} className="brand_btn-secondary">Browse Images</button>
          <button onClick={onUploadImageClick} className="brand_btn-secondary">Upload Image</button>
          <input ref={inputRef} type="file" multiple={false} onChange={handleUploadChange} className="hidden" />
        </div>
      </div>
    </div>
  )
}


export default ImagePlaceholder

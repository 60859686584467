import { findNode } from "@udecode/plate-common";
import { MemoizedChildren, useEditorRef } from "@udecode/plate-common/react";
import React, { useState, useRef, useEffect } from "react";
import { useDrop } from 'react-dnd';

import {
  createDefaultBlockQuoteElement,
  createDefaultH1Element,
  createDefaultH2Element,
  createDefaultParagraphElement,
  createDefaultOlElement,
  createDefaultUlElement
} from "../../plate-config/Plugins/DefaultMockups/DefaultMockups";
import { createDefaultImageElement } from "../../plate-config/Plugins/Image/Image.plugin";
import { createDefaultVideoElement } from "../../plate-config/Plugins/Video/Video.plugin";
import { createDefaultEmbedElement } from "../../plate-config/Plugins/Embed/Embed.plugin";
import { createDefaultDashedHrElement, createDefaultHrElement } from "../../plate-config/Plugins/HrLine/HrLine.plugin";
import {
  createDefaultButtonGroupElement,
  createDefaultButtonGroupWith2BtnElement,
  createDefaultButtonGroupWith3BtnElement
} from "../../plate-config/Plugins/Button/ButtonGroup.plugin";
import {
  createTwoColumnGroupElement,
  createThreeColumnsElement,
  createFourColumnsElement
} from "../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin";
import { createDefaultTestimonialGroupElement } from "../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin";
import { createDefaultCardGroupElement } from "../../plate-config/Plugins/CardGroup/CardGroup.plugin";

interface FixedDraggablesProps {
  children?: React.ReactNode;
}

export interface FixedDraggableItem {
  id: string;
}

const FixedDraggables = React.forwardRef<HTMLDivElement, FixedDraggablesProps>((props, ref) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [hoverPosition, setHoverPosition] = useState<'above' | 'below' | ''>('');
  const editor = useEditorRef()

  const editorDataProps = props as any
  const node = findNode(editor, { at: [], match: { id: editorDataProps.element.id as string } })!;
  const nodePathBelow = [...node[1]];
  nodePathBelow[nodePathBelow.length - 1] += 1;

  // NOTE: id should be taken from fixed toolbar.
  const onAddItem = (item: FixedDraggableItem, position: 'above' | 'below') => {
    switch (item.id) {
      case 'paragraph':
        editor.insertNodes(createDefaultParagraphElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'heading1':
        editor.insertNodes(createDefaultH1Element(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'heading2':
        editor.insertNodes(createDefaultH2Element(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'blockquote':
        editor.insertNodes(createDefaultBlockQuoteElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'image':
        editor.insertNodes(createDefaultImageElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'video':
        editor.insertNodes(createDefaultVideoElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'embed':
        editor.insertNodes(createDefaultEmbedElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'bulletList':
        editor.insertNodes(createDefaultUlElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'numberedList':
        editor.insertNodes(createDefaultOlElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case '2columns':
        editor.insertNodes(createTwoColumnGroupElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case '3columns':
        editor.insertNodes(createThreeColumnsElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case '4columns':
        editor.insertNodes(createFourColumnsElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'hr':
        editor.insertNodes(createDefaultHrElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'hr-dashed':
        editor.insertNodes(createDefaultDashedHrElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'button':
        editor.insertNodes(createDefaultButtonGroupElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case '2buttons':
        editor.insertNodes(createDefaultButtonGroupWith2BtnElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case '3buttons':
        editor.insertNodes(createDefaultButtonGroupWith3BtnElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'testimonials':
        editor.insertNodes(createDefaultTestimonialGroupElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      case 'cards':
        editor.insertNodes(createDefaultCardGroupElement(), { at: position === 'above' ? node[1] : nodePathBelow, select: true });
        break;
      default:
        break;
    }
  }

  const [{ isOver }, dropRef] = useDrop({
    accept: 'fixed-toolbar-nodes',
    drop: (item: FixedDraggableItem, monitor) => {
      const clientOffset = monitor.getClientOffset();
      const componentRect = divRef.current?.getBoundingClientRect();
      if (clientOffset && componentRect) {
        const dropYRelativePosition: number = clientOffset.y - componentRect.top;
        const isAboveCenter: boolean = dropYRelativePosition < componentRect.height / 2;
        onAddItem(item, isAboveCenter ? 'above' : 'below');
      }
    },
    hover: (_item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      const componentRect = divRef.current?.getBoundingClientRect();
      if (clientOffset && componentRect) {
        const hoverYRelativePosition = clientOffset.y - componentRect.top;
        const isHoverAboveCenter = hoverYRelativePosition < componentRect.height / 2;
        setHoverPosition(isHoverAboveCenter ? 'above' : 'below');
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
  });

  useEffect(() => {
    dropRef(divRef);
  }, [dropRef]);

  return (
    <div
      ref={divRef}
      className="relative"
    >
      {isOver && !!hoverPosition &&
        <div
          className="relative bg-transparent h-[2px] overflow-hidden my-2"
          contentEditable={false}
          suppressContentEditableWarning={true}
        >
          <div
            className={`
                    absolute inset-0 transform bg-blue-500 rounded-full transition-all duration-300
                    ${hoverPosition === 'above' ? "h-[4px] scale-x-100 opacity-100" : ""} 
                    ${hoverPosition === 'below' ? "h-[4px] scale-x-0 opacity-0" : " "}
                  `}
          />
        </div>}
      <MemoizedChildren>{props.children}</MemoizedChildren>
      {isOver && !!hoverPosition &&
        <div
          className="relative bg-transparent h-[2px] overflow-hidden my-2"
          contentEditable={false}
          suppressContentEditableWarning={true}
        >
          <div
            className={`
                    absolute inset-0 transform bg-blue-500 rounded-full transition-all duration-300
                    ${hoverPosition === 'below' ? "h-[4px] scale-x-100 opacity-100" : ""} 
                    ${hoverPosition === 'above' ? "h-[4px] scale-x-0 opacity-0" : " "}
                    `}
          />
        </div>}
    </div>
  );
});

export default FixedDraggables;

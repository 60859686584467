import React, { useState } from "react";
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate-common/react";
import { findNode } from "@udecode/plate-common";

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import BlockMenubarContent from "../../../shared/BlockMenubarControls/BlockMenubarContent";
import BlockMenubarOptionList from "../../../shared/BlockMenubarControls/BlockMenubarOptionList";
import { PlateColumnGroupElement } from "../../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin";
import { createDefaultColumnElement } from "../../../plate-config/Plugins/ColumnGroup/Column.plugin";
import { columnLayoutModes } from "../ColumnLayouts/ColumnLayouts";


type Props = {
  block: PlateColumnGroupElement;
}

const ColumnGroupMenubar: React.FC<Props> = ({ block }) => {
  const editor = useEditorRef();
  const [value, setValue] = useState<string>("");

  const node = findNode(editor, { at: [], match: (n: any) => n.id === block.id })!
  const nodePath = node[1]
  const childAmount = block.children.length

  const onDeleteColumnsHandle = () => editor.removeNodes({ at: [], match: (n: any) => n.id === block.id })
  const onTwoColumnsHandle = (): void => {
    if (childAmount === 2) return;
    if (childAmount >= 3) {
      block.children.slice(2, childAmount).forEach((child: any) => {
        editor.removeNodes({ at: [], match: (n: any) => n.id === child.id });
      });
      editor.setNodes({ style: '50/50' } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
    }
  };
  const onThreeColumnsHandle = (): void => {
    if (childAmount === 3) return;
    switch (childAmount) {
      case 2:
        editor.insertNodes(createDefaultColumnElement(), { at: [...nodePath, block.children.length] });
        break;
      case 4:
        editor.removeNodes({ at: [], match: (n: any) => n.id === block.children[3].id });
        break;
    }
    editor.setNodes({ style: '33/33/33' } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  };
  const onFourColumnsHandle = (): void => {
    if (childAmount === 4) return;
    const insertCount = 4 - childAmount;
    for (let i = 0; i < insertCount; i++) {
      editor.insertNodes(createDefaultColumnElement(), { at: [...nodePath, block.children.length + i] });
    }
    editor.setNodes({ style: '25/25/25/25' } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  };
  const onLayoutModeHandle = (style: string) =>
    editor.setNodes({ style } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

  return (
    <MenuBar.Root
      value={value}
      onValueChange={setValue}
      className="element_topbar_relative_wrapper"
    >
      <MenuBar.Menu value="col_two_handle">
        <BlockMenubarTrigger
          labelIcon="twoColumns" onClick={childAmount === 2 ? undefined : onTwoColumnsHandle} isOpened={childAmount === 2}
          tooltip="Two Columns" containerId={block.id}
        />
      </MenuBar.Menu>
      <MenuBar.Menu value="col_three_handle">
        <BlockMenubarTrigger
          labelIcon="threeColumns" onClick={childAmount === 3 ? undefined : onThreeColumnsHandle} isOpened={childAmount === 3}
          tooltip="Three Columns" containerId={block.id}
        />
      </MenuBar.Menu>
      <MenuBar.Menu value="col_four_handle">
        <BlockMenubarTrigger
          labelIcon="fourColumns" onClick={childAmount === 4 ? undefined : onFourColumnsHandle} isOpened={childAmount === 4}
          tooltip="Four Columns" containerId={block.id}
        />
      </MenuBar.Menu>
      <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="col_layout">
        <BlockMenubarTrigger
          isDropdown
          label={"Layout"}
          isOpened={childAmount === 4 ? false : value === 'col_layout'}
          disabled={childAmount === 4}
        />
        {childAmount !== 4 && <MenuBar.Portal>
          <BlockMenubarContent>
            <BlockMenubarOptionList
              data={columnLayoutModes[block.children.length]}
              onOptionClick={onLayoutModeHandle}
              activeKey={block.style as string}
            />
          </BlockMenubarContent>
        </MenuBar.Portal>}
      </MenuBar.Menu>
      <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="col_group_delete">
        <BlockMenubarTrigger labelIcon="trash" onClick={onDeleteColumnsHandle} isDelete tooltip="Delete" containerId={block.id} />
      </MenuBar.Menu>
    </MenuBar.Root>
  );
}

export default ColumnGroupMenubar;
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import PhIcon from '../../../../shared/Icon/PhIcon';
import { NavigationLink } from '../../../../store/reducers/api/api.types';
import Tooltip from '../../../ToolTip/Tooltip';

interface Props {
  item: NavigationLink
  onLinkDelete: (id: string) => void
  isLoading?: boolean
}

const MenuItem: React.FC<Props> = ({ item, onLinkDelete, isLoading }) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({ id: item.id! });

  const style = { transform: CSS.Transform.toString(transform), transition };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="flex items-center select-none relative p-2 group/list hover:bg-gray-100 rounded-lg cursor-grab"
    >
      <div
        {...listeners}
        ref={setActivatorNodeRef}
        className='flex gap-[6px] w-full items-center'
      >
        <div className="w-6 h-6 flex justify-center items-center">
          <PhIcon name="dotsSixVertical" size={16} className='brand-text' weight='bold' />
        </div>
        <span className='brand-text text-sm'>{!!item.text ? item.text : 'Link'}</span>
      </div>
      <Tooltip content={'Delete'} side={'top'} sideOffset={10} container={item.id} triggerClassName={'opacity-0 group-hover/list:opacity-100'} >
        <div onClick={isLoading ? undefined : onLinkDelete.bind(onLinkDelete, item.id)} className='cursor-pointer' >
          <PhIcon name="trash" size={16} className='text-[#F46262]' />
        </div>
      </Tooltip>
    </div>
  );
};

export default MenuItem;
import React, { useEffect, useRef, useState } from "react";
import AnimateHeight, { Height } from "react-animate-height"

type Props = {
  children: React.ReactNode
}

const AnimateHeightWrapper: React.FC<Props> = ({ children }) => {
  const [height, setHeight] = useState<Height>('auto');
  const contentDiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = contentDiv.current as HTMLDivElement;
    const resizeObserver = new ResizeObserver(() => {
      setHeight(element.clientHeight);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <AnimateHeight
      height={height}
      contentClassName="auto-content"
      contentRef={contentDiv}
      disableDisplayNone
      className="animate_height_wrapper"
    >
      {children}
    </AnimateHeight>
  )
}

export default AnimateHeightWrapper;
import React from "react";
import { useEditorRef } from "@udecode/plate-common/react";

import { Popover, PopoverTrigger, PopoverPortal, PopoverContent } from "../../../../../shared/Popover/Popover"
import { PlatePricingElement } from "../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { textColorFromColorClass } from "../../../../../utils/color.util";
import { useAppSelector } from "../../../../../store/hooks/redux-hooks";
import ButtonSelector from "../../../../../shared/ButtonSelector/ButtonSelector";
import PhIcon from "../../../../../shared/Icon/PhIcon";

type Props = {
  tableIndex: number
  element: PlatePricingElement
}

const Config: React.FC<Props> = ({ element, tableIndex }) => {
  const editor = useEditorRef()
  const colors = useAppSelector(state => state.page_colors.activePalette.colors)

  const textColor = textColorFromColorClass(element.primary_color, colors)
  const table = element.pricing_tables_attributes[tableIndex]

  const PRICING_OPTIONS = [
    { name: "single", label: "Single" },
    { name: "multiple", label: "Multiple" },
  ]

  const onPricingOptionHandle = (option: string) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedLineItems = option === 'multiple'
          ? [...table.line_items_attributes]
          : table.line_items_attributes.map((item) => ({ ...item, optional_default: false }))
        return { ...table, select_type: option, line_items_attributes: updatedLineItems }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  return (
    <div className="relative w-full">
      <div className="absolute bottom-6 right-6">
        <Popover>
          <PopoverTrigger>
            <div
              className={`btn btn-clear btn-icon rounded-full opacity-70 hover:bg-opacity-30 hover:opacity-100 color-${textColor}`}
            >
              <PhIcon name={"gear"} size={16} />
            </div>
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent sideOffset={5} side="bottom" align="start">
              <div className="w-64 border-solid border border-gray-100 rounded-xl bg-white drop-shadow z-30">
                <div className="flex flex-col p-4 gap-4">
                  <div className="uppercase font-semibold text-sm text-left brand-subtext">
                    Options Select Type
                  </div>
                  <ButtonSelector data={PRICING_OPTIONS} current={table.select_type} setCurrent={onPricingOptionHandle} />
                </div>
              </div>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </div>
    </div>
  )
}

export default Config
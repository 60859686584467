import React, { useState } from "react";

import PhIcon from "../../../shared/Icon/PhIcon";

type LibraryContentProps = {
  testimonials: {
    id: string,
    content: string,
    user_name: string,
    user_position: string,
    image_external_id: string | null,
    image_source: string | null,
    image_url: string | null
  }[],
  toggleIdToAdd: (id: string) => void
}

const LibraryContent: React.FC<LibraryContentProps> = ({ testimonials, toggleIdToAdd }) => {

  return (
    <section className="p-5 flex gap-4 flex-wrap">
      {testimonials.map(testimonial =>
        <TestimonialCard
          testimonial={testimonial}
          key={testimonial.id}
          toggleIdToAdd={toggleIdToAdd}
        />
      )}
    </section>
  )
}

export default LibraryContent;


type TestimonialCardProps = {
  testimonial: {
    id: string,
    content: string,
    user_name: string,
    user_position: string,
    image_external_id: string | null,
    image_source: string | null,
    image_url: string | null
  },
  toggleIdToAdd: (id: string) => void
}

export const TestimonialCard: React.FC<TestimonialCardProps> = ({ testimonial, toggleIdToAdd }) => {
  const [checked, setChecked] = useState(false)

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
    toggleIdToAdd(testimonial.id)
  }

  return (
    <div
      className="flex items-center flex-col w-[452px] p-4 rounded-2xl border bg-white border-black/20 shadow-md">
      <div className="flex w-full gap-2">
        <div className="w-[56px] h-[56px] flex items-center gap-4 flex-col bg-gray-100 rounded-full justify-center cursor-pointer">
          {
            testimonial.image_url
              ? <img src={testimonial.image_url} alt="img" className="w-[56px] h-[56px] rounded-full cursor-pointer" draggable={false} />
              : <PhIcon name='fileImage' size={20} className="brand-subtext" />
          }
        </div>
        <div className="flex flex-col flex-grow gap-1 p-1">
          <div
            className={`
            w-full text-base font-semibold p-0 outline-none border-none focus:border-none
            focus:outline-none focus:shadow-none focus:drop-shadow-none focus:ring-0 bg-transparent
            `}
          >
            {testimonial.user_name}
          </div>
          <div
            className={`
          w-full text-sm font-normal p-0 outline-none border-none focus:border-none
          focus:outline-none focus:shadow-none focus:drop-shadow-none focus:ring-0 bg-transparent
        `}
          >
            {testimonial.user_position}
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <input
              checked={checked}
              type="checkbox"
              onChange={onCheckboxChange}
              className="w-4 h-4 text-[#00A7F8] bg-white border border-[#DBDBDB] focus:outline-none focus:ring-0 rounded-md" />
          </div>
        </div>
      </div>
      <div className="p-1 flex w-full">
        <div
          className={`
        text-base paragraph-font-family font-normal resize-none p-0 border-none focus:border-none
        focus:outline-none focus:shadow-none focus:drop-shadow-none focus:ring-0 w-full min-h-[115px] bg-transparent
        `}
        >
          {testimonial.content}
        </div>
      </div>
    </div>
  )
}
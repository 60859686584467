import React, { useRef, useState } from "react";
import { useEditorRef } from "@udecode/plate-common/react";

import FooterSettings from "./FooterSettings/FooterSettings";
import { useAppSelector } from "../../../store/hooks/redux-hooks";
import Logo from "./Logo/Logo";
import Menu from "./Menu/Menu";
import Socials from "./Socials/Socials";
import { useElementWidth } from "../../../hooks/useElementWidth";


const PageFooter: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false)
  const {
    footer_enabled, footer_background_color, social_links_bar, footer_menu_buttons,
    footer_display_menu, footer_display_logo, logo_url, nav_router, template
  } = useAppSelector(state => state.page_navigation)
  const { readOnly, isLandingPage } = useAppSelector((state) => state.page_addendums);
  const editor = useEditorRef();
  const navRef = useRef<HTMLDivElement>(null);
  const navWidth = useElementWidth(navRef) || 0

  const readOnlyMode = readOnly || isLandingPage;
  const bgColor = footer_background_color ? `background-${footer_background_color}` : 'bg-black';
  const show_logo = readOnlyMode ? template ? footer_display_logo : footer_display_logo && logo_url : footer_display_logo
  const footer_menu_ready_items = footer_menu_buttons.filter(link => {
    if (link.href && link.url_type === 'section') return true
    if (link.href && link.url_type === 'page') {
      const page = nav_router.find(page => page.id === link.href)
      return page && (isLandingPage ? page.status === 'active' : page.status !== 'building')
    }
    return false
  })
  const show_footer_menu = readOnlyMode ? footer_display_menu && footer_menu_ready_items.length > 0 : footer_display_menu

  const onNavWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!readOnlyMode) editor.deselect();
  }

  if (readOnlyMode && !footer_enabled) return null;

  return (
    <nav
      className={`
        flex flex-col items-center gap-4 justify-center relative w-full
        ${isLandingPage ? "" : "rounded-b-lg"} ${bgColor} py-8 min-h-[86px]
      `}
      onMouseEnter={setIsHovered.bind(setIsHovered, true)}
      onMouseLeave={setIsHovered.bind(setIsHovered, false)}
      onClick={onNavWrapperClick}
      ref={navRef}
    >
      {footer_enabled
        ?
        <>
          {show_logo && <Logo />}
          {show_footer_menu && <Menu navWidth={navWidth} />}
          {social_links_bar && <Socials />}
        </>
        :
        <span className="text-gray-300 text-xs bg-white/10 px-[5px] py-[3px] rounded-lg brand-font-family cursor-default" >
          Bar Hidden
        </span>
      }
      {!readOnlyMode && <FooterSettings showTrigger={isHovered} />}
    </nav>
  );
}

export default PageFooter;
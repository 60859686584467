import React, { useState } from "react";

import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import BuilderNavMenu from "../../../../shared/BuilderNavMenu/BuilderNavMenu";
import BuilderNavMenuToolbar from "../../../../shared/BuilderNavMenu/BuilderNavMenuToolbar/BuilderNavMenuToolbar";
import { useUpdateNavLinksAttributeMutation } from "../../../../store/reducers/api/navbar/navbar.api";

type Props = {
  isMobile?: boolean
  closeMenu?: () => void
}

const Menu: React.FC<Props> = ({ isMobile, closeMenu }) => {
  const [value, setValue] = useState<string>("");
  const [isHovered, setIsHovered] = useState(false)
  const { nav_menu_buttons, nav_text_color, nav_router, template } = useAppSelector(state => state.page_navigation)
  const { readOnly, isLandingPage } = useAppSelector(state => state.page_addendums)
  const [updateNavLinksAttribute, { isLoading }] = useUpdateNavLinksAttributeMutation()

  const readOnlyMode = readOnly || isLandingPage
  const itemsToDisplay = readOnlyMode
    ? nav_menu_buttons.filter(link => {
      if (link.href && link.url_type === 'section') {
        if (template) {
          return true
        }
        const page = nav_router.find(page => page.id === link.anchor_page_id)
        return page && (isLandingPage ? page.status === 'active' : page.status !== 'building')
      }
      if (link.href && link.url_type === 'page') {
        const page = nav_router.find(page => page.id === link.href)
        return page && (isLandingPage ? page.status === 'active' : page.status !== 'building')
      }
      return false
    })
    : nav_menu_buttons
  const textColor = nav_text_color ? `${nav_text_color}` : 'text-white';

  const onLinkAdd = async () => await updateNavLinksAttribute([
    ...nav_menu_buttons,
    {
      background_color: null, href: null, kind: "navbar", position: nav_menu_buttons.length,
      text: null, text_color: null, url_type: null, anchor_page_id: null
    }
  ])
  const onLinkDelete = async (id: string) => {
    const updatedLinks = nav_menu_buttons.map(btn => btn.id === id ? { ...btn, _destroy: true } : btn)
    await updateNavLinksAttribute(updatedLinks)
  }
  const onOrderUpdate = async (items: any) => {
    const updatedLinks = items.map((item: any, index: number) => ({ ...item, position: index }))
    await updateNavLinksAttribute(updatedLinks)
  }

  const onLinkUpdate = async (id: string, title: string | null, url_type: string, section_id?: string, page_id?: string, anchor_page_id?: string) => {
    const updatedLinks = nav_menu_buttons.map(btn => btn.id === id
      ? {
        ...btn,
        text: title,
        url_type,
        href: url_type === 'section' ? section_id! : page_id!,
        anchor_page_id: anchor_page_id ? anchor_page_id : null
      }
      : btn)
    await updateNavLinksAttribute(updatedLinks)
  }

  return (
    <section className={`flex justify-center min-h-10  ${isMobile ? "my-10" : ""} `}>
      <div
        onMouseEnter={setIsHovered.bind(setIsHovered, true)}
        onMouseLeave={setIsHovered.bind(setIsHovered, false)}
        className="flex justify-center group/nav_menu"
      >
        <div
          className={`
        flex w-full items-center relative justify-center rounded-lg px-8 py-2 border border-transparent 
         ${readOnlyMode ? "" : "hover:border-[#3C82F699]"} min-w-[266px] 
        `}
        >
          <BuilderNavMenu
            itemsToDisplay={itemsToDisplay}
            onOrderUpdate={onOrderUpdate}
            text_color={textColor}
            onLinkDelete={onLinkDelete}
            onLinkUpdate={onLinkUpdate}
            isMobile={isMobile}
            isLoading={isLoading}
            closeMenu={closeMenu}
          />
          {
            !itemsToDisplay.length &&
            <span className={`text-sm ${textColor} cursor-pointer`} onClick={setValue.bind(setValue, "footer_menu")}  >
              Click To Manage Menu Links
            </span>
          }
          {!readOnlyMode &&
            <BuilderNavMenuToolbar
              value={value}
              setValue={setValue}
              showTrigger={readOnly ? false : isHovered}
              onLinkAdd={onLinkAdd}
              onOrderUpdate={onOrderUpdate}
              onLinkDelete={onLinkDelete}
              itemsToDisplay={itemsToDisplay}
              menuSide="bottom"
              isLoading={isLoading}
            />}
        </div>
      </div>
    </section>
  );
}

export default Menu;
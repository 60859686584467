import React, { useState } from "react";

import { PlateElementAiTextBlock } from "../../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import { useAppSelector } from "../../../../../store/hooks/redux-hooks";
import { useCreateResourceSlotMutation } from "../../../../../store/reducers/api/resources/resources.api";
import { getPageDataAttributes } from "../../../../../store/reducers/api/data-attributes.util";
import FullScreenSpinner from "../../../../../shared/LoadingComponents/FullScreenSpinner";
import Checkbox from "../../../../../shared/Inputs/Checkbox";
import PhIcon from "../../../../../shared/Icon/PhIcon";
import DecoratedInput from "../../../../../shared/Inputs/DecoratedInput";

type Props = {
  element: PlateElementAiTextBlock,
  onToggleSlotResouce: (resourceId: string) => void
}

const ResourcesCheckbox: React.FC<Props> = ({ onToggleSlotResouce, element }) => {
  const { resource_slots } = useAppSelector(state => state.page_resources)
  const [createResourceSlot, { isLoading }] = useCreateResourceSlotMutation()
  const requestData = getPageDataAttributes()
  const [resourceToAdd, setResourceToAdd] = useState(false)
  const [resource, setResource] = useState('')

  const onCreateResourceSlot = async () => {
    await createResourceSlot(resource).catch((e) => console.log(e))
    setResource('')
    setResourceToAdd(false)
  }

  return (
    <section className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="brand-text text-sm font-medium">Resources to use</div>
        {resource_slots.length > 0 &&
          <a
            className={"brand-font-family text-secondary text-sm font-medium"}
            href={`/templates/${requestData?.page_id}/resource_slots`}
            style={{ textDecorationLine: "none" }}
          >
            Manage
          </a>
        }
      </div>
      <div className="flex flex-col gap-2">
        {resource_slots.map((resource, i) => (
          <Checkbox
            key={i}
            htmlFor={resource.id}
            label={resource.about}
            value={element.details.template_resource_slot_ids.includes(resource.id)}
            setValue={_checked => onToggleSlotResouce(resource.id)}
          />
        ))}
      </div>
      {
        resourceToAdd
          ?
          <DecoratedInput
            value={resource}
            onChange={setResource}
            contentAfter={
              <div className="flex gap-2 items-center justify-center">
                <div onClick={onCreateResourceSlot} className="brand-font-family text-secondary cursor-pointer text-sm">Save</div>
                <div onClick={setResourceToAdd.bind(setResourceToAdd, !resourceToAdd)} className="cursor-pointer">
                  <PhIcon name="x" className="brand-text" />
                </div>
              </div>
            }
          />
          :
          <div
            className="text-secondary cursor-pointer flex items-center text-sm font-medium"
            onClick={setResourceToAdd.bind(setResourceToAdd, !resourceToAdd)}
          >
            <PhIcon name="plus" className="text-secondary cursor-pointer mr-2" /> Add
          </div>
      }

      {isLoading && <FullScreenSpinner />}
    </section>
  )
}

export default ResourcesCheckbox

import React, { memo } from "react"

type Props = {
  value: boolean,
  setValue: (value: boolean) => void,
  label?: string
  htmlFor: string
}


const Checkbox: React.FC<Props> = memo(({ value, setValue, label, htmlFor }) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.checked)
  const labelClick = () => {
    const checkbox = document.getElementById(htmlFor) as HTMLInputElement;
    if (checkbox) {
      checkbox.click();
    }
  };
  return (
    <div className="checkbox-brand-wrapper">
      <input id={htmlFor} type="checkbox" className="brand-checkbox" checked={value} onChange={onChange} />
      <svg><use xlinkHref="#checkmark-28" /></svg>
      <label htmlFor={htmlFor} className="m-0" />
      {label &&
        <div className="brand-font-family text-gray-900 text-sm cursor-pointer" onClick={labelClick}>{label}</div>
      }
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="checkmark-28" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeMiterlimit="10" fill="none" d="M22.9 3.7l-15.2 16.6-6.6-7.1" />
        </symbol>
      </svg>
    </div>
  )
})

export default Checkbox

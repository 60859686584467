import React from "react";

type Props = {
  title: string
  subTitle: string
}

const ContentDetails: React.FC<Props> = ({ title, subTitle }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="brand-text text-sm font-medium">{title}</div>
      <div className="brand-font-family text-gray-900 text-sm ">{subTitle}</div>
    </div>
  );
};


export default ContentDetails;
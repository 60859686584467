import React from 'react'

import { PlateElementAiTextBlock } from '../../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin'


type Props = {
  element: PlateElementAiTextBlock,
  length_min: number,
  length_max: number,
  onMaxLengthChange: (length_max: number) => void
  onMinLengthChange: (length_min: number) => void
}

const TextLength: React.FC<Props> = ({ element, onMaxLengthChange, onMinLengthChange, length_max, length_min }) => {

  const onMinHandle = (e: React.ChangeEvent<HTMLInputElement>) => onMinLengthChange(+e.target.value)
  const onMaxHandle = (e: React.ChangeEvent<HTMLInputElement>) => onMaxLengthChange(+e.target.value)

  const onChangeMinValidator = (min: number | null, max: number | null) => {
    if (typeof min !== 'number' && typeof max === 'number') {
      return `Must be less than ${max}`
    }
    if (typeof min === 'number' && typeof max === 'number' && min > max) {
      return `Must be less than ${max}`
    }
    if (typeof min === 'number' && min < 1) {
      return `Must be greater than 0`
    }
  }

  const onChangeMaxValidator = (min: number | null, max: number | null) => {
    if (typeof min === 'number' && typeof max !== 'number') {
      return `Must be greater than ${min}`
    }
    if (typeof min === 'number' && typeof max === 'number' && min > max) {
      return `Must be greater than ${min}`
    }
    if (typeof max === 'number' && max < 1) {
      return `Must be greater than 0`
    }
  }

  return (
    <div className="flex w-full gap-4">
      <div className="flex flex-col gap-2 w-[50%]">
        <div className="brand-text text-sm font-medium" >
          {`Min # of ${element.details.length_type === 'words' ? 'Words' : element.details.length_type === 'sentences' ? 'Sentences' : "Paragraphs"}`}
        </div>
        <input
          className={`brand_input ${onChangeMinValidator(length_min, length_max) ? "error" : ""}`}
          type="number"
          value={length_min}
          onChange={onMinHandle}
          min={1}
        />
        {onChangeMinValidator(length_min, length_max) && <div className="brand_input-error">{onChangeMinValidator(length_min, length_max)}</div>}
      </div>
      <div className="flex flex-col gap-2 w-[50%]">
        <div className="brand-text text-sm font-medium" >
          {`Max # of ${element.details.length_type === 'words' ? 'Words' : element.details.length_type === 'sentences' ? 'Sentences' : "Paragraphs"}`}
        </div>
        <input
          className={`brand_input ${onChangeMaxValidator(length_min, length_max) ? "error" : ""}`}
          type="number"
          value={length_max}
          onChange={onMaxHandle}
          min={1}
        />
        {onChangeMaxValidator(length_min, length_max) && <div className="brand_input-error">{onChangeMaxValidator(length_min, length_max)}</div>}
      </div>
    </div>
  )
}

export default TextLength;
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { createApi } from "unsplash-js";
import { debounce } from 'lodash';

import { UnsplashImage, UnsplashPhotoResponse } from "../unsplash.types";
import ImageCard from "../ImageCard/ImageCard";


type Props = {
  setImageFromUnsplash: (image: UnsplashImage) => void;
  query: string;
}

const UnsplashTab: React.FC<Props> = ({ setImageFromUnsplash, query }) => {
  const [images, setImages] = useState<UnsplashImage[]>([])
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)

  const unsplashClient = createApi({
    accessKey: '1cmGaycBs6l-UIo9QpwT7DtIdbfEkUNApioW4VdAfpg'
  })


  useEffect(() => {
    debouncedApiCall(query)
  }, [query])


  const getPhotos = async (search: string) => {
    await unsplashClient.search.getPhotos({ query: search, page: 1, perPage: 20 })
      .then(response => {
        if (response) {
          const data = response as UnsplashPhotoResponse;
          const totalImages = data.response.total;
          const updatedImages = [...data.response.results];
          setImages(updatedImages);
          if (updatedImages.length < totalImages) {
            setPage(2)
            setHasMore(true)
          } else {
            setPage(1)
            setHasMore(false)
          }
        }
      })
      .catch(error => {
        console.log('Unsplash loading images error:', error)
        setImages([])
        setHasMore(false)
      })
  }

  const getMorePhotos = async () => {
    await unsplashClient.search.getPhotos({ query: query.trim().length > 0 ? query : 'random', page, perPage: 20 })
      .then(response => {
        if (response) {
          const data = response as UnsplashPhotoResponse;
          const totalImages = data.response.total;
          const updatedImages = [...images, ...data.response.results];
          setImages(updatedImages);
          if (updatedImages.length < totalImages) {
            setPage(page + 1)
            setHasMore(true)
          } else {
            setHasMore(false)
          }
        }
      })
      .catch(error => {
        console.log('Unsplash loading images error:', error)
        setImages([])
        setHasMore(false)
      })
  }

  const debouncedApiCall = useCallback(debounce((query) => {
    getPhotos(query.trim().length > 0 ? query : 'random')
  }, 800), []);

  return (
    <InfiniteScroll
      dataLength={images.length}
      next={getMorePhotos}
      hasMore={hasMore}
      loader={<div className="text-sm brand-text text-center">Loading . . . </div>}
      endMessage={
        <div className="pt-4 mb-12 pb-8 text-center brand-subtext text-xs">
          {images.length === 0 ? "No images found, try to change search params" : "All images loaded"}
        </div>
      }
      scrollableTarget={'scrollable-dialog-content-id'}
    >
      <div className="px-6 pb-5 flex flex-col">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {images.map((image, i) => (<ImageCard image={image} setImage={setImageFromUnsplash} key={i} />))}
        </div>
      </div>
    </InfiniteScroll>
  )
}

export default UnsplashTab
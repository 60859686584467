import React, { useEffect, useState } from "react";


export const useElementHeight = (ref: React.RefObject<HTMLDivElement>) => {
  const [heightWithoutPadding, setHeightWithoutPadding] = useState<number | null>(null);

  const calculateAndSetHeight = () => {
    if (ref.current) {
      const element = ref.current;

      const style = getComputedStyle(element);
      const paddingTop = parseInt(style.paddingTop, 10);
      const paddingBottom = parseInt(style.paddingBottom, 10);
      const height = element.clientHeight - paddingTop - paddingBottom;

      setHeightWithoutPadding(height);
    }
  };

  useEffect(() => {
    calculateAndSetHeight();

    const element = ref.current;
    const resizeObserver = new ResizeObserver(calculateAndSetHeight);
    const mutationObserver = new MutationObserver(calculateAndSetHeight);

    if (element) {
      resizeObserver.observe(element);

      mutationObserver.observe(element, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true,
      });
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [ref]);

  return heightWithoutPadding;
}
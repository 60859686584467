import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static values = { html: { type: Boolean, default: false }, dependentSelectId: { type: String, default: "" } }

  connect() {
    // Check first if this element is a select element. If yes, return that.
    const selectElement = this.element.tagName === "SELECT" ? this.element : this.element.querySelector("select");

    let options = {
      maxOptions: null,
      allowEmptyOption: true,
      onDropdownOpen: () => {
        document.body.classList.add('dropdown-open')
      },
      onDropdownClose: () => {
        document.body.classList.remove('dropdown-open')
      },
    };

    if (this.htmlValue) {
      options.render = {
        option: function(data, _escape) {
          return '<div class="option">' + data.text + '</div>';
        },
        item: function(data, escape) {
          return '<div class="item">' + data.text + '</div>';
        }
      };
    }

    this.select = new TomSelect(selectElement, options)
  }

  disconnect() {
    this.select.destroy()
  }

  clear() {
    console.log("Clear the tom select")
    this.select.clear();
  }
}

import React from 'react';

type Props = {
  data: {
    key: string;
    value: string;
  }[]
  onSelect: (key: string) => void;
  activeKey: string;
  btnClass?: string;
}

const BlockMenubarSelector: React.FC<Props> = ({ data, onSelect, activeKey, btnClass }) => {
  return (
    <div className='p-1 border border-[#DFD9D980] rounded-xl bg-gray-100 flex items-center gap-2 w-full'>
      {data.map((item, i) =>
        <button
          key={i}
          onClick={onSelect.bind(onSelect, item.key)}
          className={`
          w-full px-3 py-2 rounded-lg brand-font-family text-xs font-medium transition-all duration-300 ease-in-out flex items-center justify-center
            ${activeKey === item.key ? "text-white bg-secondary-dark" : " text-secondary-dark bg-transparent hover:bg-white"} text-nowrap ${btnClass}
          `}
        >
          {item.value}
        </button>
      )}
    </div>
  )
}

export default BlockMenubarSelector;
import React, { memo } from "react";

import Slider from "../Sliders/Slider";

type Props = {
  label: string;
  value: number[];
  onValueChange: (value: number[]) => void;
  min?: number;
  max?: number;
}

const BlockMenubarSlider = memo(({ label, value, min, max, onValueChange }: Props) => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex justify-between capitalize">
        <span className="font-medium text-sm brand-font-family text-gray-700">{label}</span>
        <span className="font-medium text-sm brand-font-family text-gray-700">{value[0]}%</span>
      </div>
      <div className="pt-2">
        <Slider
          min={min}
          max={max}
          value={value}
          onValueChange={onValueChange}
          trackClassName="border-gray-300"
          rangeClassName="bg-secondary-dark border-secondary-dark"
          thumbClassName="border-secondary-dark focus:border-secondary-dark"
        />
      </div>
    </div>
  )
})

export default BlockMenubarSlider

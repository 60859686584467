import React from "react";
import { useLinkToolbarButton, useLinkToolbarButtonState } from '@udecode/plate-link/react';

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";

const Link: React.FC = () => {
  const state = useLinkToolbarButtonState();
  const { props } = useLinkToolbarButton(state);
  return <BlockMenubarTrigger
    labelIcon="link"
    onClick={props.onClick}
    tooltip="Link"
    containerId="floating-toolbar"
  />
}

export default Link;
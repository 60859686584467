import React from "react";

import PhIcon from "../Icon/PhIcon";

type Props = {
  onClose: () => void;
  label: string;
}

const BlockMenubarHeader: React.FC<Props> = ({ onClose, label }) => {
  return (
    <div className="flex gap-[10px] items-center justify-between px-4 -mx-4 pb-4 border-b border-gray-100">
      <span className="brand-text font-medium text-sm cursor-default">{label}</span>
      <div className="cursor-pointer" onClick={onClose} >
        <PhIcon name="x" size={16} className="text-black" />
      </div>
    </div>
  );

}

export default BlockMenubarHeader;
import React, { memo } from "react"

import PhIcon from "../../../../../../shared/Icon/PhIcon"

type Props = {
  itemView: string
  goBack: () => void
}

const MenuHeader = memo(({ goBack, itemView }: Props) => {
  return (
    <div className="flex gap-1 justify-between items-center border-b border-gray-100 p-1 pl-4">
      <span className="text-xs font-semibold text-gray-700 uppercase">
        {itemView === "options" && "Item Options"}
        {itemView === "recurring" && "Recurring Billing"}
        {itemView === "quantity" && "Quantity Options"}
        {itemView === "variables" && "Quantity Variable"}
        {itemView === "discount" && "Discount"}
        {itemView === "value" && "Value"}
      </span>
      <button
        onClick={goBack}
        className="btn btn-small btn-light-gray flex gap-1 justify-center text-gray-700"
      >
        <PhIcon name={'arrowUDownLeft'} size={16} />
      </button>
    </div>
  )
})

export default MenuHeader;

import React, { useEffect, useRef, useState } from 'react'
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import PhIcon from '../../../../../shared/Icon/PhIcon';
import { PlateElementAiTextBlock } from '../../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin';
import Tooltip from '../../../../../shared/ToolTip/Tooltip';

type Props = {
  element: PlateElementAiTextBlock
  onListKindChange: (list_kind: string) => void
}

const ListOptions: React.FC<Props> = ({ onListKindChange, element }) => {
  const [open, setOpen] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>();

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const updateWidth = () => {
    if (elementRef.current) {
      setWidth(elementRef.current.offsetWidth);
    }
  };

  const labelMap = {
    standard: "Text Only",
    question_answer: "Question & Answer",
    bolded_summary: "Bolded Summary List",
    bolded_summary_paragraph: "Paragraphs with Summary",
    objection: "Address Concerns",
    feature_benefit: "Feature & Benefits"
  }



  return (
    <div className='flex flex-col w-full gap-2'>
      <div className="brand-text text-sm font-medium">List type</div>
      <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        <DropdownMenu.Trigger className='focus-visible:outline-none'>
          <div
            className={`flex w-full items-center justify-between rounded-xl border text-sm hover:border-gray-400
            ${open ? "active_input text-gray-500" : "border-gray-300 text-brand"} p-[14px] bg-white
            `}
            ref={elementRef}
          >
            {labelMap[element.details.list_kind]}
            <PhIcon name="caretDown" size={16} />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            side="bottom"
            className="flex flex-col items-center rounded-xl shadow-lg bg-white p-2 z-[10000] gap-2"
            style={{ width }}
          >
            <DropdownMenu.Item
              className={`outline-none w-full p-3 flex items-center justify-between rounded-lg
                hover:bg-gray-100 ${element.details.list_kind === "standard" ? "bg-gray-100" : ""}`}
              onSelect={onListKindChange.bind(onListKindChange, "standard")}
              id='dropdown-item-anchor'
            >
              <div className='text-gray-900 brand-font-family text-sm'>Text Only</div>
              <Tooltip
                side='right'
                sideOffset={25}
                container='dropdown-item-anchor'
                contentClassName='bg-white rounded-xl p-2 text-xs text-gray-700'
                content={
                  <ul className={`${element.details.list_style === "ordered" ? "list-decimal" : "list-disc"} list-outside pl-4`}>
                    <li className={"mt-2 first:mt-0"}>Venenatis ligula adipiscing fermentum</li>
                    <li className={"mt-2 first:mt-0"}>Malesuada amet adipiscing vestibulum</li>
                    <li className={"mt-2 first:mt-0"}>Malesuada adipiscing tristique quam</li>
                  </ul>
                }
              >
                <div>
                  <PhIcon name="info" size={16} className="brand-text" />
                </div>
              </Tooltip>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              className={`outline-none w-full p-3 flex items-center justify-between rounded-lg
                hover:bg-gray-100 ${element.details.list_kind === "question_answer" ? "bg-gray-100" : ""}`}
              onSelect={onListKindChange.bind(onListKindChange, "question_answer")}
              id='dropdown-item-anchor'
            >
              <div className='text-gray-900 brand-font-family text-sm'>Question & Answer</div>
              <Tooltip
                side='right'
                sideOffset={25}
                container='dropdown-item-anchor'
                contentClassName='bg-white rounded-xl p-2 text-xs text-gray-700'
                content={
                  <ul className={`${element.details.list_style === "ordered" ? "list-decimal" : "list-disc"} list-outside pl-4`}>
                    <li className={"mt-2 first:mt-0"}>
                      <div className="font-semibold">Egestas aenean pellentesque sit?</div>
                      <div className="mt-1"> Risus Ultricies Vulputate amet.</div>
                    </li>
                    <li className={"mt-2 first:mt-0"}>
                      <div className="font-semibold">Egestas pellentesque inceptos malesuada?</div>
                      <div className="mt-1">Mollis dolor fermentum vulputate.</div>
                    </li>
                  </ul>
                }
              >
                <div>
                  <PhIcon name="info" size={16} className="brand-text" />
                </div>
              </Tooltip>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              className={`outline-none w-full p-3 flex items-center justify-between rounded-lg
                hover:bg-gray-100 ${element.details.list_kind === "bolded_summary" ? "bg-gray-100" : ""}`}
              onSelect={onListKindChange.bind(onListKindChange, "bolded_summary")}
              id='dropdown-item-anchor'
            >
              <div className='text-gray-900 brand-font-family text-sm'>Bolded Summary List</div>
              <Tooltip
                side='right'
                sideOffset={25}
                container='dropdown-item-anchor'
                contentClassName='bg-white rounded-xl p-2 text-xs text-gray-700'
                content={
                  <ul className={`${element.details.list_style === "ordered" ? "list-decimal" : "list-disc"} list-outside pl-4`}>
                    <li className={"mt-2 first:mt-0"}>
                      <span className="font-semibold mr-1">Nullam Sem -</span>
                      Venenatis ligula adipiscing fermentum sit
                    </li>
                    <li className={"mt-2 first:mt-0"}>
                      <span className="font-semibold mr-1">Purus -</span>
                      Malesuada amet adipiscing porta vestibulum
                    </li>
                    <li className={"mt-2 first:mt-0"}>
                      <span className="font-semibold mr-1">Commodo Mollis -</span>
                      Malesuada adipiscing tristique quam sollicitudin
                    </li>
                  </ul>
                }
              >
                <div>
                  <PhIcon name="info" size={16} className="brand-text" />
                </div>
              </Tooltip>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              className={`outline-none w-full p-3 flex items-center justify-between rounded-lg
                hover:bg-gray-100 ${element.details.list_kind === "bolded_summary_paragraph" ? "bg-gray-100" : ""}`}
              onSelect={onListKindChange.bind(onListKindChange, "bolded_summary_paragraph")}
              id='dropdown-item-anchor'
            >
              <div className='text-gray-900 brand-font-family text-sm'>Paragraphs with Summary</div>
              <Tooltip
                side='right'
                sideOffset={25}
                container='dropdown-item-anchor'
                contentClassName='bg-white rounded-xl p-2 text-xs text-gray-700'
                content={
                  <>
                    <div>
                      <div className="font-semibold mr-1"> Bibendum Dolor Sem </div>
                      <div className="mt-1"> Morbi leo risus, porta ac consectetur ac, vestibulum at eros. </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-semibold mr-1"> Cursus Bibendum </div>
                      <div className="mt-1"> Nullam quis risus eget urna mollis ornare vel eu leo.</div>
                    </div>
                  </>
                }
              >
                <div>
                  <PhIcon name="info" size={16} className="brand-text" />
                </div>
              </Tooltip>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              className={`outline-none w-full p-3 flex items-center justify-between rounded-lg
                hover:bg-gray-100 ${element.details.list_kind === "objection" ? "bg-gray-100" : ""}`}
              onSelect={onListKindChange.bind(onListKindChange, "objection")}
              id='dropdown-item-anchor'
            >
              <div className='text-gray-900 brand-font-family text-sm'>Address Concerns</div>
              <Tooltip
                side='right'
                sideOffset={25}
                container='dropdown-item-anchor'
                contentClassName='bg-white rounded-xl p-2 text-xs text-gray-700'
                content={
                  <>
                    <div>
                      <div className="font-semibold mr-1"> Cursus Mattis Sem </div>
                      <div className="mt-1"> Pellentesque condimentum vestibulum venenatis ornare </div>
                      <div className="italic mt-1"> Bibendum dolor ornare condimentum pharetra </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-semibold mr-1"> Egestas Tortor </div>
                      <div className="mt-1"> Cras ligula sollicitudin porta aenean </div>
                      <div className="italic mt-1"> Bibendum mullam etiam fringilla sag </div>
                    </div>
                  </>
                }
              >
                <div>
                  <PhIcon name="info" size={16} className="brand-text" />
                </div>
              </Tooltip>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              className={`outline-none w-full p-3 flex items-center justify-between rounded-lg
                hover:bg-gray-100 ${element.details.list_kind === "feature_benefit" ? "bg-gray-100" : ""}`}
              onSelect={onListKindChange.bind(onListKindChange, "feature_benefit")}
              id='dropdown-item-anchor'
            >
              <div className='text-gray-900 brand-font-family text-sm'>Feature & Benefits</div>
              <Tooltip
                side='right'
                sideOffset={25}
                container='dropdown-item-anchor'
                contentClassName='bg-white rounded-xl p-2 text-xs text-gray-700'
                content={
                  <>
                    <div>
                      <div className="font-semibold mr-1"> Cras Nullam Dapibus Egestas </div>
                      <div className="mt-1">  Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum </div>
                      <div className="mt-1"> Etiam porta sem malesuada magna mollis euismod </div>
                    </div>
                    <div className="mt-4">
                      <div className="font-semibold mr-1"> Cursus Ornare </div>
                      <div className="mt-1"> Maecenas sed diam eget risus varius blandit sit amet non magna </div>
                      <div className="mt-1"> Fusce dapibus, tellus ac cursus commodo </div>
                    </div>
                  </>
                }
              >
                <div>
                  <PhIcon name="info" size={16} className="brand-text" />
                </div>
              </Tooltip>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  )
}

export default ListOptions
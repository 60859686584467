import { TElement } from "@udecode/plate-common"
import { createPlatePlugin } from "@udecode/plate-common/react"

export const VideoPlugin = createPlatePlugin({
  key: 'video',
  node: {
    type: 'video',
    isElement: true,
    isVoid: true,
  },
})

export interface PlateVideoElement extends TElement {
  id: string
  url: string | null
  type: typeof VideoPlugin.key
  width: string
  align: string
}


export const createDefaultVideoElement = (): TElement => ({
  type: VideoPlugin.key,
  url: null,
  children: [{ text: '' }],
  width: '100%',
  align: 'center',
})
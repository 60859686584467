import React, { type FC } from 'react';
import { createNodesWithHOC } from '@udecode/plate-common/react';

import { draggableNodeKeys } from './with-draggables';
import FixedDraggables from '../../../plate-components/DnD/FixedDraggables';

const withFixedMenuDraggables = (
  Component: FC,
) => {
  return React.forwardRef<HTMLDivElement, any>(
    (props, ref) => {
      return (
        <FixedDraggables ref={ref} {...props}>
          <Component {...props} />
        </FixedDraggables>
      )
    }
  );
}

export const withFixedDraggablesPrimitive = createNodesWithHOC(withFixedMenuDraggables);

export const withFixedDraggables = (components: any) => {
  return withFixedDraggablesPrimitive(components, [
    {
      keys: draggableNodeKeys,
      level: null,
    },
  ]);
};
import React, { useMemo } from "react";
import { focusEditor, ParagraphPlugin, useEditorRef, useSelectionFragmentProp } from "@udecode/plate-common/react";
import { HEADING_KEYS } from "@udecode/plate-heading";
import { BulletedListPlugin, NumberedListPlugin } from "@udecode/plate-list/react";
import { BlockquotePlugin } from "@udecode/plate-block-quote/react";
import { select, TElement, toggleBlock } from "@udecode/plate-common";
import { toggleList, unwrapList } from "@udecode/plate-list";
import * as MenuBar from "@radix-ui/react-menubar";
import { INDENT_LIST_KEYS, ListStyleType } from '@udecode/plate-indent-list';
import { IndentListPlugin } from '@udecode/plate-indent-list/react';

import { PhIconName } from "../../../shared/Icon/PhIcon";
import BlockMenubarTrigger from '../../../shared/BlockMenubarControls/BlockMenubarTrigger';
import BlockMenubarContent from '../../../shared/BlockMenubarControls/BlockMenubarContent';
import BlockMenubarOptionList from '../../../shared/BlockMenubarControls/BlockMenubarOptionList';
import { SectionPlugin } from "../../../plate-config/Plugins/Section/Section.plugin";
import { ColumnGroupPlugin } from "../../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin";
import { ColumnPlugin } from "../../../plate-config/Plugins/ColumnGroup/Column.plugin";

interface TurnIntoItem {
  value: string;
  label: string;
  description: string;
  icon: PhIconName;
}

export const getBlockType = (block: TElement) => {
  if (block[IndentListPlugin.key]) {
    if (block[IndentListPlugin.key] === ListStyleType.Decimal) {
      return ListStyleType.Decimal;
    } else if (block[IndentListPlugin.key] === INDENT_LIST_KEYS.todo) {
      return INDENT_LIST_KEYS.todo;
    } else {
      return ListStyleType.Disc;
    }
  }

  return block.type;
};

export const STRUCTURAL_TYPES: string[] = [
  SectionPlugin.key,
  ColumnGroupPlugin.key,
  ColumnPlugin.key,

];

export const turnIntoItems: TurnIntoItem[] = [
  {
    value: ParagraphPlugin.key,
    label: 'Paragraph',
    description: 'Paragraph',
    icon: 'paragraph'
  },
  {
    value: HEADING_KEYS.h1,
    label: 'Heading 1',
    description: 'Heading 1',
    icon: 'textHOne'
  },
  {
    value: HEADING_KEYS.h2,
    label: 'Heading 2',
    description: 'Heading 2',
    icon: 'textHTwo'
  },
  {
    value: BulletedListPlugin.key,
    label: 'Bulleted list',
    description: 'Bulleted list',
    icon: 'listBullets'
  },
  {
    value: NumberedListPlugin.key,
    label: 'Numbered list',
    description: 'Numbered list',
    icon: 'listNumbers'
  },
  {
    value: BlockquotePlugin.key,
    label: 'Quote',
    description: 'Quote',
    icon: 'quotes'
  },
];

type Props = {
  value?: string;
}

const TurnIntoDefaultNodes: React.FC<Props> = ({ value }) => {
  const editor = useEditorRef();

  const itemsToDisplay = turnIntoItems.filter((item) => item.value !== BulletedListPlugin.key && item.value !== NumberedListPlugin.key);
  const defaultItem = turnIntoItems.find((item) => item.value === ParagraphPlugin.key)!;
  const optionList = itemsToDisplay.map((item) => ({ iconName: item.icon, key: item.value, value: item.label }));

  const selectedValue = useSelectionFragmentProp({
    defaultValue: ParagraphPlugin.key,
    getProp: (node) => getBlockType(node as any),
    structuralTypes: STRUCTURAL_TYPES,
  });
  const selectedItem = useMemo(
    () => turnIntoItems.find(
      (item) => item.value === (selectedValue ?? ParagraphPlugin.key)
    ) || defaultItem,
    [selectedValue]
  );

  const { icon: SelectedItemIcon, label: selectedItemLabel, value: selectedItemValue } = selectedItem;

  const onValueHandle = (type: string) => {
    if (type === BulletedListPlugin.key || type === NumberedListPlugin.key) {
      toggleList(editor, { type });
    } else {
      unwrapList(editor);
      toggleBlock(editor, { type });
    }
    select(editor, editor.selection!);
    focusEditor(editor);
  }

  return (
    <MenuBar.Menu value="float_turn_into">
      <BlockMenubarTrigger
        isDropdown
        iconBeforeLabel={SelectedItemIcon}
        label={selectedItemLabel}
        isOpened={value === 'float_turn_into'}
        tooltip="Turn into"
        containerId="floating-toolbar"
      />
      <MenuBar.Portal>
        <BlockMenubarContent>
          <BlockMenubarOptionList
            data={optionList}
            onOptionClick={onValueHandle}
            activeKey={selectedItemValue}
          />
        </BlockMenubarContent>
      </MenuBar.Portal>
    </MenuBar.Menu>
  );
};


export default TurnIntoDefaultNodes;
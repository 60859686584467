import React from "react";
import * as MenuBar from "@radix-ui/react-menubar";

import PhIcon, { PhIconName } from "../Icon/PhIcon";
import { colorClassToRGB } from "../../utils/color.util";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import Tooltip from "../ToolTip/Tooltip";

type Props = {
  isDropdown?: boolean;
  label?: string;
  labelIcon?: PhIconName;
  iconBeforeLabel?: PhIconName;
  isOpened?: boolean;
  onClick?: () => void;
  isDelete?: boolean;
  isColor?: boolean;
  color?: string | null;
  styleColor?: string | null;
  path?: string;
  openInNewTab?: boolean;
  isLink?: boolean;
  disabled?: boolean;
  tooltip?: string;
  containerId?: string;
}

const BlockMenubarTrigger: React.FC<Props> = ({
  isDropdown, label, labelIcon, iconBeforeLabel, isOpened, onClick, isDelete, isColor, color,
  path, openInNewTab, isLink, disabled, styleColor, tooltip, containerId
}) => {
  const { activePalette } = useAppSelector(state => state.page_colors)

  const preventPointerMove = (e: React.PointerEvent) => e.preventDefault()

  return (

    <MenuBar.Trigger onPointerEnter={preventPointerMove} id="menubar_tooltip_anchor" >
      <Tooltip content={tooltip} side={'top'} sideOffset={10} container={containerId ? containerId : "menubar_tooltip_anchor"} >
        <div
          className={`${isLink ? "" : "element_topbar_trigger"} ${isOpened ? "open" : ""} ${disabled ? "cursor-not-allowed" : ""}`}
          contentEditable={false}
          suppressContentEditableWarning
          onClick={onClick}
        >
          {
            isColor &&
            <>
              {
                color || styleColor
                  ?
                  <div
                    className="rounded-full border border-gray-300 flex items-center justify-center h-4 w-4"
                    style={{ backgroundColor: styleColor ? styleColor : colorClassToRGB(color as string, 100, activePalette.colors)! }}
                  />
                  :
                  <div
                    className="rounded-full border border-gray-300 flex items-center justify-center cursor-pointer h-4 w-4 chessboard-background"
                  >
                    <div className="w-[1px] h-full bg-[#F46262] transform rotate-45 rounded-full" />
                  </div>
              }
            </>

          }
          {
            isLink &&
            <a
              className="element_topbar_trigger"
              href={path}
              {...openInNewTab ? { target: "_blank", rel: "noopener noreferrer" } : { target: "_self" }}
            >
              <PhIcon name='arrowSquareOut' size={16} className="text-black" />
            </a>
          }
          {iconBeforeLabel && <PhIcon name={iconBeforeLabel} size={16} className="text-black" />}
          {label && <span className="text-sm font-normal brand-text capitalize"> {label} </span>}
          {labelIcon && <PhIcon name={labelIcon} size={16} className={`${isDelete ? "text-[#F46262]" : "text-black"}`} />}
          {isDropdown && <PhIcon name="caretDown" size={16} className="text-black" />}
        </div>
      </Tooltip>
    </MenuBar.Trigger>
  )
}

export default BlockMenubarTrigger;
import React from "react";

import ScrollableDialog from "../../../shared/ScrollableDialog/ScrollableDialog";
import ModalHeader from "./ModalHeader/ModalHeader";
import { PlateElementAiTextBlock } from "../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";
import ModalContent from "./ModalContent/ModalContent";

type Props = {
  modalOpen: boolean;
  onModalClose: () => void;
  element: PlateElementAiTextBlock
}

const ModalEditor: React.FC<Props> = ({ modalOpen, onModalClose, element }) => {
  return (
    <ScrollableDialog
      dialogHeader={<ModalHeader onModalClose={onModalClose} />}
      dialogOpen={modalOpen}
      onDialogClose={onModalClose}
      scrollableContent={<ModalContent element={element} onModalClose={onModalClose} />}
      scrollableContentId="scrollable-dialog-content-id"
      dialogStyles={{ margin: '20px', marginTop: '70px' }}
      sxClassess={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "960px",
            maxWidth: "100%",
            maxHeight: '100%',
            margin: '0',
          },
          "& .MuiDialog-paper": {
            borderRadius: "32px",
            boxShadow: "0px 8px 24px 0px #00000026",
            outline: "6px solid #FFFFFF80",
            background: '#F8F5F5'
          },
          "& .MuiDialogContent-root": {
            padding: "0",
            borderTop: "unset",
            borderBottom: "unset",
          },
        },
      }}
    />
  );
};

export default ModalEditor;
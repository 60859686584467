import React from 'react';

const FullScreenSpinner = () => {

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-300/40">
      <span className="full_screen_loader" />
    </div>
  );
}

export default FullScreenSpinner;

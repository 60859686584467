import React, { memo } from "react"

type Props = {
  label: string
  value: string | React.ReactNode
}

const ListItem: React.FC<Props> = memo(({ label, value }) => {
  return (
    <div className='dl-item mb-4 last:mb-0'>
      <div className="flex items-center text-sm brand-text font-semibold uppercase">
        {label}
      </div>
      <div className="text-gray-900 font-normal text-sm p-0 m-0 flex"> {value} </div>
    </div>
  )
})

export default ListItem

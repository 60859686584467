import { TElement } from "@udecode/plate-common";
import { createPlatePlugin } from "@udecode/plate-common/react";

export const TestimonialPlugin = createPlatePlugin({
  key: 'testimonial',
  node: {
    type: 'testimonial',
    isElement: true,
    isVoid: true,
  },
})

export interface PlateTestimonialElement extends TElement {
  id: string
  type: typeof TestimonialPlugin.key,
  content: string | null,
  user_name: string | null,
  user_position: string | null,
  image_external_id: string | null
  image_source: string | null
  image_url: string | null
}

export const createDefaultTestimonialElement = (): TElement => ({
  type: TestimonialPlugin.key,
  children: [{ text: '' }],
  content: null,
  user_name: null,
  user_position: null,
  image_external_id: null,
  image_source: null,
  image_url: null
})
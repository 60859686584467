import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Command } from 'cmdk';

import { useAppSelector } from "../../../store/hooks/redux-hooks";
import PhIcon from "../../../shared/Icon/PhIcon";
import VariableItem from "./VariableItem/VariableItem";
import VariableForm from "./VariableForm/VariableForm";
import Tooltip from "../../../shared/ToolTip/Tooltip";


const VariablesDropdown: React.FC = ({
}) => {
  const [open, setOpen] = useState(false);
  const template_variables = useAppSelector(state => state.page_template_variables);
  const [addNew, setAddNew] = useState(false);
  const [isInputActive, setInputActive] = useState<boolean>(false);

  const preventDefault = (e: React.MouseEvent) => e.preventDefault();
  const preventPointerMove = (e: React.PointerEvent) => e.preventDefault()

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild className="focus-visible:outline-none">
        <div>
          <Tooltip content={'Variables'} side={'bottom'} sideOffset={5} container="nav_tooltip_anchor" >
            <button className="brand_btn-secondary min-w-11 h-11" style={{ padding: 0 }} >
              <PhIcon name="code" size={18} className="text-gray-900" />
            </button>
          </Tooltip>
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          className="w-[320px] rounded-xl shadow-md z-[4] bg-white"
        >
          <DropdownMenu.Item
            onClick={preventDefault}
            onPointerEnter={preventPointerMove}
            onPointerMove={preventPointerMove}
            onPointerLeave={preventPointerMove}
            className="block_sidebar_item outline-none flex flex-col items-center rounded-xl justify-between gap-4 w-full bg-white"
          >
            <div className="flex w-full justify-between items-center border-b border-[#D9D9D9] p-4">
              <span className="brand-text text-base font-medium cursor-default">Template Variables</span>
              <div className="cursor-pointer" onClick={setOpen.bind(setOpen, !open)} >
                <PhIcon name="x" size={16} className="text-black" />
              </div>
            </div>
            {
              addNew
                ?
                <VariableForm closeForm={setAddNew.bind(setAddNew, false)} />
                :
                <div className="flex w-full px-4 pb-4">
                  <Command>
                    <div className="flex items-center gap-2 mb-2">
                      <div className={`decorated_input-wrapper ${isInputActive ? 'active' : ''}`}>
                        <PhIcon name="magnifyingGlass" size={16} className="text-gray-700 mr-2" />
                        <Command.Input
                          placeholder="Search..."
                          className={` text-sm  decorated_input`}
                          onFocus={setInputActive.bind(setInputActive, true)}
                          onBlur={setInputActive.bind(setInputActive, false)}
                        />
                      </div>
                      <button
                        className="brand_btn-secondary text-base min-w-11 min-h-11"
                        style={{ padding: 0 }}
                        onClick={setAddNew.bind(setAddNew, !addNew)}
                      >
                        <PhIcon name="plus" size={18} className="brand-text" />
                      </button>
                    </div>
                    <Command.List className="h-[368px] overflow-scroll">
                      <Command.Empty className="brand-text text-sm text-black mt-1" >No results found.</Command.Empty>
                      {template_variables.map((variable, i) =>
                        <Command.Item
                          key={i}
                          className={`
                            flex flex-col w-full bg-gray-100 border border-transparent rounded-lg p-3
                             hover:border-gray-300 cursor-pointer mb-1 group/var_item
                             `}
                          value={variable.value}
                          onSelect={() => null}
                        >
                          <VariableItem variable={variable} />
                        </Command.Item>
                      )}
                    </Command.List>
                  </Command>
                </div>
            }
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root >
  );
}

export default VariablesDropdown;
import React from "react";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import { textColorFromHex } from "../../utils/color.util";
import ColorSelectorButton from "../Button/ColorSelectorButton";
import ColorSelectorResetButton from "../Button/ColorSelectorResetButton";

type Props = {
  label: string;
  color?: string | null;
  onColorChange: (color: string) => void;
  onColorReset?: () => void;
  labelClassName?: string;
  wrapperClassName?: string;
}

const ToolbarColorSelector: React.FC<Props> = ({
  label, onColorChange, color, onColorReset, labelClassName, wrapperClassName
}) => {
  const { activePalette } = useAppSelector(state => state.page_colors)

  const colors = [...activePalette.colors, "#000000", "#ffffff"]

  const isColorActive = (backgroundColor: string, index: number, color: string): boolean => {
    switch (true) {
      case backgroundColor === color || `color-${index}` === color:
        return true;
      case backgroundColor === "#000000" && color === "color-black":
        return true;
      case backgroundColor === "#ffffff" && color === "color-white":
        return true;
      case backgroundColor === "transparent" && color === "color-transparent":
        return true;
      default:
        return false;
    }
  }

  const colorCheck = (color: string, position: number) => {
    let colorClass: string
    switch (color) {
      case "#000000":
        colorClass = "color-black"
        break;
      case "#ffffff":
        colorClass = "color-white"
        break;
      case "transparent":
        colorClass = "color-transparent"
        break;
      default:
        colorClass = `color-${position}`
        break;
    }
    return colorClass
  }
  const onColorChangeHandle = (color: string, position: number) => onColorChange(colorCheck(color, position))

  return (
    <div className="w-full">
      <span className={labelClassName ? labelClassName : "font-medium text-sm brand-text cursor-default"}>{label}</span>
      <section className={`flex gap-1 flex-wrap mt-2 ${wrapperClassName ? wrapperClassName : "max-w-[202px]"}`}>
        {onColorReset && <ColorSelectorResetButton onClick={onColorReset} />}
        {colors.map((backgroundColor, i) =>
          <ColorSelectorButton
            key={i}
            backgroundColor={backgroundColor}
            onClick={onColorChangeHandle.bind(onColorChangeHandle, backgroundColor, i)}
            iconName={isColorActive(backgroundColor, i, color!) ? "check" : undefined}
            iconClassName={`text-${textColorFromHex(backgroundColor)}`}
          />
        )}
      </section>
    </div>
  );
}

export default ToolbarColorSelector;
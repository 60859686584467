import React, { forwardRef } from "react";
import * as MenuBar from "@radix-ui/react-menubar";

type Props = {
  children: React.ReactNode;
  side?: "bottom" | "top" | "right" | "left";
  align?: "center" | "start" | "end";
  sideOffset?: number;
  itemClassName?: string;
  contentClassName?: string;
}

const BlockMenubarContent: React.FC<Props> = forwardRef<HTMLDivElement, Props>(({
  children, side = 'bottom', align = 'center', sideOffset = 6, itemClassName, contentClassName
}, ref) => {
  const preventPointerMove = (e: React.PointerEvent) => e.preventDefault()
  const preventDefault = (e: Event) => e.preventDefault()
  return (
    <MenuBar.Content
      ref={ref}
      className={`element_topbar_wrapper-content ${contentClassName}`}
      side={side} align={align} sideOffset={sideOffset}
    >
      <MenuBar.Item
        onSelect={preventDefault}
        onPointerEnter={preventPointerMove}
        onPointerMove={preventPointerMove}
        onPointerLeave={preventPointerMove}
        className={`element_topbar_item ${itemClassName}`}
      >
        {children}
      </MenuBar.Item>
    </MenuBar.Content>
  )
})

export default BlockMenubarContent;
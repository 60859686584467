import React, { useRef } from "react";
import { useEditorRef } from "@udecode/plate-common/react";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import PlateEditor from "../../plate-config/PlateEditor";
import PlateEditorReadOnly from "../../plate-config/PlateEditorReadOnly";
import PageNavigation from "./PageNavigation/PageNavigation";
import PageFooter from "./PageFooter/PageFooter";
import { useElementHeight } from "../../hooks/useElementHeight";

const stylesConfig = {
  laptop: {
    navWrapper: "px-[74px] w-full pt-[64px] min-w-[550px] mt-[20px]",
    pageWrapper: "px-[74px] pb-5 w-full min-w-[550px]",
  },
  tablet: {
    navWrapper: "px-[74px] w-full max-w-[982px] min-w-[550px] pt-[64px] mt-[20px]",
    pageWrapper: "px-[74px] pb-5 w-full max-w-[982px] min-w-[550px]",
  },
  mobile: {
    navWrapper: "px-[74px] w-full max-w-[550px] min-w-[550px] pt-[64px] mt-[20px]",
    pageWrapper: "px-[74px] pb-5 w-full max-w-[550px] min-w-[550px]",
  },
}

const EditorLayout: React.FC = () => {
  const { editorContainerWidth, readOnly, isLandingPage } = useAppSelector((state) => state.page_addendums);
  const { nav_position } = useAppSelector(state => state.page_navigation)
  const editor = useEditorRef();
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const pageWrapperHeight = useElementHeight(pageWrapperRef);

  const readOnlyMode = readOnly || isLandingPage;

  // Deselect the editor when clicking outside of the editor
  const onNavWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!readOnlyMode) editor.deselect();
  }
  const onPageWrapperOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget && !readOnlyMode) editor.deselect();
  }

  return (
    <section className="page_layout">
      <section
        className={`
         ${isLandingPage ? "w-full" : stylesConfig[editorContainerWidth].navWrapper}
         ${nav_position === 'fixed' ? "sticky top-0 z-[3]" : ""} 
        `}
        onClick={onNavWrapperClick}
      >
        <PageNavigation pageWrapperHeight={pageWrapperHeight} />
      </section>
      <section
        className={isLandingPage ? "w-full" : stylesConfig[editorContainerWidth].pageWrapper}
        onClick={onPageWrapperOutsideClick}
        ref={pageWrapperRef}
      >
        <section className="editor_container_layout" id="editor_container_layout" >
          {readOnlyMode ? <PlateEditorReadOnly /> : <PlateEditor />}
        </section>
        <PageFooter />
      </section>
    </section>
  );
}


export default EditorLayout;
import React from "react";

import PhIcon from "../../../../shared/Icon/PhIcon";

type Props = {
  onModalClose: () => void;
}

const ModalHeader: React.FC<Props> = ({ onModalClose }) => {
  return (
    <div className="flex items-center justify-between p-6">
      <div className="flex items-center gap-2">
        <div className="flex items-center"> <PhIcon name="stars" /> </div>
        <div className="brand-text text-xl font-semibold">  AI Text Settings </div>
      </div>
      <div className="cursor-pointer" onClick={onModalClose}> <PhIcon name="x" size={18} /> </div>
    </div>
  )
}

export default ModalHeader;

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option", "filter"]
  static values = { values: Array }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, 200)
  }

  filter() {
    this.optionTargets.forEach((node, index) => { node.classList.toggle('hidden', !this.visibilityList[index]) })
  }

  // Returns array of show hide flags for each option
  get visibilityList() {
    return (this.valuesValue || []).map(s => {
      return s.toLowerCase().includes(this.filterTarget.value.trim().toLowerCase())
    })
  }
}

import React, { useEffect, useRef, useState } from "react";

import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import {
  useUpdatePageHeaderFontMutation, useUpdatePageHeaderWeightMutation,
  useUpdatePageParagraphFontMutation, useUpdatePageParagraphWeightMutation
} from "../../../../store/reducers/api/fonts/fonts.api";
import WeightSelector from "./WeightSelector/WeightSelector";
import FontSelector from "./FontSelector/FontSelector";


const FontOption: React.FC = () => {

  const { header_font, header_weight, paragraph_font, paragraph_weight, paragraphAvailableWeights, headerAvailableWeights } = useAppSelector(state => state.page_fonts)

  const [updatePageHeaderFont] = useUpdatePageHeaderFontMutation()
  const [updatePageParagraphFont] = useUpdatePageParagraphFontMutation()
  const [updatePageParagraphWeight] = useUpdatePageParagraphWeightMutation()
  const [updatePageHeaderWeight] = useUpdatePageHeaderWeightMutation()
  const elementRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>();

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const updateWidth = () => {
    if (elementRef.current) {
      setWidth(elementRef.current.offsetWidth);
    }
  };

  const onHeaderFontChange = async (header_font: string) => await updatePageHeaderFont(header_font)
  const onParagraphFontChange = async (paragraph_font: string) => await updatePageParagraphFont(paragraph_font)
  const onParagraphWeightChange = async (paragraph_weight: number) => await updatePageParagraphWeight(paragraph_weight)
  const onHeaderWeightChange = async (header_weight: number) => await updatePageHeaderWeight(header_weight)

  return (
    <section>
      <div className="mt-4">
        <span className="brand-text text-sm font-normal text-left cursor-default">Header</span>
        <div className="flex w-full gap-2 mt-2" ref={elementRef}>
          <FontSelector
            activeFont={header_font}
            onFontChange={onHeaderFontChange}
            width={width}
          />
          <WeightSelector
            activeWeight={header_weight}
            availableWeights={headerAvailableWeights}
            onWeightChange={onHeaderWeightChange}
            width={width}
          />
        </div>
      </div>
      <div className="mt-4">
        <span className="brand-text text-sm font-normal text-left cursor-default">Paragraph</span>
        <div className="flex w-full gap-2 mt-2">
          <FontSelector
            activeFont={paragraph_font}
            onFontChange={onParagraphFontChange}
            width={width}
          />
          <WeightSelector
            activeWeight={paragraph_weight}
            availableWeights={paragraphAvailableWeights}
            onWeightChange={onParagraphWeightChange}
            width={width}
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="border border-[#E8E8E8] bg-gray-100 rounded-lg p-2">
          <h1 className="font-heading text-2xl font-bold cursor-default">
            Example Text
          </h1>
          <div className="paragraph-font-family text-sm font-normal cursor-default">
            This is how your text will look
          </div>
        </div>
      </div>
    </section>
  );
}

export default FontOption;
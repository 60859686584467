import React, { useEffect, useRef } from 'react';


type Props = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  textClasses: string;
  placeholder?: string;
  disabled?: boolean;
  paramToUpdate?: any;
}


const AutoHeightTextarea: React.FC<Props> = ({ value, onChange, textClasses, placeholder, disabled, paramToUpdate }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px";
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value, paramToUpdate]);

  return (
    <textarea
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`
              resize-none p-0 border-none focus:border-none focus:outline-none focus:shadow-none 
              focus:drop-shadow-none focus:ring-0 w-full  ${textClasses} bg-transparent
              `}
      maxLength={300}
      ref={textAreaRef}
      disabled={disabled}
    />
  )
}

export default AutoHeightTextarea;
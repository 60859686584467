import React from "react";

import PhIcon, { PhIconName } from "../Icon/PhIcon";


type Props = {
  backgroundColor?: string
  onClick?: () => void
  iconName?: PhIconName
  iconClassName?: string
  btnClassName?: string
  wrapperClassName?: string
}

const ColorSelectorButton: React.FC<Props> = ({
  backgroundColor, onClick, iconClassName, iconName, btnClassName, wrapperClassName
}) => {

  return (
    <div className={`p-[2.5px] h-8 w-8 ${wrapperClassName}`}>
      <button
        className={`
          rounded-full border border-gray-900/20 flex items-center 
          justify-center w-[27.5px] h-[27.5px] ${btnClassName}
          ${onClick ? "cursor-pointer" : "cursor-default"}
          `}
        style={{ backgroundColor: backgroundColor }}
        onClick={onClick}
      >
        {iconName && <PhIcon name={iconName} size={16} className={iconClassName} />}
      </button>
    </div>
  );
}

export default ColorSelectorButton;
import React from "react";
import { focusEditor, useEditorRef } from "@udecode/plate-common/react";
import { getMarks, select, toggleMark } from "@udecode/plate-common";

import { PhIconName } from "../../../shared/Icon/PhIcon";
import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";

type Props = {
  mark: string;
  iconName: PhIconName;
  containerId?: string;
  tooltip?: string;
}

const BasicMarks: React.FC<Props> = ({ mark, iconName, containerId, tooltip }) => {
  const editor = useEditorRef()
  const marks = getMarks(editor)

  const onMarkChange = () => {
    toggleMark(editor, { key: mark });
    select(editor, editor.selection!);
    focusEditor(editor);
  }

  return <BlockMenubarTrigger
    labelIcon={iconName}
    onClick={onMarkChange}
    isOpened={!!marks?.[mark]}
    containerId={containerId}
    tooltip={tooltip}
  />
}

export default BasicMarks;
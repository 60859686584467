import React, { useEffect, useState } from "react";
import { useEditorRef } from "@udecode/plate-common/react";
import * as MenuBar from "@radix-ui/react-menubar";

import { Popover, PopoverContent, PopoverTrigger } from "../../../../shared/Popover/Popover";
import ImagePicker from "../../../../shared/ImagePicker/ImagePicker";
import { PlateColumnElement } from "../../../../plate-config/Plugins/ColumnGroup/Column.plugin";
import PhIcon from "../../../../shared/Icon/PhIcon";
import BlockMenubarTrigger from "../../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import BlockMenubarOptionList from "../../../../shared/BlockMenubarControls/BlockMenubarOptionList";
import BlockMenubarContent from "../../../../shared/BlockMenubarControls/BlockMenubarContent";
import BlockMenubarColors from "../../../../shared/BlockMenubarControls/BlockMenubarColors";
import BlockMenubarSlider from "../../../../shared/BlockMenubarControls/BlockMenubarSlider";
import Tooltip from "../../../../shared/ToolTip/Tooltip";

type Props = {
  colIndex: number;
  element: PlateColumnElement
  onDeleteColumn: () => void;
  onAlignChange: (value: string) => void;
  onBgColorChange: (value: string) => void;
  onBgOpacityChange: (value: number[]) => void;
  setImageFromPicker: (image_url: string, image_source: string, image_external_id: string) => void;
  onImageUnset: () => void;
  onBgColorReset: () => void;
}

const ColumnToolbar: React.FC<Props> = ({
  colIndex, element, onDeleteColumn, onAlignChange, onBgColorChange,
  onBgOpacityChange, setImageFromPicker, onImageUnset, onBgColorReset
}) => {
  const [value, setValue] = useState<string>("");
  const editor = useEditorRef()
  const [open, setOpen] = useState(false)
  const [showImagePicker, setShowImagePicker] = useState(false)

  useEffect(() => {
    editor.setNodes({ menuOpen: open } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
  }, [open])

  const preventDefault = (e: Event) => e.preventDefault();

  const align = element.align ?? 'top'
  const alignOptions: any = [
    { key: "top", iconName: "alignTop" },
    { key: "center", iconName: "alignCenterHorizontal" },
    { key: "bottom", iconName: "alignBottom" }
  ]

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}  >
        <PopoverTrigger className='absolute top-[-8px] left-1/2 translate-x-[-50%] z-[2]'>
          <Tooltip content={'Settings'} side={'top'} sideOffset={10} container={element.id} >
            <div
              contentEditable={false}
              suppressContentEditableWarning={true}
              className='bg-white rounded p-1 w-6 h-4 flex items-center justify-center shadow-md border border-gray-50 cursor-pointer'
              onClick={setOpen.bind(setOpen, !open)}
            >
              <PhIcon name="dotsThreeOutline" size={24} color={'brand-text'} />
            </div>
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent
          onOpenAutoFocus={preventDefault}
          onCloseAutoFocus={preventDefault}
          side='top'
          sideOffset={6}
          align='center'
          contentEditable={false}
          suppressContentEditableWarning={true}
        >
          <MenuBar.Root
            value={value}
            onValueChange={setValue}
            className="element_topbar_relative_wrapper"
          >
            <MenuBar.Menu value="col_align">
              <BlockMenubarTrigger
                isDropdown
                iconBeforeLabel={alignOptions.find(item => item.key === align)?.iconName}
                isOpened={value === 'col_align'}
                tooltip="Align"
                containerId={element.id}
              />
              <MenuBar.Portal>
                <BlockMenubarContent>
                  <BlockMenubarOptionList
                    data={alignOptions}
                    onOptionClick={onAlignChange}
                    activeKey={align}
                  />
                </BlockMenubarContent>
              </MenuBar.Portal>
            </MenuBar.Menu>
            <MenuBar.Separator className="element_topbar_separator" />
            <MenuBar.Menu value="col_image">
              <BlockMenubarTrigger
                labelIcon="image" onClick={setShowImagePicker.bind(setShowImagePicker, true)}
                tooltip="Background Image" containerId={element.id}
              />
            </MenuBar.Menu>
            <MenuBar.Menu value="col_colors">
              <BlockMenubarTrigger
                isDropdown isColor color={element.bg_color} isOpened={value === 'col_colors'}
                tooltip="Colors" containerId={element.id}
              />
              <MenuBar.Portal>
                <BlockMenubarContent>
                  <BlockMenubarColors
                    color={element.bg_color}
                    onColorChange={onBgColorChange}
                    onColorReset={onBgColorChange.bind(onBgColorChange, null)}
                    label="Background Color"
                  />
                  <BlockMenubarSlider
                    label="Opacity"
                    value={[element.bg_opacity as number]}
                    onValueChange={onBgOpacityChange}
                    min={0}
                    max={100}
                  />
                </BlockMenubarContent>
              </MenuBar.Portal>
            </MenuBar.Menu>
            <MenuBar.Separator className="element_topbar_separator" />
            <MenuBar.Menu value="col_delete">
              <BlockMenubarTrigger
                labelIcon="trash"
                onClick={(colIndex === 0 || colIndex === 1) ? undefined : onDeleteColumn}
                isDelete
                disabled={colIndex === 0 || colIndex === 1}
                tooltip="Delete"
                containerId={element.id}
              />
            </MenuBar.Menu>
          </MenuBar.Root>
        </PopoverContent>
      </Popover>
      <ImagePicker
        currentImageUrl={element.image_url as string}
        backgroundImageSource={element.image_source as string}
        modalOpen={showImagePicker}
        onImageChange={setImageFromPicker}
        onImageUnset={onImageUnset}
        onModalClose={setShowImagePicker.bind(setShowImagePicker, false)}
        brandImages={false}
      />
    </>
  )
}

export default ColumnToolbar;
import React, { useState } from "react";

import LabeledInput from "../../../../../../shared/Inputs/LabeledInput";
import { PricingTableLineItem } from "../../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import SwitchInput from "../../../../../../shared/Inputs/SwitchInput";
import { StoreCalcVariable } from "../../../../../../store/reducers/page_calc_variables/page_calc_variables";
import { useUpdateCalcVariableMutation } from "../../../../../../store/reducers/api/calc_variables/calc_variables.api";
import { useAppDispatch } from "../../../../../../store/hooks/redux-hooks";
import { onShadowCalcVariableUpdate } from "../../../../../../store/reducers/page-shadow-store/page_shadow_store";

type Props = {
  rowItem: PricingTableLineItem,
  calc_variables: StoreCalcVariable[]
  setItemViewHandle: (view: string) => void,
  onRowAdjustableQuantityHandle: (adjustable_quantity: boolean) => void
  onRowAdjustableQuantityMinHandle: (min: number | null) => void
  onRowAdjustableQuantityMaxHandle: (max: number | null) => void
  isReadOnly: boolean
}

const QuantityMenu = ({
  rowItem, setItemViewHandle, calc_variables, onRowAdjustableQuantityHandle, onRowAdjustableQuantityMaxHandle,
  onRowAdjustableQuantityMinHandle, isReadOnly
}: Props) => {
  const [updateVariable] = useUpdateCalcVariableMutation()
  const dispatch = useAppDispatch()

  const activeVariable = calc_variables.find(variable => variable.id === rowItem.variable_id)
  const [activeVarMin, setActiveVarMin] = useState<number>(activeVariable?.minimum || 1)
  const [activeVarMax, setActiveVarMax] = useState<number>(activeVariable?.maximum || 100)

  const onLocalQuantityMinHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    return onRowAdjustableQuantityMinHandle((isNaN(value) || value === 0) ? null : value)
  }


  const onLocalQuantityMaxHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value)
    return onRowAdjustableQuantityMaxHandle((isNaN(value) || value === 0) ? null : value)
  }

  const onChangeMinValidator = (min: number | null, max: number | null) => {
    if (!max || !min) {
      return null
    }
    if (min > max) {
      return `Must be less than ${max}`
    }
  }

  const onChangeMaxValidator = (min: number | null, max: number | null) => {
    if (!min) {
      return null
    }
    if (max! < min) {
      return `Must be greater than ${min}`
    }
  }

  const onActiveVarMinHandle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveVarMin(parseInt(e.target.value))
    if (isReadOnly) {
      dispatch(onShadowCalcVariableUpdate({ ...activeVariable!, minimum: parseInt(e.target.value) }))
      return
    }
    await updateVariable({ ...activeVariable!, minimum: parseInt(e.target.value), default: activeVariable!.default.toString() })
  }
  const onActiveVarMaxHandle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveVarMax(parseInt(e.target.value))
    if (isReadOnly) {
      dispatch(onShadowCalcVariableUpdate({ ...activeVariable!, maximum: parseInt(e.target.value) }))
      return
    }
    await updateVariable({ ...activeVariable!, maximum: parseInt(e.target.value), default: activeVariable!.default.toString() })
  }


  return (
    <>
      {activeVariable &&
        <div className="flex justify-center w-full items-center bg-blue-100 py-1 text-gray-900 border-b border-gray-100">
          <div className="text-gray-600 text-xs">
            <span>{"{{"}</span>{activeVariable.handle}<span className="mr-1">{"}}"}</span>
          </div>
          {activeVariable.label}
        </div>
      }
      <div className="flex flex-col p-4 gap-4">
        <SwitchInput
          label="Adjustable Quantity"
          value={rowItem.adjustable_quantity}
          setValue={onRowAdjustableQuantityHandle}
        />
        {rowItem.adjustable_quantity &&
          <div className="flex gap-2">
            {activeVariable ? (
              <>
                <LabeledInput
                  htmlFor="line-item-minimum-var"
                  label="Minimum"
                  value={activeVarMin}
                  type="number"
                  onChange={onActiveVarMinHandle}
                  error={onChangeMinValidator(activeVarMin, activeVarMax)}
                />
                <LabeledInput
                  htmlFor="line-item-maximum-var"
                  label="Maximum"
                  value={activeVarMax}
                  type="number"
                  onChange={onActiveVarMaxHandle}
                  error={onChangeMaxValidator(activeVarMin, activeVarMax)}
                />
              </>
            ) : (
              <>
                <LabeledInput
                  htmlFor="line-item-minimum"
                  label="Minimum"
                  value={rowItem.min_quantity}
                  type="number"
                  onChange={onLocalQuantityMinHandle}
                  error={onChangeMinValidator(rowItem.min_quantity, rowItem.max_quantity)}
                />
                <LabeledInput

                  htmlFor="line-item-maximum"
                  label="Maximum"
                  value={rowItem.max_quantity}
                  type="number"
                  onChange={onLocalQuantityMaxHandle}
                  error={onChangeMaxValidator(rowItem.min_quantity, rowItem.max_quantity)}
                />
              </>
            )}
          </div>
        }
        <div className="relative group/quantity-menu">
          <div className={`hidden group-hover/quantity-menu:visible tooltip absolute -top-10 left-1/2 transform -translate-x-1/2 whitespace-nowrap transition-all duration-300`}>
            Use a different variable for the quantity
          </div>
          <button className="btn btn-block btn-white" onClick={setItemViewHandle.bind(setItemViewHandle, 'variables')}>
            {activeVariable ? "Change Variable" : "Assign Variable"}
          </button>
        </div>
      </div>
    </>
  )
}

export default QuantityMenu;

import React from "react";

import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import { useUpdateCustomColorsMutation, useUpdatePagePaletteMutation } from "../../../../store/reducers/api/colors/colors.api";
import ColorSourceSelector from "./ColorSourceSelector/ColorSourceSelector";
import DefaultColors from "./ColorSelector/DefaultColors";
import CustomColors from "./ColorSelector/CustomColors/CustomColors";
import { prepareColorAttributesForOverride } from "../../../../store/reducers/page_colors/page_color.config";


const ColorOption: React.FC = () => {
  const { activePalette, availablePalettes } = useAppSelector(state => state.page_colors)
  const [updatePagePalette] = useUpdatePagePaletteMutation()
  const [updateCustomColors] = useUpdateCustomColorsMutation();

  const pallets = availablePalettes.filter(data => {
    if (data.palette === 'account' && !data.uniqueAccountColors) return false
    return data;
  })
  const colors = [...activePalette.colors, "#000000", "#ffffff"]
  const customPalette = availablePalettes.find(palette => palette.palette === 'custom');
  const customColors = customPalette?.colors || [];

  const onColorSourceChange = async (palette: string) => await updatePagePalette(palette)
  const onOverrideHandle = async () => await updateCustomColors(prepareColorAttributesForOverride(activePalette, availablePalettes))
    .then(() => onColorSourceChange('custom'))

  return (
    <>
      <ColorSourceSelector
        activePalette={activePalette}
        pallets={pallets}
        onColorSourceChange={onColorSourceChange}
      />
      {
        activePalette.palette === 'custom'
          ? <CustomColors activePalette={activePalette} />
          : <DefaultColors
            colors={colors}
            overridePallet={onOverrideHandle}
            customColors={customColors}
            activePalette={activePalette}
          />
      }
    </>
  );
}

export default ColorOption;
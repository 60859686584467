import React from "react";

import TooltipCursor from "../../ToolTip/TooltipCursor";

type Props = {
  image: any;
  setImage: (image: any) => void;
}

const ImageCard: React.FC<Props> = ({ image, setImage }) => {
  return (
    <TooltipCursor content="Select Image" delay={200}>
      <div
        className="relative h-[162px] bg-cover bg-center rounded-2xl transition ease-in-out duration-500 cursor-pointer"
        onClick={setImage.bind(setImage, image)}
        style={{ backgroundImage: `url(${image?.urls?.small || image?.url})` }}
      >
        <div
          className="rounded-xl absolute inset-0 w-full h-full opacity-0 cursor-pointer
                 transition-all ease-in-out duration-500 hover:bg-black/40 hover:opacity-100"
        />
      </div>
    </TooltipCursor>
  )
}

export default ImageCard
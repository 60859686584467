import React, {
  ComponentPropsWithRef,
  useCallback,
  useEffect,
  useState
} from 'react'
import { EmblaCarouselType } from 'embla-carousel'
import PhIcon from '../../../shared/Icon/PhIcon'

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean
  nextBtnDisabled: boolean
  onPrevButtonClick: () => void
  onNextButtonClick: () => void
}

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
}

type PropType = ComponentPropsWithRef<'button'> & {
  isMobile: boolean
}

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, isMobile, ...restProps } = props

  return (
    <div
      contentEditable={false}
      suppressContentEditableWarning
      className={`flex flex-col items-center justify-center ${isMobile ? "" : "mr-8"}`}>
      <button
        className={`
          flex items-center justify-center border border-[#9B9B9B] bg-white rounded-full w-8 h-8 shadow-md
          ${props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
          `}
        type="button"
        {...restProps}
      >
        <PhIcon name="caretLeft" size={16} className='brand-text' />
        {children}
      </button>
    </div>
  )
}

export const NextButton: React.FC<PropType> = (props) => {
  const { children, isMobile, ...restProps } = props

  return (
    <div
      contentEditable={false}
      suppressContentEditableWarning
      className={`flex flex-col items-center justify-center ${isMobile ? "" : "ml-8"}`}>
      <button
        className={`
          flex items-center justify-center border border-[#9B9B9B] bg-white rounded-full w-8 h-8 shadow-md
          ${props.disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
          `}
        type="button"
        {...restProps}
      >
        <PhIcon name="caretRight" size={16} className='brand-text' />
        {children}
      </button>
    </div>
  )
}

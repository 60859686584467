import React from 'react';
import { useEditorRef } from '@udecode/plate-common/react';
import { findNode } from '@udecode/plate-common';
import useEmblaCarousel from 'embla-carousel-react'

import { PlateTestimonialGroupElement } from '../../../plate-config/Plugins/Testimonial/TestimonialGroup.plugin';
import { useAppSelector } from '../../../store/hooks/redux-hooks';
import { colorClassToRGB } from '../../../utils/color.util';
import { NextButton, PrevButton, usePrevNextButtons } from './SliderArrows';
import { DotButton, useDotButton } from './SliderDots';


type Props = {
  children: React.ReactNode;
  block: PlateTestimonialGroupElement
}

const SliderWrapper: React.FC<Props> = ({ children, block }) => {
  const editor = useEditorRef();
  const activePalette = useAppSelector(state => state.page_colors.activePalette)
  const { isLandingPage, readOnly } = useAppSelector(state => state.page_addendums)
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi)
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi)
  const isMobile = useAppSelector(state => state.page_addendums.editorContainerWidth === 'mobile')

  const bg_color = block.card_bg_color as string || 'color-white'
  const bg_opacity = typeof block.card_bg_opacity === 'number' ? block.card_bg_opacity : 100
  const readOnlyMode = readOnly || isLandingPage
  const nodePath = findNode(editor, { at: [], match: { id: block.id } })![1];

  const onSelect = () => readOnlyMode && editor.select([...nodePath, 0])
  const onNext = () => {
    onNextButtonClick()
    readOnlyMode && editor.select([...nodePath, 0])
  }
  const onPrev = () => {
    onPrevButtonClick()
    readOnlyMode && editor.select([...nodePath, 0])
  }
  const onDotClick = (i: number) => {
    onDotButtonClick(i)
    readOnlyMode && editor.select([...nodePath, 0])
  }


  return (
    <section
      className="flex rounded-lg p-4 shadow-md w-full"
      style={{ backgroundColor: colorClassToRGB(bg_color, bg_opacity, activePalette.colors)! }}
      onClick={onSelect}
    >
      <PrevButton onClick={onPrev} disabled={prevBtnDisabled} isMobile={isMobile} />
      <div className="overflow-hidden w-full" ref={emblaRef}>
        <div className="flex"> {children} </div>
        <div
          contentEditable={false}
          suppressContentEditableWarning
          className='flex min-h-8 items-end justify-center gap-4'
        >
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={onDotClick.bind(onDotClick, index)}
              className={` w-2 h-2 rounded-full  ${index === selectedIndex ? "bg-black" : "bg-black/10"}`}
            />
          ))}
        </div>
      </div>
      <NextButton onClick={onNext} disabled={nextBtnDisabled} isMobile={isMobile} />
    </section>
  );
}

export default SliderWrapper;



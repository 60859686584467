import React, { useEffect, useState } from "react";

import { PopoverPortal, Popover, PopoverTrigger, PopoverContent, PopoverArrow } from "../../../../shared/Popover/Popover";
import EditIcon from "../../EditIcon/EditIcon";
import LabeledInput from "../../../../shared/Inputs/LabeledInput";
import { PlateAssessmentElement } from "../../../../plate-config/Plugins/Assessment/Assessment.plugin";
import { StoreCalcVariable } from "../../../../store/reducers/page_calc_variables/page_calc_variables";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { onShadowCalcVariableUpdate } from "../../../../store/reducers/page-shadow-store/page_shadow_store";
import ToolbarColorSelector from "../../../../shared/ToolbarControls/ToolbarColorSelector";

type Props = {
  element: PlateAssessmentElement
  onRoiLockedButtonColorChange: (roi_locked_button_color: string) => void
  onRoiLockedButtonTextColorChange: (roi_locked_button_text_color: string) => void
  onRoiLockedButtonTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  calc_variables: StoreCalcVariable[]
  newVarValue: number | null
  lockScreenToggle: () => void,
  trackAssessmentUnlocked: (answer: number) => void,
  isEditorReadOnly: boolean
}


const LockScreenBtn = ({
  element, onRoiLockedButtonColorChange, onRoiLockedButtonTextChange, trackAssessmentUnlocked,
  onRoiLockedButtonTextColorChange, calc_variables, newVarValue, lockScreenToggle, isEditorReadOnly
}: Props) => {
  const [error, setError] = useState<string | null>(null)
  const dispatch = useAppDispatch()

  const { roi_locked_button_color, roi_locked_button_text, roi_locked_button_text_color, roi_locked_variable_id } = element.assessment_detail_attributes;
  const activeVar = calc_variables.find(el => el.id === roi_locked_variable_id)

  useEffect(() => {
    if (error && isEditorReadOnly && activeVar && newVarValue !== null) {
      setError(null)
    }
  }, [error, isEditorReadOnly, activeVar, newVarValue])

  const onButtonClick = async () => {
    if (!isEditorReadOnly) {
      setError(null)
      return
    }
    if (!activeVar) {
      setError("Please select a variable")
      return
    }
    if (newVarValue === null) {
      setError("Please enter a value")
      return
    }
    dispatch(onShadowCalcVariableUpdate({ ...activeVar!, default: newVarValue }))
    lockScreenToggle()
    trackAssessmentUnlocked(newVarValue)
  }

  return (
    <>
      <div className="relative">
        <div
          contentEditable={false}
          suppressContentEditableWarning={true}
          className={`
            btn relative btn-white btn-large hover:scale-110 paragraph-font-family
            ${roi_locked_button_text_color} background-${roi_locked_button_color}
            border-${roi_locked_button_color}
            `}
          onClick={onButtonClick}
        >
          {roi_locked_button_text || "See my ROI"}
        </div>
        {error && <div className="absolute bottom-0 tooltip danger left-1/2 transform translate-y-8 -translate-x-1/2 w-full text-center text-xs">{error}</div>}
        {!isEditorReadOnly && <div className="absolute top-0 right-0 translate-x-14 z-10">
          <Popover>
            <PopoverTrigger>
              <EditIcon textColor={roi_locked_button_text_color as string} />
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent sideOffset={5}>
                <PopoverArrow />
                <div className="w-72 border-solid border border-gray-100 rounded-xl bg-white drop-shadow z-30">
                  <div className="p-5 flex flex-col">
                    <div className="uppercase font-semibold text-sm text-left brand-subtext">
                      Button Text
                    </div>
                    <LabeledInput
                      htmlFor="roi_locked_button_text"
                      type="text"
                      value={roi_locked_button_text}
                      onChange={onRoiLockedButtonTextChange}
                    />
                    <div className="mt-4">
                      <ToolbarColorSelector
                        label="Background Color"
                        color={roi_locked_button_color as string}
                        onColorChange={onRoiLockedButtonColorChange}
                        labelClassName="uppercase font-semibold text-sm text-left text-gray-700"
                        wrapperClassName="max-w-64"
                      />
                    </div>
                    <div className="mt-4">
                      <ToolbarColorSelector
                        label="Text Color"
                        color={roi_locked_button_text_color as string}
                        onColorChange={onRoiLockedButtonTextColorChange}
                        labelClassName="uppercase font-semibold text-sm text-left text-gray-700"
                        wrapperClassName="max-w-64"
                      />
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </PopoverPortal>
          </Popover>
        </div>}
      </div>
    </>
  )
}

export default LockScreenBtn
import React, { useEffect, useState } from 'react'

import { useUpdateCalcVariableMutation } from "../../../../../store/reducers/api/calc_variables/calc_variables.api"
import { StoreCalcVariable } from "../../../../../store/reducers/page_calc_variables/page_calc_variables"
import LabeledInput from "../../../../../shared/Inputs/LabeledInput"
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/redux-hooks'
import { onShadowCalcVariableUpdate } from '../../../../../store/reducers/page-shadow-store/page_shadow_store'

type Props = {
  variables: StoreCalcVariable[],
  valueNotes: string,
  trackAssessmentExplore: () => void,
  trackAssessmentChanges: (
    field: string, variable_id: string | null, value: string | number,
    previous_value: string | number, sliderValue: number | null, variables: StoreCalcVariable[] | null
  ) => void,
  isEditorReadOnly: boolean
}

const ValueExplorer = ({ variables, valueNotes, trackAssessmentExplore, trackAssessmentChanges, isEditorReadOnly }: Props) => {
  const dispatch = useAppDispatch()
  const shadowStoreVariables = useAppSelector(state => state.page_shadow_store.calculation_variables)

  useEffect(() => {
    trackAssessmentExplore()
  }, [])

  const [updateVariable] = useUpdateCalcVariableMutation()

  const onVariableUpdate = async (variable: StoreCalcVariable, value: number) => {
    const variableToUpdate = {
      ...variable,
      'default': value
    }
    if (isEditorReadOnly) {
      dispatch(onShadowCalcVariableUpdate({ ...variable, default: value }))
      const variablesToUpdate = shadowStoreVariables.map(variable => {
        if (variable.id === variableToUpdate.id) {
          return {
            ...variable,
            'default': value
          }
        }
        return variable
      })
      trackAssessmentChanges(variableToUpdate.label || 'variable', variableToUpdate.id, value, variable.default, null, variablesToUpdate)
      return
    }
    await updateVariable({ ...variableToUpdate, default: variableToUpdate.default.toString() })

  }

  return (
    <div
      className="w-64 border-solid border border-gray-100 rounded-xl bg-white drop-shadow z-30"
    >
      <div className="p-4 flex flex-col gap-4">
        {variables.length > 0 && (
          <div className="flex flex-col gap-4">
            <div className="text-gray-600 uppercase text-xs paragraph-font-family">Based On</div>
            {
              variables.map((variable) => (
                <VariableExplorer
                  key={variable.id}
                  variable={variable}
                  onVariableUpdate={onVariableUpdate}
                />
              ))
            }
            {valueNotes && (
              <div className="text-xs text-gray-600 paragraph-font-family">{valueNotes}</div>
            )}
          </div>
        )}
        {variables.length === 0 && (
          <div className="text-gray-600 text-xs">Is visible when there are variables in the formula.</div>
        )}
      </div>
    </div>
  )
}

export default ValueExplorer

type VariableExplorerProps = {
  variable: StoreCalcVariable,
  onVariableUpdate: (variable: StoreCalcVariable, value: number) => void
}

const VariableExplorer: React.FC<VariableExplorerProps> = ({
  variable,
  onVariableUpdate
}) => {
  const [value, setValue] = useState(variable.default)

  const onVariableUpdateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value))
    onVariableUpdate(variable, Number(e.target.value))
  }

  return (
    <div className="paragraph-font-family" key={variable.id}>
      <LabeledInput
        htmlFor={`variable-default-${variable.id}`}
        label={variable.label ? variable.label : variable.handle}
        type="number"
        value={value}
        onChange={onVariableUpdateHandler}
        inputPrefix={variable.prefix}
        inputSuffix={variable.suffix}
      />
      {variable.hint &&
        <div className="flex justify-between pt-1 text-gray-600 text-xs">{variable.hint}</div>
      }
    </div>
  )
}
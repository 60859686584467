import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { PageData } from './api.types'
import { setPageNav } from '../page_navigation/page_navigation'
import { setAddendums } from '../page_addendums/page_addendums'
import { setPageColors } from '../page_colors/page_colors'
import { setPageFonts } from '../page_fonts/page_fonts'
import { setSectionsFromAPI } from '../page_sections/page_sections'
import { setResources } from '../page_resources/page_resources'
import { getPageDataAttributes } from './data-attributes.util'
import { setPageImages } from '../page_images/page_images'

export const revvedupApi = createApi({
  reducerPath: 'revvedupApi',
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getPageData: builder.query<PageData, void>({
      query: () => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${requestData?.page_id}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            console.log('Page data loaded:', data);
            // Set sections disabled due to schema conflicts
            dispatch(setSectionsFromAPI(data))
            // Set navigation
            dispatch(setPageNav(data))
            // Set colors
            dispatch(setPageFonts(data))
            // Set colors
            dispatch(setPageColors(data))
            // Set all the rest
            dispatch(setAddendums({ pageData: data, requestAttributes: getPageDataAttributes() }))
            // Set AI resources
            dispatch(setResources(data))
            // Set images
            dispatch(setPageImages(data))
          }
        } catch (err) {
          console.log('Page data loading error:', err);
        }
      }
    }),
  }),
})


export const { useGetPageDataQuery } = revvedupApi

import { TElement, TNodeEntry } from "@udecode/plate-common";
import { createPlatePlugin, PlateEditor } from "@udecode/plate-common/react";

import { CardPlugin, createDefaultCardElement, PlateCardElement } from "./Card.plugin";

const extendEditor = ({ editor }) => {
  const { normalizeNode } = editor as PlateEditor;
  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    if (node.type === CardGroupPlugin.node.type) {
      // Rule: group should be deleted if it has no children
      const block = node as PlateCardGroupElement;
      if (block.children.length === 0) {
        editor.removeNodes({ at: path });
        return;
      }
      // Children control - only columns allowed
      block.children.map((child) => {
        if (child.type !== CardPlugin.node.type) {
          editor.removeNodes({ at: [], match: (n: any) => n.id === child.id });
        }
      });
    }
    normalizeNode([node, path]);
  };
  return editor
}

export const CardGroupPlugin = createPlatePlugin({
  key: 'card_group',
  node: {
    type: 'card_group',
    isElement: true,
  },
  extendEditor: extendEditor,
})


export interface PlateCardGroupElement extends TElement {
  id: string
  type: typeof CardGroupPlugin.key
  children: PlateCardElement[]
  template: string
  align: string | null
  card_bg_color: string | null
  card_bg_opacity: number
  text_color: string | null
}

export const createDefaultCardGroupElement = (): TElement => ({
  type: CardGroupPlugin.key,
  children: [
    createDefaultCardElement(), createDefaultCardElement(), createDefaultCardElement(),
    createDefaultCardElement(), createDefaultCardElement(), createDefaultCardElement(),
  ],
  template: 'none',
  align: 'left',
  card_bg_color: 'color-white',
  card_bg_opacity: 100,
  text_color: 'black',
})

import React, { useState } from "react";
import TableCell from '@mui/material/TableCell';
import { isEditorReadOnly, useEditorRef } from "@udecode/plate-common/react";

import { Popover, PopoverTrigger, PopoverContent, PopoverPortal } from "../../../../../shared/Popover/Popover"
import OptionsMenu from "./OptionsMenu/OptionsMenu"
import ReccuringMenu from "./ReccurringMenu/ReccuringMenu"
import MenuHeader from "./MenuHeader/MenuHeader"
import QuantityMenu from "./QuantityMenu/QuantityMenu"
import DiscountMenu from "./DiscountMenu/DiscountMenu"
import VariablesMenu from "./VariablesMenu/VariablesMenu"
import ValueMenu from "./ValueMenu/ValueMenu"
import ToolbarOptions from "./ToolbarOptions/ToolbarOptions"
import { PlatePricingElement, PricingTableLineItem, PlatePricingLocalElement } from "../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { StoreCalcVariable } from "../../../../../store/reducers/page_calc_variables/page_calc_variables";


type Props = {
  setIsRowOptionsOpen: (value: boolean) => void
  isRowOptionsOpen: boolean
  element: PlatePricingElement
  localElement: PlatePricingLocalElement
  setLocalElement: React.Dispatch<React.SetStateAction<PlatePricingLocalElement | undefined>>
  tableIndex: number
  rowIndex: number
  calc_variables: StoreCalcVariable[]
};

export const RowOptions: React.FC<Props> = ({
  setIsRowOptionsOpen, isRowOptionsOpen, element, tableIndex,
  rowIndex, setLocalElement, localElement, calc_variables
}) => {
  const editor = useEditorRef()
  const isReadOnly = isEditorReadOnly(editor)
  const [itemView, setItemView] = useState<null | string>(null)

  const activeTable = element.pricing_tables_attributes[tableIndex]
  const rowItem = activeTable.line_items_attributes[rowIndex]

  const onPopoverCloseHandle = (open: boolean) => {
    setIsRowOptionsOpen(open)
    !open && setItemView(null)
  }

  const setItemViewHandle = (view: string) => setItemView(view)

  const goBack = () => setItemView(itemView === "variables" ? "quantity" : null)

  const activeRowVariable = calc_variables.find(variable => variable.id === rowItem.variable_id)!
  const valueIssue = rowItem.variable_id
    ? !rowItem.value_formula_attributes.calculation.includes(`{{${activeRowVariable.handle}}}`)
    : !rowItem.value_formula_attributes.calculation.includes("{{QTY}}")

  const onInteractOutsideHandle = (e: any) => {
    const target = e.target as HTMLElement
    if (target?.id === 'row_options_popover_anchor') e.preventDefault()
  }

  const onMoveRowUpHandle = () => {
    const tableRows = [...activeTable.line_items_attributes] as PricingTableLineItem[]
    [tableRows[rowIndex], tableRows[rowIndex - 1]] = [tableRows[rowIndex - 1], tableRows[rowIndex]]
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, line_items_attributes: tableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
    setIsRowOptionsOpen(false)
  }

  const onMoveRowDownHandle = () => {
    const tableRows = [...activeTable.line_items_attributes] as PricingTableLineItem[]
    [tableRows[rowIndex], tableRows[rowIndex + 1]] = [tableRows[rowIndex + 1], tableRows[rowIndex]]
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, line_items_attributes: tableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
    setIsRowOptionsOpen(false)
  }

  const onRowDeleteHandle = () => {
    const tableRows = [...activeTable.line_items_attributes] as PricingTableLineItem[]
    tableRows.splice(rowIndex, 1)
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, line_items_attributes: tableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
    setIsRowOptionsOpen(false)
  }

  const onRowDuplicateHandle = () => {
    const tableRows = [...activeTable.line_items_attributes] as PricingTableLineItem[]
    tableRows.splice(rowIndex + 1, 0, { ...tableRows[rowIndex] })
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, line_items_attributes: tableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
    setIsRowOptionsOpen(false)
  }

  const onRowOptionalHandle = (optional: boolean) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, optional, optional_default: optional ? row.optional_default : false }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    const local_table_attributes = localElement.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, selected: optional ? row.selected : false }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    setLocalElement({ ...localElement, pricing_tables_attributes: local_table_attributes })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowOptionalDefaultHandle = (optional_default: boolean) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          const tableSelectType = table.select_type
          if (tableSelectType === "single") {
            if (index === rowIndex) {
              return { ...row, optional_default }
            }
            return { ...row, optional_default: optional_default ? false : row.optional_default }
          }
          if (index === rowIndex) {
            return { ...row, optional_default }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    const local_table_attributes = localElement.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, selected: optional_default }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    setLocalElement({ ...localElement, pricing_tables_attributes: local_table_attributes })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowKindHandle = (kind: string) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, kind }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes: pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowAdjustableQuantityHandle = (adjustable_quantity: boolean) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, adjustable_quantity }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowAdjustableQuantityMinHandle = (min_quantity: number | null) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, min_quantity }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowAdjustableQuantityMaxHandle = (max_quantity: number | null) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, max_quantity }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowVariableIdHandle = (variable_id: string | null) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, variable_id }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
    setItemView('quantity')
  }

  const onRowBillingFrequencyHandle = (billing_frequency: string) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, billing_frequency }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowTermsHandle = (terms: number | null) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, terms }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowDiscountValueHandle = (discount_value: number) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, discount_value }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowDiscountTypeHandle = (discount_type: string) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, discount_type }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const onRowValueFormulaAttributesHandle = (value_formula_attributes: { calculation: string, notes: string | null }) => {
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        const updatedTableRows = table.line_items_attributes.map((row, index) => {
          if (index === rowIndex) {
            return { ...row, value_formula_attributes }
          }
          return row
        })
        return { ...table, line_items_attributes: updatedTableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  return (
    <TableCell
      align="right"
      sx={{
        position: 'absolute', border: 'none',
        bottom: '0',
        left: '50%',
        transform: 'translateX(300%) translateY(10%)',
        zIndex: 1000,
        '&:hover': {
          borderColor: 'transparent',
        },
      }}
      colSpan={6}>
      <Popover open={isRowOptionsOpen} onOpenChange={onPopoverCloseHandle}>
        <PopoverTrigger asChild>
          <div />
        </PopoverTrigger>
        <PopoverPortal container={document.getElementById(activeTable.id)}>
          <PopoverContent
            side="right"
            align="start"
            onInteractOutside={onInteractOutsideHandle}
            id="row-toolbar-tooltip-anchor"
            className={`${itemView === "value" ? "w-80" : "w-64"} border-solid border border-gray-100 rounded-xl bg-white drop-shadow z-30`}
          >
            {!itemView
              ?
              <ToolbarOptions
                setItemViewHandle={setItemViewHandle}
                onMoveRowUpHandle={onMoveRowUpHandle}
                onRowDeleteHandle={onRowDeleteHandle}
                onMoveRowDownHandle={onMoveRowDownHandle}
                onRowDuplicateHandle={onRowDuplicateHandle}
                rowIndex={rowIndex}
                activeTable={activeTable}
                valueIssue={valueIssue}
              />
              :
              <>
                <MenuHeader itemView={itemView} goBack={goBack} />
                {itemView === "options" &&
                  <OptionsMenu
                    rowItem={rowItem}
                    onRowOptionalHandle={onRowOptionalHandle}
                    onRowOptionalDefaultHandle={onRowOptionalDefaultHandle}
                    onRowKindHandle={onRowKindHandle}
                  />}
                {itemView === "quantity" &&
                  <QuantityMenu
                    rowItem={rowItem}
                    calc_variables={calc_variables}
                    isReadOnly={isReadOnly}
                    setItemViewHandle={setItemViewHandle}
                    onRowAdjustableQuantityHandle={onRowAdjustableQuantityHandle}
                    onRowAdjustableQuantityMinHandle={onRowAdjustableQuantityMinHandle}
                    onRowAdjustableQuantityMaxHandle={onRowAdjustableQuantityMaxHandle}
                  />}
                {itemView === "variables" &&
                  <VariablesMenu
                    rowItem={rowItem}
                    calc_variables={calc_variables}
                    setItemViewHandle={setItemViewHandle}
                    onRowVariableIdHandle={onRowVariableIdHandle}
                  />}
                {itemView === "recurring" &&
                  <ReccuringMenu
                    rowItem={rowItem}
                    onRowBillingFrequencyHandle={onRowBillingFrequencyHandle}
                    onRowTermsHandle={onRowTermsHandle}
                  />}
                {itemView === "discount" &&
                  <DiscountMenu
                    rowItem={rowItem}
                    onRowDiscountValueHandle={onRowDiscountValueHandle}
                    onRowDiscountTypeHandle={onRowDiscountTypeHandle}
                  />}
                {itemView === "value" &&
                  <ValueMenu
                    rowItem={rowItem}
                    onRowValueFormulaAttributesHandle={onRowValueFormulaAttributesHandle}
                    calc_variables={calc_variables}
                  />}
              </>
            }
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </TableCell>

  );
}

export default RowOptions;

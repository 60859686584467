import React from 'react'
import { useForm, SubmitHandler } from "react-hook-form"

import { useAddTemplateVariableMutation } from '../../../../store/reducers/api/template_variables/template_variables.api';


interface IFormInput {
  varName: string
}

type Props = {
  closeForm: () => void
}

const VariableForm: React.FC<Props> = ({ closeForm }) => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm<IFormInput>({
    defaultValues: {
      varName: "",
    }
  });
  const [addTemplateVariable, { isLoading }] = useAddTemplateVariableMutation();

  const createTemplateVarHandle = async (variableName: string) => await addTemplateVariable(variableName).unwrap().then(() => closeForm())

  const onSubmit: SubmitHandler<IFormInput> = async (data) => await createTemplateVarHandle(data.varName).catch((error) => {
    setError('varName', {
      type: 'manual',
      message: error?.data?.errors[0] || "Oops! Something went wrong. Please try again."
    })
  });

  const varNameValidation = (value: string, formValues: IFormInput) => {
    if (!value || value.trim().length < 1) return "Name can't be blank";
    return true
  }

  return (
    <div className='flex w-full px-4 pb-4'>
      <form onSubmit={handleSubmit(onSubmit)} className="border bg-gray-100 rounded-lg w-full border-gray-300 p-3">
        <div className="flex flex-col gap-4">
          <div className='flex flex-col gap-2'>
            <div className='text-gray-900 font-medium text-sm'>New Variable</div>
            <input
              {...register("varName", { required: 'Name is required', validate: varNameValidation })}
              type="text"
              placeholder='Enter variable name'
              className="brand_input text-sm"
              autoComplete='off'
            />
            {errors.varName && <p className="mt-1 text-[#F46262] text-sm brand-font-family">{errors.varName.message}</p>}
          </div>
          <div className="flex gap-2">
            <button
              type='button'
              onClick={closeForm}
              className="brand_btn-secondary w-1/2">
              Cancel
            </button>
            <button
              type='submit'
              disabled={isLoading}
              onClick={handleSubmit(onSubmit)}
              className="brand_btn-primary w-1/2">
              Add Variable
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default VariableForm

import React from "react";

import { PricingTable } from "../../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import PhIcon from "../../../../../../shared/Icon/PhIcon";


type Props = {
  setItemViewHandle: (view: string) => void
  onMoveRowUpHandle: () => void
  onRowDeleteHandle: () => void
  onMoveRowDownHandle: () => void
  onRowDuplicateHandle: () => void
  rowIndex: number
  activeTable: PricingTable
  valueIssue: boolean
}

const ToolbarOptions = ({
  setItemViewHandle, rowIndex, onMoveRowUpHandle, onRowDeleteHandle,
  onMoveRowDownHandle, onRowDuplicateHandle, activeTable, valueIssue
}: Props) => {

  return (
    <>
      <div className="flex gap-1 border-b border-gray-100 p-1">
        <button
          className="btn btn-small btn-clear w-10 flex justify-center text-gray-900"
          onClick={onMoveRowUpHandle}
          disabled={rowIndex === 0}
        >
          <PhIcon name="caretUp" size={16} />
        </button>
        <button
          className="btn btn-small btn-clear w-10 flex justify-center text-gray-900"
          onClick={onMoveRowDownHandle}
          disabled={!(activeTable.line_items_attributes.length - 1 > rowIndex)}
        >
          <PhIcon name="caretDown" size={16} />
        </button>
        <button
          className="btn btn-small btn-clear w-10 flex justify-center text-gray-900"
          onClick={onRowDuplicateHandle}
        >
          <PhIcon name="copy" size={16} />
        </button>
        <button
          className="btn btn-small btn-clear w-10 flex justify-center text-gray-900"
          onClick={onRowDeleteHandle}
        >
          <PhIcon name="trash" size={16} />
        </button>
      </div>
      <div className="flex flex-col">
        <button
          onClick={setItemViewHandle.bind(setItemViewHandle, "options")}
          className="flex justify-between items-center py-2 px-4 hover:bg-gray-100 text-sm font-semibold text-gray-700 cursor-pointer"
        >
          Item Options <PhIcon name={'caretRight'} size={13} />
        </button>
        <button
          onClick={setItemViewHandle.bind(setItemViewHandle, "quantity")}
          className="flex justify-between items-center py-2 px-4 hover:bg-gray-100 text-sm font-semibold text-gray-700 cursor-pointer"
        >
          Quantity Options <PhIcon name={'caretRight'} size={13} />
        </button>
        <button
          onClick={setItemViewHandle.bind(setItemViewHandle, "recurring")}
          className="flex justify-between items-center py-2 px-4 hover:bg-gray-100 text-sm font-semibold text-gray-700 cursor-pointer"
        >
          Recurring Billing <PhIcon name={'caretRight'} size={13} />
        </button>
        <button
          onClick={setItemViewHandle.bind(setItemViewHandle, "discount")}
          className="flex justify-between items-center py-2 px-4 hover:bg-gray-100 text-sm font-semibold text-gray-700 cursor-pointer"
        >
          Discount <PhIcon name={'caretRight'} size={13} />
        </button>
        <button
          onClick={setItemViewHandle.bind(setItemViewHandle, "Value")}
          className={`
            flex justify-between items-center py-2 px-4 hover:bg-gray-100 
            text-sm font-semibold text-gray-700 cursor-pointer
            ${valueIssue ? "bg-red-50 text-red-600 hover:bg-red-100" : ""}
            `}
        >
          Value <PhIcon name={'caretRight'} size={13} />
        </button>
      </div>
    </>
  )
}

export default ToolbarOptions;
import React from "react";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import PhIcon from "../Icon/PhIcon";
import Tooltip from "../ToolTip/Tooltip";


type Props = {
  items: { id: string, title: string }[],
  onOrderUpdate: (oldIndex: number, newIndex: number) => void,
  onItemAdd: () => void,
  onItemDelete: (id: string) => void,
}

const BlockMenubarDragList: React.FC<Props> = ({ items, onOrderUpdate, onItemDelete, onItemAdd }) => {

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id && over.id && active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        arrayMove(items, oldIndex, newIndex);
        onOrderUpdate(oldIndex, newIndex);
      }
    }
  };

  return (
    <section
      className="flex w-full min-w-[220px] flex-col gap-1"
      contentEditable={false}
      suppressContentEditableWarning
    >
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={items}
          strategy={verticalListSortingStrategy}
        >
          <div className="flex flex-col w-full">
            {items.map((el) => <BlockMenubarDragListItem item={el} key={el.id} onItemDelete={onItemDelete} />)}
          </div>
        </SortableContext>
      </DndContext>
      <div className="w-full h-[1px] bg-gray-100" />
      <button onClick={onItemAdd} className="brand_btn-borderless w-full" >
        <PhIcon name="plus" size={16} className='brand-text mr-2' />
        <span className="text-sm brand-text text-nowrap text-left cursor-pointer">Add New Item</span>
      </button>
    </section>
  )
}

export default BlockMenubarDragList;

interface BlockMenubarDragListItemProps {
  item: { id: string, title: string },
  onItemDelete: (id: string) => void,
}

export const BlockMenubarDragListItem: React.FC<BlockMenubarDragListItemProps> = ({ item, onItemDelete }) => {

  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({ id: item.id });

  const titleShortcut = item.title.length > 20 ? `${item.title.slice(0, 20)}...` : item.title;

  return (
    <div
      ref={setNodeRef}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      {...attributes}
      className="flex w-full select-none items-center group/list justify-between gap-[6px] p-2 hover:bg-gray-100 rounded-lg"
      id="draglist_tooltip_anchor"
    >
      <div className="flex gap-[6px] items-center">
        <div
          {...listeners}
          ref={setActivatorNodeRef}
          className="cursor-grab w-6 h-6 rounded-[4px] flex justify-center items-center outline-none"
        >
          <PhIcon name="dotsSixVertical" size={16} className='brand-text' weight='bold' />
        </div>
        <span className="text-sm brand-text text-nowrap text-left">{titleShortcut}</span>
      </div>
      <Tooltip
        content={'Delete'}
        side={'top'}
        sideOffset={10}
        container="draglist_tooltip_anchor"
        triggerClassName={'opacity-0 group-hover/list:opacity-100'}
      >
        <div className="cursor-pointer" onClick={onItemDelete.bind(onItemDelete, item.id)}>
          <PhIcon name="trash" size={16} className='text-[#F46262]' />
        </div>
      </Tooltip>
    </div>
  );
};


import React from "react";
import * as Menubar from "@radix-ui/react-menubar";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import PhIcon from "../../../shared/Icon/PhIcon";
import MenuItem from "./MenuItem/MenuItem";
import { NavigationLink } from "../../../store/reducers/api/api.types";


type Props = {
  showTrigger: boolean;
  value: string;
  setValue: (value: string) => void;
  onLinkDelete: (id: string) => void;
  onOrderUpdate: (items: any) => void;
  onLinkAdd: () => void;
  itemsToDisplay: NavigationLink[]
  menuSide?: 'top' | 'bottom';
  isLoading?: boolean;
}


const BuilderNavMenuToolbar: React.FC<Props> = ({
  showTrigger, value, setValue, itemsToDisplay, onLinkAdd, onLinkDelete, onOrderUpdate, menuSide = "top", isLoading
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const showMenu = value ? true : showTrigger
  const items = itemsToDisplay.map((item, index) => ({ ...item, id: item.id || index.toString() }));

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id && over.id && active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        const updatedItems = arrayMove(items, oldIndex, newIndex);
        onOrderUpdate(updatedItems);
      }
    }
  };
  const preventDefault = (e: Event) => e.preventDefault();

  return (
    <Menubar.Root
      value={value}
      onValueChange={setValue}
      className={`${showMenu ? "absolute top-[0px] left-[-32px] pr-3" : "hidden"}`}
    >
      <Menubar.Menu value="footer_menu">
        <Menubar.Trigger
          className="w-6 h-6 rounded-[4px] bg-white shadow-md flex justify-center items-center outline-none"
        >
          <PhIcon name="dotsSixVertical" size={16} className="brand-text" weight="bold" />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content
            className="bg-white shadow-md rounded-lg p-3 flex flex-col gap-1 min-w-[200px] max-w-[200px]"
            side={menuSide}
            sideOffset={5}
            align="start"
            style={{ zIndex: 4 }}
          >
            <Menubar.Item className="menubar_item flex flex-col gap-1" onSelect={preventDefault}>
              {!!items.length &&
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                  <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                    disabled={isLoading}
                  >
                    <div className={`flex flex-col gap-1`}>
                      {items.map((el) => <MenuItem key={el.id} item={el} onLinkDelete={onLinkDelete} isLoading={isLoading} />)}
                    </div>
                  </SortableContext>
                </DndContext>
              }
              {items.length <= 4 &&
                <>
                  {items.length > 1 && <div className="flex w-full h-[1px] bg-gray-100 my-1" />}
                  <button
                    className="brand_btn-borderless w-full"
                    onClick={onLinkAdd}
                    disabled={isLoading}
                  >
                    <PhIcon name="plus" size={14} className="brand-text mr-1" /> Add New
                  </button>
                </>
              }
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>
  );
}


export default BuilderNavMenuToolbar;
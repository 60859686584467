import React, { useState } from "react";
import * as Menubar from "@radix-ui/react-menubar";

import ToolbarColorSelector from "../../../../shared/ToolbarControls/ToolbarColorSelector";
import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import {
  useUpdateFooterBgColorAttributeMutation, useUpdateFooterDisplayAttributeMutation,
  useUpdateFooterDisplayLogoAttributeMutation, useUpdateFooterDisplayMenuAttributeMutation,
  useUpdateFooterTextColorAttributeMutation, useUpdateNavSocialsVisibilityAttributeMutation
} from "../../../../store/reducers/api/navbar/navbar.api";
import BlockMenubarHeader from "../../../../shared/BlockMenubarControls/BlockMenubarHeader";
import BlockMenubarToggle from "../../../../shared/BlockMenubarControls/BlockMenubarToggle";
import BlockSidebarTrigger from "../../../../shared/BlockMenubarControls/BlockSidebarTrigger";

type Props = {
  showTrigger: boolean;
}

const FooterSettings: React.FC<Props> = ({ showTrigger }) => {
  const [value, setValue] = useState<string>("");
  const navPageConfig = useAppSelector(state => state.page_navigation)
  const [updateFooterDisplayAttribute] = useUpdateFooterDisplayAttributeMutation()
  const [updateFooterDisplayLogoAttribute] = useUpdateFooterDisplayLogoAttributeMutation()
  const [updateFooterDisplayMenuAttribute] = useUpdateFooterDisplayMenuAttributeMutation()
  const [updateFooterBgColorAttribute] = useUpdateFooterBgColorAttributeMutation()
  const [updateFooterTextColorAttribute] = useUpdateFooterTextColorAttributeMutation()
  const [updateNavSocialsVisibilityAttribute] = useUpdateNavSocialsVisibilityAttributeMutation()

  const showMenu = value ? true : showTrigger

  const onFooterVisibilityChange = async (value: boolean) => await updateFooterDisplayAttribute(value)
  const onFooterLogoVisibleChange = async (value: boolean) => await updateFooterDisplayLogoAttribute(value)
  const onFooterMenuVisibilityChange = async (value: boolean) => await updateFooterDisplayMenuAttribute(value)
  const onFooterBgColorChange = async (value: string | null) => await updateFooterBgColorAttribute(value)
  const onFooterTextColorChange = async (value: string | null) => await updateFooterTextColorAttribute(value)
  const onFooterSocialsVisibilityChange = async (value: boolean) => await updateNavSocialsVisibilityAttribute(value)
  const preventDefault = (e: Event) => e.preventDefault()

  return (
    <Menubar.Root
      value={value}
      onValueChange={setValue}
      className={`${showMenu ? "top-[0px] left-[-48px] menubar_wrapper" : "hidden"}`}
      id="footer_settings_menu"
    >
      <Menubar.Menu value="footer_settings_menu">
        <Menubar.Trigger>
          <BlockSidebarTrigger icon="settings" tooltip="Settings" container="footer_settings_menu" />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content sticky="always" hideWhenDetached className="block_sidebar_wrapper-content" side="right" align="end" alignOffset={-7} sideOffset={8} >
            <Menubar.Item className="menubar_item" onSelect={preventDefault}>
              <BlockMenubarHeader label="Footer Settings" onClose={setValue.bind(setValue, '')} />
            </Menubar.Item>
            <Menubar.Item className="menubar_item" onSelect={preventDefault}>
              <BlockMenubarToggle
                value={navPageConfig.footer_enabled}
                onChange={onFooterVisibilityChange}
                label="Show Footer"
              />
            </Menubar.Item>
            {navPageConfig.footer_enabled &&
              <>
                <Menubar.Separator className="menubar_separator" />
                <Menubar.Item className="menubar_item" onSelect={preventDefault}>
                  <BlockMenubarToggle
                    value={navPageConfig.footer_display_logo}
                    onChange={onFooterLogoVisibleChange}
                    label="Show Logo"
                  />
                </Menubar.Item>
                <Menubar.Item className="menubar_item" onSelect={preventDefault}>
                  <BlockMenubarToggle
                    value={navPageConfig.footer_display_menu}
                    onChange={onFooterMenuVisibilityChange}
                    label="Show Menu"
                  />
                </Menubar.Item>
                <Menubar.Item className="menubar_item" onSelect={preventDefault}>
                  <BlockMenubarToggle
                    value={navPageConfig.social_links_bar}
                    onChange={onFooterSocialsVisibilityChange}
                    label="Show Social Icons"
                  />
                </Menubar.Item>
                <Menubar.Separator className="menubar_separator" />
                <Menubar.Item className="menubar_item" onSelect={preventDefault}>
                  <ToolbarColorSelector
                    label="Background Color"
                    color={navPageConfig.footer_background_color}
                    onColorChange={onFooterBgColorChange}
                    onColorReset={onFooterBgColorChange.bind(onFooterBgColorChange, null)}
                    wrapperClassName="w-full"
                  />
                </Menubar.Item>
                <Menubar.Item className="menubar_item" onSelect={preventDefault}>
                  <ToolbarColorSelector
                    label="Text Color"
                    color={navPageConfig.footer_text_color}
                    onColorChange={onFooterTextColorChange}
                    onColorReset={onFooterTextColorChange.bind(onFooterTextColorChange, null)}
                    wrapperClassName="w-full"
                  />
                </Menubar.Item>
              </>
            }
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>
  );
}


export default FooterSettings;
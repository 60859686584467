import React from "react";

import { PricingTableLineItem } from "../../../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { StoreCalcVariable } from "../../../../../../store/reducers/page_calc_variables/page_calc_variables";

type Props = {
  rowItem: PricingTableLineItem,
  setItemViewHandle: (view: string) => void,
  onRowVariableIdHandle: (variable_id: string | null) => void
  calc_variables: StoreCalcVariable[]
}

const VariablesMenu = ({ rowItem, calc_variables, onRowVariableIdHandle }: Props) => {
  return (
    <div className="flex flex-col p-4 gap-4">
      {calc_variables.map(variable =>
        <button
          key={`${variable.id}-button-variable`}
          className={`btn btn-white btn-block flex justify-start items-center`}
          onClick={onRowVariableIdHandle.bind(onRowVariableIdHandle, variable.id)}
          disabled={rowItem.variable_id === variable.id}
        >
          <div className="text-gray-600 text-xs">
            <span>{"{{"}</span>{variable.handle}<span className="mr-1">{"}}"}</span>
          </div>
          <div> {variable.label} </div>
        </button>
      )}
      {rowItem.variable_id &&
        <button className="btn btn-white btn-block danger" onClick={onRowVariableIdHandle.bind(onRowVariableIdHandle, null)} >
          Remove Variable
        </button>
      }
    </div>
  )
}

export default VariablesMenu;

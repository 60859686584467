import React, { useState } from "react";
import { findNode, getNodeParent } from "@udecode/plate-common";
import { isEditorReadOnly, useEditorRef } from "@udecode/plate-common/react";
import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";

import { PlateCardElement } from "../../../plate-config/Plugins/CardGroup/Card.plugin";
import { PlateCardGroupElement } from "../../../plate-config/Plugins/CardGroup/CardGroup.plugin";
import PhIcon from "../../../shared/Icon/PhIcon";
import { Popover, PopoverContent, PopoverPortal, PopoverAnchor } from "../../../shared/Popover/Popover";

type Props = {
  block: PlateCardElement
}

const EmojiVisualType: React.FC<Props> = ({ block }) => {
  const editor = useEditorRef()
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const isReadOnly = isEditorReadOnly(editor)

  const elementNode = findNode(editor, { at: [], match: (n: any) => n.id === block.id })!;
  const parentNode = getNodeParent(editor, elementNode[1]) as PlateCardGroupElement;
  const parentAlign = parentNode.align || 'left'
  const image_align = parentAlign === 'center' ? 'justify-center' : parentAlign === 'right' ? 'justify-end' : 'justify-start'

  const onEmojiClick = (emojiObject: EmojiClickData) => {
    editor.setNodes(
      { emoji: emojiObject.emoji } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
    setShowEmojiPicker(false)
  }
  const preventDefault = (e: Event) => e.preventDefault();

  return (
    <Popover open={isReadOnly ? false : showEmojiPicker} onOpenChange={setShowEmojiPicker}  >
      <PopoverAnchor>
        <div className={`${image_align} mb-5 w-full flex relative`}>
          <div
            className={`h-[64px] flex items-center gap-4 flex-col 
              ${block.emoji ? "bg-transparent" : "w-[64px] bg-gray-100"}
              rounded-full cursor-pointer justify-center`}
            onClick={isReadOnly ? undefined : () => setShowEmojiPicker(true)}
          >
            {
              isReadOnly
                ?
                block.emoji
                  ? <div style={{ fontSize: '64px', lineHeight: '64px' }} className="cursor-pointer" >{block.emoji}</div>
                  : null
                :
                block.emoji
                  ? <div style={{ fontSize: '64px', lineHeight: '64px' }} className="cursor-pointer" >{block.emoji}</div>
                  : <PhIcon name='smiley' size={30} className="brand-subtext" />
            }
          </div>
        </div>
      </PopoverAnchor>
      <PopoverPortal>
        <PopoverContent onOpenAutoFocus={preventDefault} onCloseAutoFocus={preventDefault} side='bottom' align='center'>
          <EmojiPicker
            autoFocusSearch={true}
            emojiStyle={EmojiStyle.NATIVE}
            lazyLoadEmojis={true}
            skinTonesDisabled={true}
            onEmojiClick={onEmojiClick}
            height={'420px'}
            width={'350px'}
          />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  )
}

export default EmojiVisualType;
import { TElement, TNodeEntry } from "@udecode/plate-common";
import { createPlatePlugin, PlateEditor } from "@udecode/plate-common/react";

import { createDefaultTestimonialElement, PlateTestimonialElement, TestimonialPlugin } from "./Testimonial.plugin";

const extendEditor = ({ editor }) => {
  const { normalizeNode } = editor as PlateEditor;
  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    if (node.type === TestimonialGroupPlugin.node.type) {
      // Rule: group should be deleted if it has no children
      const block = node as PlateTestimonialGroupElement;
      if (block.children.length === 0) {
        editor.removeNodes({ at: path });
        return;
      }
      // Children control - only columns allowed
      block.children.map((child) => {
        if (child.type !== TestimonialPlugin.node.type) {
          editor.removeNodes({ at: [], match: (n: any) => n.id === child.id });
        }
      });
    }
    normalizeNode([node, path]);
  };
  return editor
}

export const TestimonialGroupPlugin = createPlatePlugin({
  key: 'testimonial_group',
  node: {
    type: 'testimonial_group',
    isElement: true,
  },
  extendEditor: extendEditor,
})


export interface PlateTestimonialGroupElement extends TElement {
  id: string
  type: typeof TestimonialGroupPlugin.key
  children: PlateTestimonialElement[]
  template: string
  card_bg_color: string | null
  card_bg_opacity: number
  text_color: string | null
}

export const createDefaultTestimonialGroupElement = (): TElement => ({
  type: TestimonialGroupPlugin.key,
  children: [createDefaultTestimonialElement()],
  template: 'slider',
  card_bg_color: 'color-white',
  card_bg_opacity: 100,
  text_color: 'black',
})

import React, { useState } from "react";
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate-common/react";

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import BlockMenubarContent from "../../../shared/BlockMenubarControls/BlockMenubarContent";
import { PlateButtonElement } from "../../../plate-config/Plugins/Button/Button.plugin";
import BlockMenubarColors from "../../../shared/BlockMenubarControls/BlockMenubarColors";
import BlockMenubarLink from "../../../shared/BlockMenubarControls/BlockMenubarLink";


type Props = {
  block: PlateButtonElement
  goToLink: any
}

const ButtonMenubar: React.FC<Props> = ({ block, goToLink }) => {

  const editor = useEditorRef();
  const [value, setValue] = useState<string>("");

  const onBtnDelete = () => editor.removeNodes({ at: [], match: (n: any) => n.id === block.id })
  const onButtonColorChange = (primary_color: string | null) =>
    editor.setNodes({ primary_color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  const onTextColorChange = (secondary_color: string | null) =>
    editor.setNodes({ secondary_color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  const onExternalUrlChange = (url: string) =>
    editor.setNodes(
      { url, link_type: 'external', internal_page_id: null, internal_resource_id: null, internal_template_resource_slot_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id })
  const onInternalPageIdChange = (internal_page_id: string) =>
    editor.setNodes(
      { url: null, link_type: 'internal', internal_page_id, internal_resource_id: null, internal_template_resource_slot_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onInternalTemplateResourceSlotIdChange = (internal_template_resource_slot_id: string) =>
    editor.setNodes(
      { url: null, link_type: 'resource', internal_template_resource_slot_id, internal_resource_id: null, internal_page_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onInternalResourceIdChange = (internal_resource_id: string) =>
    editor.setNodes(
      { url: null, link_type: 'resource', internal_resource_id, internal_template_resource_slot_id: null, internal_page_id: null, } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )
  const onDynamicUrlChange = (url: string) =>
    editor.setNodes(
      { url, link_type: 'dynamic', internal_resource_id: null, internal_template_resource_slot_id: null, internal_page_id: null } as Partial<Node>,
      { at: [], match: (n: any) => n.id === block.id }
    )

  return (
    <MenuBar.Root
      value={value}
      onValueChange={setValue}
      className="element_topbar_relative_wrapper"
    >
      <MenuBar.Menu value="btn_colors">
        <BlockMenubarTrigger
          isDropdown isColor color={block.primary_color} isOpened={value === 'btn_colors'}
          tooltip="Colors"
          containerId={block.id}
        />
        <MenuBar.Portal>
          <BlockMenubarContent>
            <BlockMenubarColors
              color={block.primary_color}
              onColorChange={onButtonColorChange}
              onColorReset={onButtonColorChange.bind(onButtonColorChange, null)}
              label="Button Color"
            />
            <BlockMenubarColors
              color={block.secondary_color}
              onColorChange={onTextColorChange}
              onColorReset={onTextColorChange.bind(onTextColorChange, null)}
              label="Text Color"
            />
          </BlockMenubarContent>
        </MenuBar.Portal>
      </MenuBar.Menu>
      <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="btn_link">
        <BlockMenubarTrigger isDropdown labelIcon="link" isOpened={value === 'btn_link'} tooltip="Link" containerId={block.id} />
        <MenuBar.Portal>
          <BlockMenubarContent>
            <BlockMenubarLink
              linkType={block.link_type}
              url={block.url}
              onExternalUrlChange={onExternalUrlChange}
              internal_page_id={block.internal_page_id}
              onInternalPageIdChange={onInternalPageIdChange}
              internal_template_resource_slot_id={block.internal_template_resource_slot_id}
              onInternalTemplateResourceSlotIdChange={onInternalTemplateResourceSlotIdChange}
              internal_resource_id={block.internal_resource_id}
              onInternalResourceIdChange={onInternalResourceIdChange}
              onDynamicUrlChange={onDynamicUrlChange}
            />
          </BlockMenubarContent>
        </MenuBar.Portal>
      </MenuBar.Menu>
      {goToLink &&
        <MenuBar.Menu value="btn_goToLink">
          <BlockMenubarTrigger
            isLink path={goToLink} openInNewTab={(block.link_type === 'external' || block.link_type === 'resource')}
            tooltip="Go to Link"
            containerId={block.id}
          />
        </MenuBar.Menu>
      }
      <MenuBar.Separator className="element_topbar_separator" />
      <MenuBar.Menu value="btn_delete">
        <BlockMenubarTrigger labelIcon="trash" onClick={onBtnDelete} isDelete tooltip="Delete" containerId={block.id} />
      </MenuBar.Menu>
    </MenuBar.Root>
  );
}

export default ButtonMenubar;
import React, { useEffect, useState } from "react";

import { Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverPortal } from "../../../../shared/Popover/Popover";
import EditIcon from "../../EditIcon/EditIcon";
import SwitchInput from "../../../../shared/Inputs/SwitchInput"
import LabeledInput from "../../../../shared/Inputs/LabeledInput";
import FormulaInput from "../../../../shared/FormulaInput/FormulaInput";
import ValueExplorer from "./ValueExplorer/ValueExplorer";
import { StoreCalcVariable } from "../../../../store/reducers/page_calc_variables/page_calc_variables";
import { PlateAssessmentElement } from "../../../../plate-config/Plugins/Assessment/Assessment.plugin";
import { calculateAndFormat } from "../../../../utils/calc_plugins.util";
import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import PhIcon from "../../../../shared/Icon/PhIcon";

type Props = {
  element: PlateAssessmentElement
  onDisplayValueChange: (value: boolean) => void
  onValueFormulaChange: (formula: string) => void
  onValueFormulaNotesChange: (notes: string) => void
  calc_variables: StoreCalcVariable[]
  trackAssessmentExplore: () => void
  trackAssessmentChanges: (
    field: string, variable_id: string | null, value: string | number,
    previous_value: string | number, sliderValue: number | null, variables: StoreCalcVariable[] | null
  ) => void,
  isEditorReadOnly: boolean
  textColor: string
}

const ValueCalc = ({
  element, onDisplayValueChange, onValueFormulaChange, onValueFormulaNotesChange, calc_variables,
  trackAssessmentExplore, trackAssessmentChanges, isEditorReadOnly, textColor
}: Props) => {
  const currency = useAppSelector(state => state.page_addendums.currency)

  const { hide_value, value_formula_attributes, slider_variable_id } = element.assessment_detail_attributes
  const sliderValue = calc_variables.find((variable) => variable.id === slider_variable_id)?.default || 0

  const [hover, setHover] = useState(false)
  const [exploreVars, setExploreVars] = useState<StoreCalcVariable[]>([])

  const onDisplayValueHandler = (value: boolean) => {
    onDisplayValueChange(!value)
  }

  useEffect(() => {
    setExploreVars(getExploreVars())
  }, [value_formula_attributes, calc_variables])

  const extractVariablesFromFormula = (formula: string): string[] => {
    const pattern = /{{([A-Z]+)}}/g;
    let match: string[] | null;
    const matches: string[] = [];
    while ((match = pattern.exec(formula)) !== null) {
      const variableName = match[1];
      if (variableName !== 'A') {
        matches.push(variableName);
      }
    }
    return matches;
  }

  const getExploreVars = () => {
    const formula = value_formula_attributes.calculation
    const formulaVars = extractVariablesFromFormula(formula)
    return [...calc_variables].filter((variable) => formulaVars.includes(variable.handle))
  }


  const onFormulaNotesHandler = (e: React.ChangeEvent<HTMLInputElement>) => onValueFormulaNotesChange(e.target.value)

  return (
    <div className="w-48 relative assessment-dollar-stat">
      {!isEditorReadOnly && <div className="absolute top-0 -right-2 z-20 font-base">
        <Popover >
          <PopoverTrigger>
            <EditIcon />
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent sideOffset={5}>
              <PopoverArrow />
              <div className="w-80 border-solid border border-gray-100 rounded-xl bg-white drop-shadow z-30">
                <div className="p-5 flex flex-col gap-4">
                  <div className="uppercase font-semibold text-sm text-left brand-subtext">
                    Value Options
                  </div>
                  <SwitchInput
                    label="Display Value"
                    value={!hide_value}
                    setValue={onDisplayValueHandler}
                  />
                  <LabeledInput
                    type="text"
                    htmlFor="value-notes"
                    label="Value Notes"
                    value={value_formula_attributes.notes}
                    onChange={onFormulaNotesHandler}
                  />
                  <FormulaInput
                    formula={value_formula_attributes.calculation}
                    setFormula={onValueFormulaChange}
                    calc_variables={calc_variables}
                  />
                </div>
              </div>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </div>}
      <div className="w-full correct-options-popover">
        {isEditorReadOnly && !exploreVars.length ?
          <div className={`p-2 cursor-pointer rounded-xl transition-all duration-300 text-${textColor} `} >
            <div className="text-center text-sm uppercase paragraph-font-family">Value</div>
            <div className="text-center text-3xl header-font-family hover:text-4xl h-12 transition-all duration-300 ease-in-out">
              {calculateAndFormat({ formula: value_formula_attributes.calculation, variables: calc_variables, sliderValue, type: 'currency', currency })}
            </div>
          </div>
          :
          <Popover>
            <PopoverTrigger>
              <div className={`p-2 cursor-pointer rounded-xl transition-all duration-300 hover:bg-gray-100 text-${textColor} `} >
                <div
                  className={`
              ${hover ? "visible" : "hidden"} tooltip  white absolute -top-10 
              left-1/2 transform -translate-x-1/2 whitespace-nowrap transition-all
               duration-300 paragraph-font-family`}
                >
                  Click to Explore
                </div>
                <div className="relative text-sm uppercase paragraph-font-family">
                  <div className="text-center"> Value </div>
                  <div className="absolute top-0 right-10">
                    <PhIcon
                      name="info"
                      onMouseEnter={setHover.bind(setHover, true)}
                      onMouseLeave={setHover.bind(setHover, false)}
                      size={16}
                    />
                  </div>

                </div>
                <div className={`${hover ? "text-4xl" : "text-3xl"} text-center header-font-family h-12 transition-all duration-300 ease-in-out`}>
                  {calculateAndFormat({ formula: value_formula_attributes.calculation, variables: calc_variables, sliderValue, type: 'currency', currency })}
                </div>
              </div>
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent sideOffset={5}>
                <PopoverArrow />
                <ValueExplorer
                  variables={exploreVars}
                  valueNotes={value_formula_attributes.notes as string}
                  trackAssessmentExplore={trackAssessmentExplore}
                  trackAssessmentChanges={trackAssessmentChanges}
                  isEditorReadOnly={isEditorReadOnly}
                />
              </PopoverContent>
            </PopoverPortal>
          </Popover>}
      </div>
    </div>
  )
}

export default ValueCalc

import React, { useEffect, useRef, useState } from 'react'

import { useElementWidth } from '../../../../hooks/useElementWidth'
import Menu from '../Menu/Menu'
import PhIcon from '../../../../shared/Icon/PhIcon'
import ServiceButton from '../ServiceButton/ServiceButton'
import Logo from '../Logo/Logo'
import { useAppSelector } from '../../../../store/hooks/redux-hooks'

type Props = {
  bgColor: string
  pageWrapperHeight: number | null,
  navWidth: number
}

const Layout: React.FC<Props> = ({ bgColor, pageWrapperHeight, navWidth }) => {
  const {
    nav_menu_buttons, nav_display_logo, nav_button, nav_button_display, nav_text_color, logo_url,
    nav_router, template, navbar_display_menu
  } = useAppSelector(state => state.page_navigation)
  const { readOnly, isLandingPage, editorContainerWidth } = useAppSelector(state => state.page_addendums)
  const [open, setOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuWidth = useElementWidth(menuRef) || 0
  const containerWidth = useElementWidth(containerRef) || 0
  const [layoutSizes, setLayoutSizes] = useState({
    edgeContainer: 'min-w-[20%] max-w-[20%] w-[20%]',
    menuContainer: 'min-w-[60%] max-w-[60%] w-[60%]',
    collapsedContainer: 'min-w-[80%] max-w-[80%] w-[80%]'
  })

  useEffect(() => {
    if (navWidth > 0 && navWidth > 1150) {
      setLayoutSizes({
        edgeContainer: 'min-w-[10%] max-w-[10%] w-[10%]',
        menuContainer: 'min-w-[80%] max-w-[80%] w-[80%]',
        collapsedContainer: 'min-w-[90%] max-w-[90%] w-[90%]'
      })
    } else if (navWidth > 0 && navWidth < 1150) {
      setLayoutSizes({
        edgeContainer: 'min-w-[20%] max-w-[20%] w-[20%]',
        menuContainer: 'min-w-[60%] max-w-[60%] w-[60%]',
        collapsedContainer: 'min-w-[80%] max-w-[80%] w-[80%]'
      })
    }
  }, [navWidth])

  const isMobile = editorContainerWidth === 'mobile'
  const readOnlyMode = readOnly || isLandingPage
  const textColor = nav_text_color ? `${nav_text_color}` : 'text-white';
  const show_logo = readOnlyMode ? template ? nav_display_logo : nav_display_logo && logo_url : nav_display_logo
  const nav_menu_ready_items = nav_menu_buttons.filter(link => {
    if (link.href && link.url_type === 'section') return true
    if (link.href && link.url_type === 'page') {
      const page = nav_router.find(page => page.id === link.href)
      return page && (isLandingPage ? page.status === 'active' : page.status !== 'building')
    }
    return false
  })
  const show_nav_menu = readOnlyMode ? navbar_display_menu && nav_menu_ready_items.length > 0 : navbar_display_menu
  const show_service_button = readOnlyMode ? nav_button_display && (!!nav_button.text && !!nav_button.href) : nav_button_display
  const shouldCollapse = (containerWidth < menuWidth) || isMobile

  return (
    <div className="flex w-full relative">
      <div className={`${layoutSizes.edgeContainer} flex items-center justify-start`}>
        {show_logo && <Logo />}
      </div>
      <div
        className={`${layoutSizes.menuContainer} flex items-center ${shouldCollapse ? "invisible absolute" : ""}`}
        ref={containerRef} >
        <div ref={menuRef} className="flex m-auto">
          {show_nav_menu && <Menu />}
        </div>
      </div>
      <div
        className={`flex items-center justify-end ${shouldCollapse ? `${layoutSizes.collapsedContainer}` : `${layoutSizes.edgeContainer}`}`}>
        {
          shouldCollapse
            ?
            <>
              {
                show_nav_menu ?
                  <>
                    <div onClick={setOpen.bind(setOpen, !open)}>
                      <PhIcon name={open ? "x" : "list"} size={16} className={`cursor-pointer ${textColor}`} />
                    </div>
                    {
                      open &&
                      <div
                        // NOTE: this is full editor height burger menu. It's size is calculated based on the height of the editor.
                        style={{ height: pageWrapperHeight as number, minHeight: "400px", width: "calc(100% + 68px)" }}
                        className={`absolute z-[3] top-[52px] rounded-b-lg left-[-34px] items-center ${bgColor} ${textColor} flex flex-col`}>
                        {show_nav_menu && <Menu isMobile closeMenu={setOpen.bind(setOpen, false)} />}
                        {show_service_button && <ServiceButton />}
                      </div>
                    }
                  </>
                  : show_service_button && <ServiceButton />
              }
            </>
            : show_service_button && <ServiceButton />
        }
      </div>
    </div>
  )
}

export default Layout;
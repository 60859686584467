import React from 'react';

type ParagraphPlaceholderProps = {
  textColor: string;
}

export const ParagraphPlaceholder: React.FC<ParagraphPlaceholderProps> = ({ textColor }) => {
  return (
    <span
      contentEditable={false}
      suppressContentEditableWarning
      style={{
        position: 'absolute', top: "0px", left: "0px", whiteSpace: "nowrap",
        pointerEvents: "none", paddingLeft: "10px", transform: "translateY(40%)",
        fontWeight: 500, color: textColor === 'black' ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)",
      }}
    >
      Type &nbsp;
      <span
        style={{ color: "rgba(0, 0, 0, 0.5)" }}
        className={`border border-gray-300 bg-gray-100 px-[6px] leading-none pb-[2px] rounded `}
      >
        /
      </span>
      &nbsp; for commands
    </span>
  )
}
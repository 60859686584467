import React, { memo, useState } from "react"

import Tooltip from "../../../../shared/ToolTip/Tooltip";
import PhIcon from "../../../../shared/Icon/PhIcon";

type Props = {
  prompt: string
}

const PromptItem: React.FC<Props> = memo(({ prompt }) => {
  const [tooltipText, setTooltipText] = useState('Copy prompt');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(prompt)
      .then(() => {
        setTooltipText('Copied!');
        setTooltipOpen(true);
        setTimeout(() => {
          setTooltipText('Copy prompt');
          setTooltipOpen(false);
        }, 5000);
      });
  };
  return (
    <div className='dl-item mb-4 last:mb-0' id="prompt_tooltip_anchor">
      <div className="flex gap-2">
        <div className="flex items-center text-sm brand-text font-semibold">
          PROMPT
        </div>
        <Tooltip
          content={tooltipText}
          side={'right'}
          sideOffset={10}
          container="prompt_tooltip_anchor"
          open={tooltipOpen}
          onOpenChange={setTooltipOpen}
        >
          <div onClick={handleCopy} >
            <PhIcon name={"copy"} size={16} className="brand-text" />
          </div>
        </Tooltip>
      </div>
      <div className="text-gray-900 font-normal text-sm p-0 m-0 flex">{prompt}</div>
    </div>
  )
})

export default PromptItem

import { onPageStatusChange } from "../../page_navigation/page_navigation";
import { revvedupApi } from "../api";
import { getPageDataAttributes } from "../data-attributes.util";

export const publishPageEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    publishPage: builder.mutation<any, string>({
      query: (pageId) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData?.base_url}/pages/${pageId}/publish`,
          method: 'PATCH',
          headers: {
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
        }
      },
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageStatusChange('active'))
          }
        } catch (err) {
          console.log('Publish page process failed:', err);
        }
      }
    }),
  }),
})

export const { usePublishPageMutation } = publishPageEndpoints

import React from 'react';
import { withRef } from '@udecode/cn';
import { getHandler } from '@udecode/plate-common';
import { PlateElement, useElement } from '@udecode/plate-common/react';
import type { TMentionElement } from '@udecode/plate-mention';

import { useAppSelector } from '../../store/hooks/redux-hooks';

export const MentionElement = withRef<
  typeof PlateElement,
  {
    onClick?: (mentionNode: any) => void;
    prefix?: string;
    renderLabel?: (mentionable: TMentionElement) => string;
  }
>(({ children, className, onClick, prefix, renderLabel, ...props }, ref) => {
  const element = useElement<TMentionElement>();
  const contactsData = useAppSelector(state => state.page_contacts)

  const contacts = [...contactsData.contacts, ...contactsData.users]
  const activeContact = contacts.find(contact => contact.id === element.value)!;

  return (
    <PlateElement
      data-id={element.id}
      className={`${className} mention-element`}
      contentEditable={false}
      data-slate-value={element.value}
      onClick={getHandler(onClick, element)}
      ref={ref}
      as={'span'}
      {...props}
      style={{ fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit' }}
    >
      {activeContact?.name}
      {children}
    </PlateElement>
  );
});

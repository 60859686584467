import React from "react";

import PhIcon from "../../../shared/Icon/PhIcon";

type Props = {
  isElColumnChild: boolean;
}

const PreviewSelector: React.FC<Props> = ({ isElColumnChild }) => {

  const sources = [
    { name: 'Youtube', icon: 'youtube' },
    { name: 'Loom', icon: 'loom' },
    { name: 'Vimeo', icon: 'vimeo' },
    { name: 'Vidyard', icon: 'vidyard' },
    { name: 'Facebook', icon: 'facebook' },
    { name: 'Streamable', icon: 'streamable' },
    { name: 'Kaltura', icon: 'kaltura' },
    { name: 'Dailymotion', icon: 'dailymotion' },
    { name: 'Wistia', icon: 'wistia' },
    { name: 'Twitch', icon: 'twitch' },
  ];

  return (
    <section
      className={`
      w-full flex flex-col  py-10 gap-6 rounded-[4px] bg-gray-100 relative
      ${isElColumnChild ? "px-[43px]" : "px-[86px]"}
      `}
    >
      <span className="text-base text-center brand-subtext" >
        Add a video from your favorite video platform
      </span>
      <div className="flex gap-4 flex-wrap items-center justify-center">
        {sources.map((source, index) => (
          <div key={index} className="flex items-center justify-center">
            <PhIcon
              name={source.icon as any}
              className="text-gray-400"
              {...(isElColumnChild ? { width: "90px", height: "45px" } : {})}
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default PreviewSelector;

import React from "react";

import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import PhIcon, { PhIconName } from "../../../../shared/Icon/PhIcon";

export const socialsMap = [
  { source: "facebook", icon: "facebookLogo" },
  { source: "x", icon: "xLogo" },
  { source: "linkedin", icon: "linkedinLogo" },
  { source: "instagram", icon: "instagramLogo" },
  { source: "pinterest", icon: "pinterestLogo" },
  { source: "reddit", icon: "redditLogo" },
  { source: "snapchat", icon: "snapchatLogo" },
  { source: "tiktok", icon: "tiktokLogo" },
  { source: "youtube", icon: "youtubeLogo" },
]

const Socials: React.FC = () => {
  const { social_links, footer_text_color } = useAppSelector(state => state.page_navigation)
  const { readOnly, isLandingPage } = useAppSelector(state => state.page_addendums)

  const readOnlyMode = readOnly || isLandingPage
  const textColor = footer_text_color ? `${footer_text_color}` : 'text-white';
  const linksToDisplay = social_links
    .filter(link => socialsMap.find(social => social.source === link.platform))
    .map(link => ({ ...link, icon: socialsMap.find(social => social.source === link.platform)?.icon }))

  if (readOnly && social_links.length === 0) return null

  return (
    <section className="relative min-h-10">
      <div className="flex w-full items-center relative justify-center rounded-lg px-10 py-2 border border-transparent gap-5" >
        {
          linksToDisplay.map((item, index) => (
            <a key={index} href={item.href} target="_blank">
              <PhIcon
                name={item.icon as PhIconName}
                size={22}
                className={`${textColor} ${readOnlyMode ? "cursor-pointer" : "cursor-default"}`}
              />
            </a>
          ))
        }
        {!readOnlyMode && linksToDisplay.length === 0 &&
          <a className={`text-sm ${textColor} cursor-pointer`} target="_blank" href={'/settings/page_headers'}>
            Click To Manage Social Links
          </a>
        }
      </div>
    </section>
  );
}

export default Socials;
import React from 'react';
import { withRef, withVariants } from '@udecode/cn';
import { findNode, getParentNode } from '@udecode/plate-common';
import { isEditorReadOnly, ParagraphPlugin, PlateElement } from '@udecode/plate-common/react';
import { cva } from 'class-variance-authority';
import { useSelected } from 'slate-react';

import { ColumnPlugin } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';


const headingVariants = cva('', {
  variants: {
    variant: {
      h1: 'font-heading text-4xl font-bold',
      h2: 'font-heading text-xl font-semibold tracking-tight',
    },
  },
});

const HeadingElementVariants = withVariants(PlateElement, headingVariants);

export const HeadingElement = withRef<typeof HeadingElementVariants>(
  ({ variant = 'h1', children, className, ...props }, ref) => {
    const isReadOnly = isEditorReadOnly(props.editor)
    const selected = useSelected();

    const Element = variant!;
    const nodePath = findNode(props.editor, { at: [], match: { id: props.element.id } })![1];
    const parentNode = getParentNode(props.editor, nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const nextColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.findIndex(child => child.id === props.element.id) + 1] : null
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === props.element.id : false
    const isNextColElementParagraph = nextColElement ? nextColElement.type === ParagraphPlugin.key : false
    const activeSection = props.editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === props.element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === props.element.id;

    return (
      <HeadingElementVariants
        ref={ref}
        asChild
        variant={variant}
        id={props.element.id as string}
        data-id={props.element.id}
        className={`
        ${className} p-2 rounded-lg transition-all duration-300 ease-in-out selection:bg-sky-50 border border-transparent
        ${!isReadOnly && !selected ? 'hover:bg-[#B9D3F426]' : ''} ${!isReadOnly && selected ? 'border-sky-50' : ''}
        ${isElColumnChild
            ? `${isLastColElement ? "mb-0" : isNextColElementParagraph ? "mb-2" : "mb-4"}`
            : `page-block header-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
        `}
        {...props}
      >
        <Element>{children}</Element>
      </HeadingElementVariants>
    );
  }
);

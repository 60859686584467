import React from 'react';

import PhIcon, { PhIconName } from '../Icon/PhIcon';

type Props = {
  data: {
    iconName?: PhIconName;
    key: string;
    value?: string;
  }[]
  onOptionClick: (key: string) => void;
  activeKey?: string;
}

const BlockMenubarOptionList: React.FC<Props> = ({ data, onOptionClick, activeKey }) => {
  return (
    <section
      className="flex flex-col gap-[2px] w-full"
      contentEditable={false}
      suppressContentEditableWarning
    >
      {
        data.map((item, i) => {
          return (
            <div
              key={i}
              className={`topbar_content_option_list_item ${activeKey === item.key ? 'active' : ''}`}
              onClick={onOptionClick.bind(onOptionClick, item.key)}

            >
              {item.iconName && <PhIcon name={item.iconName} size={16} color='brand-text' />}
              {item.value &&
                <div className='text-sm font-normal brand-text cursor-pointer'>
                  {item.value}
                </div>
              }
            </div>
          )
        })
      }
    </section>
  );
}

export default BlockMenubarOptionList;
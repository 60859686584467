import React, { useState } from "react"

import { Popover, PopoverTrigger } from "../../../../shared/Popover/Popover"
import PhIcon from "../../../../shared/Icon/PhIcon"


type Props = {
  onLockScreenToggle: (val: boolean) => void
}

const LockMenu = ({ onLockScreenToggle }: Props) => {
  const [open, setOpen] = useState(false)

  const onOpenChange = (val: boolean) => {
    setOpen(val)
    onLockScreenToggle(val)
  }

  return (
    <Popover onOpenChange={onOpenChange}>
      <PopoverTrigger>
        <div className="btn btn-small btn-white first-child">
          <PhIcon name={open ? 'lock' : "lockOpen"} size={16} />
        </div>
      </PopoverTrigger>
    </Popover>
  )
}

export default LockMenu

import React from "react";

import { useAppSelector } from "../../../../store/hooks/redux-hooks";

const Logo: React.FC = () => {
  const { seller_id, logo_url, nav_text_color, template } = useAppSelector(state => state.page_navigation)
  const { readOnly, isLandingPage } = useAppSelector(state => state.page_addendums)

  const readOnlyMode = readOnly || isLandingPage
  const changeLogoPath = seller_id ? `/settings/profiles/${seller_id}/edit` : '/settings/profiles'
  const textColor = nav_text_color ? `${nav_text_color}` : 'text-white';

  return (
    <div className="max-h-8 overflow-hidden">
      {
        readOnlyMode
          ?
          template
            ? <div className={`text-lg font-medium ${textColor}`}>LOGO</div>
            : logo_url
              ? <img src={logo_url} alt="page-logo" className="h-full w-full object-contain max-h-8" />
              : null
          :
          template
            ? <a className={`text-lg font-medium ${textColor}`} href={changeLogoPath}>LOGO</a>
            : logo_url
              ? <img src={logo_url} alt="page-logo" className="h-full w-full object-contain max-h-8" />
              : <a className={`text-lg font-medium ${textColor}`} href={changeLogoPath}>LOGO</a>
      }
    </div>
  );
}

export default Logo;
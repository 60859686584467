import React, { useState } from "react";
import * as MenuBar from "@radix-ui/react-menubar";
import { useEditorRef } from "@udecode/plate-common/react";

import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";
import BlockMenubarContent from "../../../shared/BlockMenubarControls/BlockMenubarContent";
import BlockMenubarOptionList from "../../../shared/BlockMenubarControls/BlockMenubarOptionList";
import { PlateElementEmbed } from "../../../plate-config/Plugins/Embed/Embed.plugin";
import { EmbedConfig } from "../config/config";
import MediaInput from "../../../shared/Inputs/MediaInput";


type Props = {
  showTrigger: boolean;
  block: PlateElementEmbed;
  platform: EmbedConfig | null;
  onUrlChange: () => void;
  onUrlSubmit: (url: string) => void;
  error: boolean;
  errorInstruction?: string;
}

const Menubar: React.FC<Props> = ({
  showTrigger, block, platform, onUrlChange, onUrlSubmit, error, errorInstruction
}) => {

  const editor = useEditorRef();
  const [value, setValue] = useState<string>("");

  const onElementDelete = () => editor.removeNodes({ at: [], match: (n: any) => n.id === block.id })
  const onSizeChange = (width_mode: string) => editor.setNodes({ width_mode } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
  const onAlignChange = (align: string) => editor.setNodes({ align } as Partial<Node>, { at: [], match: (n: any) => { return n.id === block.id } })

  const sizeOptions: any = [
    { key: "small", value: "Small", iconName: "sizeSmall" },
    { key: "medium", value: "Medium", iconName: "sizeMedium" },
    { key: "large", value: "Large", iconName: "sizeLarge" }
  ]
  const size = block.width_mode ? block.width_mode : 'large'
  const align = block.align || 'center'
  const alignOptions: any = [
    { key: "left", iconName: "alignLeft" },
    { key: "center", iconName: "alignCenterVertical" },
    { key: "right", iconName: "alignRight" }
  ]

  return (
    <MenuBar.Root
      value={value}
      onValueChange={setValue}
      className={`${showTrigger ? "element_topbar_wrapper top-[-45px]" : "hidden"}`}
    >
      <MenuBar.Menu value="embed_url">
        <MediaInput
          value={block.url}
          onValueChange={onUrlChange}
          onValueSubmit={onUrlSubmit}
          placeholder="Insert a link to the video..."
          error={error ? { message: "Link address is not correct.", link: errorInstruction } : undefined}
        />
      </MenuBar.Menu>
      <MenuBar.Separator className="element_topbar_separator" />
      {platform &&
        <>
          <MenuBar.Menu value="embed_align">
            <BlockMenubarTrigger
              isDropdown
              iconBeforeLabel={alignOptions.find(item => item.key === align)?.iconName}
              isOpened={value === 'embed_align'}
              tooltip="Align"
              containerId={block.id}
            />
            <MenuBar.Portal>
              <BlockMenubarContent>
                <BlockMenubarOptionList
                  data={alignOptions}
                  onOptionClick={onAlignChange}
                  activeKey={align}
                />
              </BlockMenubarContent>
            </MenuBar.Portal>
          </MenuBar.Menu>
          <MenuBar.Separator className="element_topbar_separator" />
          {platform.widthMode &&
            <>
              <MenuBar.Menu value="embed_size">
                <BlockMenubarTrigger
                  isDropdown
                  label={sizeOptions.find(item => item.key === size)?.value}
                  iconBeforeLabel={sizeOptions.find(item => item.key === size)?.iconName}
                  isOpened={value === 'embed_size'}
                  tooltip="Size"
                  containerId={block.id}
                />
                <MenuBar.Portal>
                  <BlockMenubarContent>
                    <BlockMenubarOptionList
                      data={sizeOptions}
                      onOptionClick={onSizeChange}
                      activeKey={size}
                    />
                  </BlockMenubarContent>
                </MenuBar.Portal>
              </MenuBar.Menu>
              <MenuBar.Separator className="element_topbar_separator" />
            </>
          }
        </>
      }
      <MenuBar.Menu value="embed_delete">
        <BlockMenubarTrigger labelIcon="trash" onClick={onElementDelete} isDelete tooltip="Delete" containerId={block.id} />
      </MenuBar.Menu>
    </MenuBar.Root>
  );
}

export default Menubar;
import React, { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import ToolbarSeparator from "../../../../shared/ToolbarControls/ToolbarSeparator";
import { useUpdateNavLinksAttributeMutation } from "../../../../store/reducers/api/navbar/navbar.api";
import ToolbarColorSelector from "../../../../shared/ToolbarControls/ToolbarColorSelector";
import BlockMenubarInput from "../../../../shared/BlockMenubarControls/BlockMenubarInput";

const ServiceButton: React.FC = () => {
  const { nav_button } = useAppSelector(state => state.page_navigation)
  const { readOnly, isLandingPage } = useAppSelector(state => state.page_addendums)
  const [open, setOpen] = useState(false)
  const [updateNavLinksAttribute, { isLoading }] = useUpdateNavLinksAttributeMutation()
  const [title, setTitle] = useState(nav_button.text || '')
  const [href, setHref] = useState(nav_button.href || '')
  const [titleColor, setTitleColor] = useState(nav_button.text_color)
  const [backgroundColor, setBackgroundColor] = useState(nav_button.background_color)

  const bgColor = nav_button.background_color ? `background-${nav_button.background_color}` : 'bg-white';
  const textColor = nav_button.text_color ? `${nav_button.text_color}` : 'text-black';
  const readOnlyMode = readOnly || isLandingPage

  const onBtnTitleChange = (text: string | null) => setTitle(text || '')
  const onBtnUrlChange = (href: string | null) => setHref(href || '')
  const onBtnColorChange = (background_color: string | null) => setBackgroundColor(background_color)
  const onBtnTextColorChange = (text_color: string | null) => setTitleColor(text_color)
  const onSubmit = async () => await updateNavLinksAttribute([{
    ...nav_button,
    text: title.trim().length > 0 ? title : null,
    href: href.trim().length > 0 ? href : null,
    background_color: backgroundColor,
    text_color: titleColor
  }])
  const preventDefault = (e: Event) => e.preventDefault()
  const preventPointerMove = (e: React.PointerEvent) => e.preventDefault()
  const onServiceBtnClick = () => !!nav_button.href && window.open(nav_button.href as string, '_blank', 'noopener,noreferrer')

  return (
    <DropdownMenu.Root open={readOnlyMode ? false : open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        <button
          className={` 
            min-w-max items-center flex px-[14px] py-3 rounded-xl outline-none text-sm
            font-medium h-10 text-nowrap paragraph-font-family ${textColor} ${bgColor}
            `}
          onClick={onServiceBtnClick}
        >
          {nav_button.text ? nav_button.text : "Button"}
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          side="bottom"
          sideOffset={2}
          className="menubar_wrapper-content"
        >
          <DropdownMenu.Item
            onSelect={preventDefault}
            onPointerEnter={preventPointerMove}
            onPointerMove={preventPointerMove}
            onPointerLeave={preventPointerMove}
            className="outline-none flex flex-col items-center justify-between gap-4 w-full"
          >
            <BlockMenubarInput label="Title" maxLength={20} onChange={onBtnTitleChange} value={title} disabled={isLoading} />
            <BlockMenubarInput label="Url" onChange={onBtnUrlChange} isUrl={true} value={href} disabled={isLoading} />
            <ToolbarSeparator variant="horizontal" />
            <ToolbarColorSelector
              label="Background Color"
              color={backgroundColor}
              onColorChange={onBtnColorChange}
              onColorReset={onBtnColorChange.bind(onBtnColorChange, null)}
              wrapperClassName="w-full"
            />
            <ToolbarColorSelector
              label="Text Color"
              color={titleColor}
              onColorChange={onBtnTextColorChange}
              onColorReset={onBtnTextColorChange.bind(onBtnTextColorChange, null)}
              wrapperClassName="w-full"
            />
            <ToolbarSeparator variant="horizontal" />
            <button
              disabled={isLoading}
              onClick={onSubmit}
              className="brand_btn-primary w-full">
              Apply
            </button>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root >
  );
}

export default ServiceButton;
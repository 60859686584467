import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:before-render", ({ detail: { newBody }}) => {
      const isCollapsed = document.cookie.includes("sidebar=true")
      const sidebarButton = newBody.querySelector("input#sidebar_button")

      if (sidebarButton) sidebarButton.checked = isCollapsed
    })
  }

  toggle({ target: { checked } }) {
    Cookies.set('sidebar', checked)
  }
}

import React from "react";

import PhIcon, { PhIconName } from "../Icon/PhIcon";

// Needed to use as popover trigger

type Props = {
  onClick?: () => void
  iconName: PhIconName
  iconClassName: string
}

const ColorSelectorDashedDiv: React.FC<Props> = ({
  onClick, iconName, iconClassName
}) => {

  return (
    <div className="p-[2.5px] h-8 w-8">
      <div
        className={`
          rounded-full border-dashed border-gray-900/20  border flex 
          items-center justify-center w-[27.5px] h-[27.5px]
          ${onClick ? "cursor-pointer" : "cursor-default"}
        `}
        onClick={onClick}
      >
        <PhIcon name={iconName} size={16} className={iconClassName} />
      </div>
    </div>
  );
}

export default ColorSelectorDashedDiv;
import React from 'react'

import { PlateElementAiTextBlock } from "../../../../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin";

type Props = {
  element: PlateElementAiTextBlock,
  list_items_min: number
  list_items_max: number
  onMinListItemsChange: (list_items_max: number) => void
  onMaxListItemsChange: (list_items_min: number) => void
}

const ListLength: React.FC<Props> = ({
  element, onMaxListItemsChange, onMinListItemsChange, list_items_min, list_items_max
}) => {
  const onMinHandle = (e: React.ChangeEvent<HTMLInputElement>) => onMinListItemsChange(+e.target.value)
  const onMaxHandle = (e: React.ChangeEvent<HTMLInputElement>) => onMaxListItemsChange(+e.target.value)

  const onChangeMinValidator = (min: number | null, max: number | null) => {
    if (typeof min !== 'number' && typeof max === 'number') {
      return `Must be less than ${max}`
    }
    if (typeof min === 'number' && typeof max === 'number' && min > max) {
      return `Must be less than ${max}`
    }
    if (typeof min === 'number' && min < 1) {
      return `Must be greater than 0`
    }
  }

  const onChangeMaxValidator = (min: number | null, max: number | null) => {
    if (typeof min === 'number' && typeof max !== 'number') {
      return `Must be greater than ${min}`
    }
    if (typeof min === 'number' && typeof max === 'number' && min > max) {
      return `Must be greater than ${min}`
    }
    if (typeof max === 'number' && max < 1) {
      return `Must be greater than 0`
    }
  }

  return (
    <div className="flex w-full gap-4">
      <div className="flex flex-col gap-2 w-[50%]">
        <div className="brand-text text-sm font-medium" >
          {`Min # of ${element.details.list_style === 'unordered' ? 'Bulleted' : 'Numbered'} items`}
        </div>
        <input
          className={`brand_input ${onChangeMinValidator(list_items_min, list_items_max) ? "error" : ""}`}
          type="number"
          value={list_items_min}
          onChange={onMinHandle}
          min={1}
        />
        {onChangeMinValidator(list_items_min, list_items_max) && <div className="brand_input-error">{onChangeMinValidator(list_items_min, list_items_max)}</div>}
      </div>
      <div className="flex flex-col gap-2 w-[50%]">
        <div className="brand-text text-sm font-medium" >
          {`Max # of ${element.details.list_style === 'unordered' ? 'Bulleted' : 'Numbered'} items`}
        </div>
        <input
          className={`brand_input ${onChangeMaxValidator(list_items_min, list_items_max) ? "error" : ""}`}
          type="number"
          value={list_items_max}
          onChange={onMaxHandle}
          min={1}
        />
        {onChangeMaxValidator(list_items_min, list_items_max) && <div className="brand_input-error">{onChangeMaxValidator(list_items_min, list_items_max)}</div>}
      </div>
    </div>
  )
}

export default ListLength;
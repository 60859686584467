import React, { memo } from "react";

import PhIcon from "../../../shared/Icon/PhIcon";

type Props = {
  textColor?: string
}
const EditIcon = memo(({ textColor = 'black' }: Props) => {
  return (
    <div
      className={`btn btn-clear ${textColor === "white" ? "reversed" : ""} btn-icon-large assessment-edit-button`}
    >
      <PhIcon name="pencil" size={18} />
    </div>
  )
})

export default EditIcon

import React, { useState } from "react";
import { isEditorReadOnly, useEditorRef } from "@udecode/plate-common/react"
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { Popover, PopoverTrigger, PopoverPortal, PopoverContent } from "../../../../shared/Popover/Popover"
import EditableField from "../../../../shared/EditableField/EditableField"
import Toolbar from "./Toolbar/Toolbar";
import Config from "./Config/Config";
import { PlatePricingElement } from "../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import { textColorFromColorClass } from "../../../../utils/color.util";


type Props = {
  tableIndex: number
  element: PlatePricingElement
}

const Header: React.FC<Props> = ({ tableIndex, element }) => {
  const colors = useAppSelector(state => state.page_colors.activePalette.colors)
  const editor = useEditorRef()
  const isReadOnly = isEditorReadOnly(editor)

  const table = element.pricing_tables_attributes[tableIndex];

  const [tableTitle, setTableTitle] = useState(table.title || '')

  const textColor = textColorFromColorClass(element.primary_color, colors)

  const onTableTitleHandle = (value: string) => {
    setTableTitle(value)
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, title: value }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  return (
    <TableRow  >
      <TableCell colSpan={10} sx={{ padding: 0, borderBottom: 'unset' }}>
        <Popover open={isReadOnly ? false : undefined}>
          <PopoverTrigger asChild>
            <div className={` w-full px-2 pt-16 pb-3 rounded-t-xl border-b border-gray-100 background-${element.primary_color}`}>
              <EditableField
                value={tableTitle}
                onChange={onTableTitleHandle}
                placeholder="My table"
                wrapperClassName="w-[60%] mb-3"
                inputClassName={`text-${textColor} header-font-family text-lg`}
                disabled={isReadOnly}
              />
            </div>
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent sideOffset={5} side="top" align="center" onOpenAutoFocus={e => e.preventDefault()}>
              <Toolbar element={element} tableIndex={tableIndex} />
            </PopoverContent>
          </PopoverPortal>
        </Popover>
        {!isReadOnly && <Config element={element} tableIndex={tableIndex} />}
      </TableCell>
    </TableRow>
  )
}

export default Header
import React, { memo } from "react"

type Props = {
  data: {
    name: any,
    label: string | React.ReactElement,
  }[],
  current: string | boolean,
  setCurrent: (value: any) => void,
  disabled?: boolean,
  wrapperClassName?: string,
  btnGroupClassName?: string,
}

const ButtonSelector = memo(({
  data,
  current,
  setCurrent,
  disabled,
  wrapperClassName,
  btnGroupClassName,
}: Props) => {

  return (
    <div className={`w-full border border-gray-100 rounded-xl bg-gray-100 mt-1 ${wrapperClassName}`}>
      <div className={`flex btn-group text-center gap-1 ${btnGroupClassName}`}>
        {data.map((item) =>
          <button
            key={`${item.name}-button-item-${Math.random()}`}
            onClick={setCurrent.bind(setCurrent, item.name)}
            className={`flex-1 btn btn-gray justify-center ${item.name === current ? "active" : ""}`}
            disabled={disabled}
          >
            {item.label}
          </button>
        )}
      </div>
    </div>
  )
})

export default ButtonSelector

import React from 'react';
import { useEditorRef } from '@udecode/plate-common/react';
import { findNode } from '@udecode/plate-common';

import { createDefaultSectionElement, PlateSectionElement } from '../../../plate-config/Plugins/Section/Section.plugin';
import PhIcon from '../../../shared/Icon/PhIcon';
import Tooltip from '../../../shared/ToolTip/Tooltip';

type Props = {
  sectionElement: PlateSectionElement
  selected: boolean
}

const BottomToolbar: React.FC<Props> = ({ sectionElement, selected }) => {
  const editor = useEditorRef()

  const node = findNode(editor, { at: [], match: { id: sectionElement.id } })!
  const nodePath = node[1][0]
  const isLastSection = editor.children[editor.children.length - 1].id === sectionElement.id

  const onSectionAdd = () => editor.insertNodes(createDefaultSectionElement(), { at: [nodePath + 1], select: true })

  return (
    <>
      <div
        className={`
    absolute flex items-center justify-center w-full opacity-0 hover:opacity-100
    ${isLastSection ? "bottom-[-3px]" : "bottom-[-24px] py-5"}`}
      >
        <div className='w-full py-1 bg-[#B9D3F4CC]'>
          <div className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 border-t-[2px] border-white border-dashed " />
        </div>
        <Tooltip content={'Add Section'} side={'top'} sideOffset={5} container={sectionElement.id}>
          <div
            onClick={onSectionAdd}
            className="w-10 h-10 border-[3px] absolute border-white bg-primary flex items-center justify-center rounded-full z-[1] cursor-pointer"
          >
            <PhIcon name="plus" size={18} className="text-black" />
          </div>
        </Tooltip>
      </div>
      {selected ?
        <>
          <div className='absolute w-full top-0 h-[1px] bg-[#B9D3F4CC]' />
          <div className='absolute w-full bottom-0 h-[1px] bg-[#B9D3F4CC]' />
        </>
        :
        <>
          <div className='absolute w-full top-0 h-[1px] bg-[#B9D3F4CC] opacity-0 group-hover/section:opacity-100' />
          <div className='absolute w-full bottom-0 h-[1px] bg-[#B9D3F4CC] opacity-0 group-hover/section:opacity-100' />
        </>
      }
    </>
  )
}

export default BottomToolbar
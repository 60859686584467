import React from "react";

import Tooltip from "../../../shared/ToolTip/Tooltip";
import PhIcon from "../../../shared/Icon/PhIcon";

type Props = {
  isElColumnChild: boolean;
}

const PreviewSelector: React.FC<Props> = ({ isElColumnChild }) => {

  const sources = [
    { name: 'Linkedin', icon: 'linkedin', tooltip: "https://www.linkedin.com/embed/feed/update/urn:li:XXX:XXX" },
    { name: 'Calendly', icon: 'calendly', tooltip: 'https://calendly.com/XXX' },
    { name: 'Pitch', icon: 'pitch', tooltip: "https://pitch.com/v/XXX" },
    { name: 'Google Docs', icon: 'gooleDocs', tooltip: "https://docs.google.com/document/d/XXX" },
    { name: 'Google Slides', icon: 'gooleSLides', tooltip: "https://docs.google.com/presentation/d/XXX" },
    { name: 'Google Forms', icon: 'googleForms', tooltip: "https://docs.google.com/forms/d/XXX" },
    { name: 'Google Sheets', icon: 'googleSheets', tooltip: "https://docs.google.com/spreadsheets/d/XXX" },
    { name: 'Hubspot', icon: 'hubspot', tooltip: "https://share-XXX.hsforms.com/XXX" },
    { name: 'Miro', icon: 'miro', tooltip: "https://miro.com/app/board/boardname/?share_link_id=XXX" },
    { name: 'Tiktok', icon: 'tiktok', tooltip: "https://www.tiktok.com/@username/video/XXX" },
    { name: 'X', icon: 'twitter', tooltip: "https://twitter.com/username/status/XXX" },
    { name: 'Facebook', icon: 'facebook', tooltip: "https://www.facebook.com/username/posts/XXX" },
    { name: 'Instagram', icon: 'instagram', tooltip: "https://www.instagram.com/p/XXX/" },
  ];

  return (
    <section
      className={`
      w-full flex flex-col  py-10 gap-6 rounded-[4px] bg-gray-100 relative
      ${isElColumnChild ? "px-[43px]" : "px-[86px]"}
      `}
    >
      <span className="text-base text-center brand-subtext" >
        Embed Content From:
      </span>
      <div className="flex gap-4 flex-wrap items-center justify-center" id="embed_tooltip-anchor">
        {sources.map((source, index) => (
          <div key={index} className="flex items-center justify-center">
            <Tooltip
              content={source.tooltip}
              side={'bottom'}
              container={'embed_tooltip-anchor'}
              contentClassName="tooltip_container"
            >
              <div>
                <PhIcon
                  name={source.icon as any}
                  className="text-gray-400"
                  {...(isElColumnChild ? { width: "90px", height: "45px" } : {})}
                />
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    </section>
  );
}

export default PreviewSelector;

import React, { memo, useState } from "react";
import { HexColorPicker as ReactColorfulPicker } from 'react-colorful';

import PhIcon from "../../../../../../../shared/Icon/PhIcon";

type Props = {
  initialColor: string | null,
  handleUpdateColor: (color: string) => void,
  goBackHandle: () => void,
}

const HexColorPicker = memo(({
  initialColor,
  handleUpdateColor,
  goBackHandle,
}: Props) => {

  const [color, setColor] = useState(initialColor || "#008dd9")

  const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setColor(e.target.value)
  }

  const handleColorSelect = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    handleUpdateColor(color)
  }


  return (
    <div className="w-[176px]">
      <div className="flex justify-between items-center mb-2">
        <span className="brand-text text-xs">Select Custom Color</span>
        <button onClick={goBackHandle} className="bg-gray-100 border rounded-xl px-3 h-[34px] flex items-center justify-center  border-gray-300">
          <PhIcon name="arrowLeft" size={16} className="brand-text" />
        </button>
      </div>
      <ReactColorfulPicker
        color={color}
        onChange={setColor}
      />
      <div className="flex justify-between items-center gap-1 mt-2">
        <input
          className="text-xs text-black border border-[#D3CDCD] rounded-lg p-2 w-[115px]"
          value={color}
          onChange={handleInputValueChange}
        />
        <button
          className="bg-primary brand-text px-3 h-[34px] rounded-xl flex items-center justify-center text-base font-medium"
          onClick={handleColorSelect}
        >
          Add
        </button>
      </div>
    </div>
  )
})

export default HexColorPicker

import React from "react";
import { focusEditor, ParagraphPlugin, useEditorRef, useEditorSelector } from "@udecode/plate-common/react";
import { BulletedListPlugin, NumberedListPlugin } from "@udecode/plate-list/react";
import { getParentNode, select, TElement, toggleBlock } from "@udecode/plate-common";
import { getListRoot, toggleList, unwrapList } from "@udecode/plate-list";

import { turnIntoItems } from "./TurnIntoDefaultNodes";
import BlockMenubarTrigger from "../../../shared/BlockMenubarControls/BlockMenubarTrigger";


const TurnIntoOl: React.FC = () => {
  const editor = useEditorRef();

  const defaultItem = turnIntoItems.find((item) => item.value === ParagraphPlugin.key)!;
  const selectedItem = useEditorSelector((editor) => {
    const entry = getParentNode(editor, editor.selection as any)?.[0] as TElement
    if (entry) {
      return turnIntoItems.find((item) => {
        if (entry && entry.type === 'lic') {
          const listRootElement = getListRoot(editor);
          if (listRootElement) {
            return listRootElement[0].type === item.value;
          }
        }
        if (entry) {
          return item.value === entry.type;
        }
        return item.value === ParagraphPlugin.key;
      }) || defaultItem
    } else {
      return defaultItem;
    }
  }, []);

  const isSelectedItemOl = selectedItem.value === NumberedListPlugin.key;

  const onValueHandle = () => {
    if (isSelectedItemOl) {
      unwrapList(editor)
      toggleBlock(editor, { type: ParagraphPlugin.key })
      select(editor, editor.selection!);
      focusEditor(editor);
      return
    }
    if (selectedItem.value === BulletedListPlugin.key) {
      toggleList(editor, { type: NumberedListPlugin.key });
      select(editor, editor.selection!);
      focusEditor(editor);
      return
    }
    toggleBlock(editor, { type: NumberedListPlugin.key });
    select(editor, editor.selection!);
    focusEditor(editor);
  }

  return <BlockMenubarTrigger
    labelIcon="listNumbers"
    onClick={onValueHandle}
    isOpened={isSelectedItemOl}
    tooltip="Ordered List"
    containerId="floating-toolbar"
  />
}

export default TurnIntoOl;
import { TElement } from "@udecode/plate-common";
import { createPlatePlugin } from "@udecode/plate-common/react";

export const ButtonPlugin = createPlatePlugin({
  key: 'button',
  node: {
    type: 'button',
    isElement: true,
    isVoid: true,
  },
  priority: 800
})

export interface PlateButtonElement extends TElement {
  id: string
  type: typeof ButtonPlugin.key,
  content: string | null,
  primary_color: string | null,
  secondary_color: string | null,
  url: string | null,
  link_type: string,
  internal_page_id: string | null,
  internal_resource_id: string | null,
  internal_template_resource_slot_id: string | null,
  menuOpen: boolean
}

export const createDefaultButtonElement = (): TElement => ({
  type: ButtonPlugin.key,
  children: [{ text: '' }],
  content: '',
  primary_color: null,
  secondary_color: null,
  url: null,
  link_type: 'external',
  internal_page_id: null,
  internal_resource_id: null,
  internal_template_resource_slot_id: null,
  menuOpen: false
})
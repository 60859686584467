import React, { useState } from "react";

type Props = {
  value: string,
  onChange: (value: string) => void,
  placeholder?: string
  contentAfter?: React.ReactNode,
  contentBefore?: React.ReactNode
  inputClassName?: string
}

const DecoratedInput: React.FC<Props> = ({ value, onChange, placeholder, contentAfter, contentBefore, inputClassName }) => {
  const [isInputActive, setInputActive] = useState<boolean>(false);
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)

  return (
    <div className={`decorated_input-wrapper ${isInputActive ? 'active' : ''}`}>
      {contentBefore}
      <input
        value={value}
        type="text"
        className={`decorated_input ${inputClassName}`}
        placeholder={placeholder}
        onChange={onInputChange}
        onFocus={setInputActive.bind(setInputActive, true)}
        onBlur={setInputActive.bind(setInputActive, false)}
      />
      {contentAfter}
    </div>
  )
}

export default DecoratedInput;
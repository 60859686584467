import React, { useState } from "react";
import * as Switch from "@radix-ui/react-switch";

type Props = {
  checked: boolean;
  onChange: (val: boolean) => void;
  disabled?: boolean;
}

const SwitchControl: React.FC<Props> = ({
  checked, onChange, disabled
}) => {
  const [active, setActive] = useState(checked);
  const handleChange = (val: boolean) => {
    setActive(val);
    onChange(val);
  };


  return (
    <Switch.Root className="switch_root_control" checked={active} onCheckedChange={handleChange} disabled={disabled}>
      <Switch.Thumb className="switch_thumb_control" />
    </Switch.Root>
  );
}

export default SwitchControl;
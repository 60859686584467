import { useState, useEffect } from 'react';

type ViewMode = 'laptop' | 'tablet' | 'mobile';

const getDeviceConfig = (width: number): ViewMode => {
  if (width <= 640) {
    return 'mobile';
  } else if (width > 640 && width <= 1024) {
    return 'tablet';
  } else {
    return 'laptop';
  }
};

const useViewMode = (): ViewMode => {

  const [viewMode, setViewMode] = useState<ViewMode>(getDeviceConfig(window.innerWidth));
  useEffect(() => {
    const handleResize = () => {
      setViewMode(getDeviceConfig(window.innerWidth));
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return viewMode;
};

export default useViewMode;

import React from "react";

type Props = {
  onModalOpen: () => void
}

const SettingsView: React.FC<Props> = ({ onModalOpen }) => {
  return (
    <div className="min-h-[400px] bg-gray-100 rounded flex w-full flex-col gap-10 items-center justify-center">
      <div className="text-[#4C4C4C] text-base w-[70%] text-center">
        Using the AI Text Block, you can generate various page elements with AI. Click the button below to configure.
      </div>
      <button onClick={onModalOpen} className="brand_btn-secondary">  Click to Configure </button>
    </div>
  )
}

export default SettingsView;
import React, { useState } from "react";
import { useEditorRef, isEditorReadOnly } from "@udecode/plate-common/react";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { PlatePricingElement, createDefaultTableRow, PlatePricingLocalElement } from "../../../../plate-config/Plugins/Pricing/Pricing.plugin";
import DescriptionCell from "./DescriptionCell/DescriptionCell";
import PriceCell from "./PriceCell/PriceCell";
import QuantityCell from "./QuantityCell/QuantityCell";
import SubtotalCell from "./SubtotalCell/SubtotalCell";
import ValueCell from "./ValueCell/ValueCell";
import FloatingMenu from "./FloatingMenu/FloatingMenu";
import RowOptions from "./RowOptions/RowOptions";
import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import { StoreCalcVariable } from "../../../../store/reducers/page_calc_variables/page_calc_variables";
import { CheckBoxChangeArgs, QuantityChangeArgs, RadioChangeArgs } from "../../PricingElement";


type Props = {
  element: PlatePricingElement
  tableIndex: number
  rowIndex: number
  localElement: PlatePricingLocalElement
  setLocalElement: React.Dispatch<React.SetStateAction<PlatePricingLocalElement | undefined>>
  calc_variables: StoreCalcVariable[]
  trackQuantityChange: (args: QuantityChangeArgs) => void
  trackItemCheckBoxChange: (args: CheckBoxChangeArgs) => void
  trackItemRadioChange: (args: RadioChangeArgs) => void
}

export const Row: React.FC<Props> = ({
  element, tableIndex, rowIndex, localElement, setLocalElement, calc_variables,
  trackQuantityChange, trackItemCheckBoxChange, trackItemRadioChange
}) => {
  const editor = useEditorRef()
  const isReadOnly = isEditorReadOnly(editor)
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)
  const editorContainerWidth = useAppSelector(state => state.page_addendums.editorContainerWidth)
  const [isHovered, setIsHovered] = useState(false);
  const [isRowOptionsOpen, setIsRowOptionsOpen] = useState(false);

  const isMobileView = editorContainerWidth === 'mobile'
  const activeTable = element.pricing_tables_attributes[tableIndex]
  const activeRow = activeTable.line_items_attributes[rowIndex]

  const toggleRowOptions = () => setIsRowOptionsOpen(!isRowOptionsOpen)

  const onRowAddHandle = () => {
    const tableRows = [...activeTable.line_items_attributes]
    tableRows.splice(rowIndex + 1, 0, createDefaultTableRow())
    const pricing_tables_attributes = element.pricing_tables_attributes.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, line_items_attributes: tableRows }
      }
      return table
    })
    editor.setNodes(
      { pricing_tables_attributes } as Partial<Node>,
      { at: [], match: (n: any) => n.id === element.id }
    )
  }

  const isLastRow = rowIndex === activeTable.line_items_attributes.length - 1

  return (
    <TableRow
      data-id={activeRow.id}
      sx={{
        position: 'relative',
        '&:hover': {
          outline: isReadOnly ? '0' : '1px solid #2fe1a6',
          outlineStyle: 'dashed',
          outlineOffset: '-1px',
          ...(isLastRow && { borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' })
        },
      }}
      className="border-t border-gray-100"
      onMouseEnter={setIsHovered.bind(setIsHovered, true)}
      onMouseLeave={setIsHovered.bind(setIsHovered, false)}
    >
      {isMobileView ?
        <TableCell colSpan={5} sx={{ borderBottom: '0px', padding: 0 }}>
          <div>
            <DescriptionCell
              isMobileView={true}
              localElement={localElement}
              setLocalElement={setLocalElement}
              element={element} rowIndex={rowIndex}
              tableIndex={tableIndex}
              trackItemCheckBoxChange={trackItemCheckBoxChange}
              trackItemRadioChange={trackItemRadioChange}
            />
            {activeRow.kind === "price" &&
              <div className="pl-2">
                {activeTable.hide_price && (isReadOnly || isLandingPage) ? null :
                  <PriceCell element={element} rowIndex={rowIndex} tableIndex={tableIndex} isMobileView={true} />}
                {activeTable.hide_quantity && (isReadOnly || isLandingPage) ? null :
                  <QuantityCell
                    element={element}
                    rowIndex={rowIndex}
                    tableIndex={tableIndex}
                    isMobileView={true}
                    calc_variables={calc_variables}
                    trackQuantityChange={trackQuantityChange}
                  />}
                <SubtotalCell element={element} rowIndex={rowIndex} tableIndex={tableIndex} isMobileView={true} calc_variables={calc_variables} />
                {activeTable.hide_value && (isReadOnly || isLandingPage) ? null :
                  <ValueCell element={element} rowIndex={rowIndex} tableIndex={tableIndex} isMobileView={true} calc_variables={calc_variables} />}
              </div>
            }
          </div>
        </TableCell>
        :
        <>
          <DescriptionCell
            localElement={localElement}
            setLocalElement={setLocalElement}
            element={element}
            rowIndex={rowIndex}
            tableIndex={tableIndex}
            trackItemCheckBoxChange={trackItemCheckBoxChange}
            trackItemRadioChange={trackItemRadioChange}
          />
          {activeRow.kind === "price" &&
            <>
              {activeTable.hide_price && (isReadOnly || isLandingPage) ? null :
                <PriceCell element={element} rowIndex={rowIndex} tableIndex={tableIndex} />}
              {activeTable.hide_quantity && (isReadOnly || isLandingPage) ? null :
                <QuantityCell
                  element={element}
                  rowIndex={rowIndex}
                  tableIndex={tableIndex}
                  calc_variables={calc_variables}
                  trackQuantityChange={trackQuantityChange}
                />}
              <SubtotalCell element={element} rowIndex={rowIndex} tableIndex={tableIndex} calc_variables={calc_variables} />
              {activeTable.hide_value && (isReadOnly || isLandingPage) ? null :
                <ValueCell element={element} rowIndex={rowIndex} tableIndex={tableIndex} calc_variables={calc_variables} />}
            </>
          }
        </>
      }
      {isHovered && !isReadOnly && <FloatingMenu onRowAddHandle={onRowAddHandle} toggleRowOptions={toggleRowOptions} />}
      {!isReadOnly && <RowOptions
        isRowOptionsOpen={isRowOptionsOpen}
        setIsRowOptionsOpen={setIsRowOptionsOpen}
        element={element}
        rowIndex={rowIndex}
        tableIndex={tableIndex}
        localElement={localElement}
        setLocalElement={setLocalElement}
        calc_variables={calc_variables}
      />}
    </TableRow>
  )
}

export default Row
import React, { useState } from "react"

import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "../../../../shared/Popover/Popover"
import SwitchInput from "../../../../shared/Inputs/SwitchInput"
import StyleSlider from "../../../../shared/Sliders/StyleSlider"
import { PlateAssessmentElement } from "../../../../plate-config/Plugins/Assessment/Assessment.plugin"
import ToolbarColorSelector from "../../../../shared/ToolbarControls/ToolbarColorSelector"
import PhIcon from "../../../../shared/Icon/PhIcon"


type Props = {
  element: PlateAssessmentElement,
  onPrimaryColorChange: (primary_color: string) => void,
  onBgOpacityChange: (value: number) => void,
  onBgBlurChange: (value: number) => void,
  onDisplayPriceChange: (hide_price: boolean) => void,
  onDisplayValueChange: (hide_value: boolean) => void,
  onRoiLockChange: (roi_locked: boolean) => void,
}

const StyleMenu = ({
  element, onDisplayPriceChange, onDisplayValueChange, onRoiLockChange, onPrimaryColorChange, onBgBlurChange, onBgOpacityChange
}: Props) => {

  // Note: local state is needed to avoid flickering when the user changes the value of the slider
  const [bgOpacity, setBgOpacity] = useState([element.assessment_detail_attributes.tint_opacity])
  const [bgBlur, setBgBlur] = useState([element.assessment_detail_attributes.background_blur])

  const onBgOpacityHandler = (val: number[]) => {
    setBgOpacity(val)
    onBgOpacityChange(val[0])
  }

  const onBgBlurHandler = (val: number[]) => {
    setBgBlur(val)
    onBgBlurChange(val[0])
  }

  return (
    <Popover>
      <PopoverTrigger>
        <div
          className={`btn btn-small btn-white ${!element.assessment_detail_attributes.roi_locked ? 'first-child' : ''}`}
        >
          <PhIcon name={"settings"} size={16} />
        </div>
      </PopoverTrigger>
      <PopoverContent sideOffset={5} align="start" alignOffset={-40}>
        <PopoverArrow />
        <div className="w-72 border-solid border border-gray-100 rounded-xl bg-white drop-shadow z-30">
          <div className="p-4">
            <div className="uppercase font-semibold text-sm text-left brand-subtext">
              Display Options
            </div>
            <SwitchInput
              label="Display Price"
              value={!element.assessment_detail_attributes.hide_price}
              setValue={onDisplayPriceChange}
              wrapperClass="mt-2"
            />
            <SwitchInput
              label="Display Value"
              value={!element.assessment_detail_attributes.hide_value}
              setValue={onDisplayValueChange}
              wrapperClass="mt-4"
            />
            <SwitchInput
              label="Lock ROI with Question"
              value={element.assessment_detail_attributes.roi_locked}
              setValue={onRoiLockChange}
              wrapperClass="mt-4"
            />
            <div className="mt-4">
              <ToolbarColorSelector
                label="Background"
                color={element.primary_color as string}
                onColorChange={onPrimaryColorChange}
                labelClassName="label"
                wrapperClassName="max-w-64"
              />
            </div>
            <StyleSlider
              label="Opacity %"
              value={bgOpacity}
              onValueChange={onBgOpacityHandler}
              min={0}
              max={100}
            />
            {!!element.image_url &&
              <>
                <div className="uppercase font-semibold text-sm text-left brand-subtext pt-6">
                  Background Image
                </div>
                <StyleSlider
                  label="Blur"
                  value={bgBlur}
                  onValueChange={onBgBlurHandler}
                  min={0}
                  max={10}
                />
              </>
            }
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default StyleMenu

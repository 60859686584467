import React from "react";

import { useAppSelector } from "../../../store/hooks/redux-hooks";
import { usePublishPageMutation } from "../../../store/reducers/api/publish_page/publish_page";
import FullScreenSpinner from "../../../shared/LoadingComponents/FullScreenSpinner";

const PageStatus: React.FC = () => {
  const { template, page_status, current_page_id } = useAppSelector((state) => state.page_navigation);
  const [publishPage, { isLoading }] = usePublishPageMutation();

  const onPublishPage = async (pageId: string) => await publishPage(pageId);

  return (
    <>
      <button
        className={`brand_btn-primary text-sm font-medium h-11 text-nowrap`}
        onClick={page_status === "active" ? undefined : onPublishPage.bind(onPublishPage, current_page_id)}
        disabled={page_status === "active"}
      >
        {template
          ? page_status === "active" ? "Template Published" : "Publish Template"
          : page_status === "active" ? "Page Published" : "Publish Page"
        }
      </button>
      {isLoading && <FullScreenSpinner />}
    </>
  );
}

export default PageStatus;
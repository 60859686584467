import React from "react";

import SwitchControl from "../../../shared/SwitchControl/SwitchControl";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/redux-hooks";
import { toggleEditorMode } from "../../../store/reducers/page_addendums/page_addendums";

const PageModeSwitcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const editorMode = useAppSelector(state => state.page_addendums.readOnly);

  const toggleEditorModeHandler = (val: boolean) => dispatch(toggleEditorMode());

  return (
    <div className="flex items-center justify-center gap-2">
      <SwitchControl
        checked={editorMode}
        onChange={toggleEditorModeHandler}
      />
      <span className="text-base font-medium pr-2 whitespace-nowrap brand-text cursor-default">
        Preview Mode
      </span>
    </div>
  );
};


export default PageModeSwitcher;
import React, { useEffect } from 'react'

import PageLayout from './components/PageLayout/PageLayout';
import { useAppDispatch } from './components/store/hooks/redux-hooks';
import { setLandingPageMode } from './components/store/reducers/page_addendums/page_addendums';
import { setAnalyticsStatus } from './components/store/reducers/page_side_effects/page_side_effects';

type Props = {
  isLandingPage?: boolean
}

const App: React.FC<Props> = ({ isLandingPage }) => {
  const dispatch = useAppDispatch()

  // ---------------------------- LANDING PAGE MODE CONTROLLER ----------------------------
  useEffect(() => {
    dispatch(setLandingPageMode(!!isLandingPage))
  }, [])
  // ---------------------------- LANDING PAGE MODE CONTROLLER ----------------------------

  // ---------------------------- ANALYTICS OBSERVER ----------------------------
  useEffect(() => {
    const targetNode = document.getElementById('react');
    if (!targetNode) {
      dispatch(setAnalyticsStatus(false))
      return;
    }
    const analyticsStatus = targetNode.getAttribute('data-analytics');
    if (!!analyticsStatus && analyticsStatus === 'true') {
      dispatch(setAnalyticsStatus(true))
    } else {
      (setAnalyticsStatus(false))
    }
  }, [])
  // ---------------------------- ANALYTICS OBSERVER ----------------------------
  return (
    <section className='app_layout' style={{ ...(isLandingPage ? { background: 'none' } : {}) }}>
      <PageLayout />
    </section>
  )
}

export default App
import React from "react";

import PhIcon, { PhIconName } from "../../Icon/PhIcon";


type Props = {
  iconName: PhIconName
  label: string
  onClick: () => void
}

const BlankCard: React.FC<Props> = ({ iconName, label, onClick }) => {
  return (
    <div
      className="bg-gray-300 h-[162px] flex flex-col items-center justify-center gap-4 rounded-2xl"
      onClick={onClick}
    >
      <PhIcon name={iconName} className="text-gray-400" size={48} />
      <span className="brand-subtext text-sm">{label}</span>
    </div>
  )
}

export default BlankCard

import React, { useState } from "react"

import { HEX_SELECTOR_INIT_COLORS, prepareColorAttributesForDelete, prepareColorAttributesForUpdate } from "../../../../../../../store/reducers/page_colors/page_color.config"
import { useUpdateCustomColorsMutation } from "../../../../../../../store/reducers/api/colors/colors.api"
import { PagePalette } from "../../../../../../../store/reducers/page_colors/page_colors"
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../../../shared/Popover/Popover"
import HexColorPicker from "../HexColorPicker/HexColorPicker"
import { textColorFromHex } from "../../../../../../../utils/color.util"
import ColorSelectorDashedButton from "../../../../../../../shared/Button/ColorSelectorDashedButton"
import ColorSelectorDiv from "../../../../../../../shared/Button/ColorSelectorDiv"
import ColorSelectorButton from "../../../../../../../shared/Button/ColorSelectorButton"
import PhIcon from "../../../../../../../shared/Icon/PhIcon"

type Props = {
  color: string,
  activePalette: PagePalette,
}

const EditExistingColor = ({
  color,
  activePalette,
}: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [isColorSelectOpen, setIsColorSelectOpen] = useState(false)
  const [updateCustomColors] = useUpdateCustomColorsMutation();

  const initialColors = HEX_SELECTOR_INIT_COLORS.filter(hex_init_color => {
    if (hex_init_color === color) { return true }
    return !activePalette.colors.includes(color)
  })
  const colorIndex = activePalette.colors.findIndex(color_to_find => color_to_find === color)

  const onColorPaletteChange = async (color: string) => {
    setIsColorSelectOpen(false)
    setPopoverOpen(false)
    await updateCustomColors(prepareColorAttributesForUpdate(color, colorIndex, activePalette.colorData || []))
  }

  const onColorPaletteDelete = async (color: string) => {
    setIsColorSelectOpen(false)
    setPopoverOpen(false)
    await updateCustomColors(prepareColorAttributesForDelete(color, activePalette.colorData || []))
  }

  const preventDefault = (e: Event) => e.preventDefault();

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal={true}>
      <PopoverTrigger>
        <ColorSelectorDiv
          wrapperClassName="group/edit-btn"
          backgroundColor={color}
          iconName="pencil"
          iconClassName={`text-${textColorFromHex(color)} opacity-0 group-hover/edit-btn:opacity-100`}

        />
      </PopoverTrigger>
      <PopoverContent
        onOpenAutoFocus={preventDefault}
        onCloseAutoFocus={preventDefault}
        side="bottom"
        align="end"
        className="color_popover_content"
      >
        {isColorSelectOpen
          ? (
            <HexColorPicker
              initialColor={color}
              handleUpdateColor={onColorPaletteChange}
              goBackHandle={setIsColorSelectOpen.bind(setIsColorSelectOpen, false)}
            />
          )
          : (
            <>
              <div className="flex justify-between items-center w-full">
                <span className="brand-text text-xs">Edit Color</span>
                {color &&
                  <button onClick={onColorPaletteDelete.bind(onColorPaletteDelete, color)} className="flex items-center p-2 rounded-lg outline-none bg-blue-500">
                    <PhIcon name="trash" size={16} color="white" />
                  </button>
                }
              </div>
              <div className="w-full">
                <div className="flex flex-wrap text-center gap-2 mt-1">
                  {initialColors.map((change_color, index) =>
                    <ColorSelectorButton
                      key={index}
                      backgroundColor={color}
                      onClick={onColorPaletteChange.bind(onColorPaletteChange, change_color)}
                      iconName={color === change_color ? "pencil" : undefined}
                      iconClassName={`text-${textColorFromHex(change_color)}`}
                    />
                  )}
                  <ColorSelectorDashedButton
                    onClick={setIsColorSelectOpen.bind(setIsColorSelectOpen, true)}
                    iconName="paintBrush"
                    iconClassName="text-black"
                  />
                </div>
              </div>
            </>
          )}
      </PopoverContent>
    </Popover >
  )
}

export default EditExistingColor

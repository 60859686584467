import React, { useEffect, useRef, forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


type Props = {
  dialogOpen: boolean;
  onDialogClose: () => void;
  dialogHeader: React.ReactNode;
  scrollableContent: React.ReactNode;
  scrollableContentId: string;
  dialogStyles?: React.CSSProperties;
  sxClassess?: any;
  dialogFooter?: React.ReactNode;
}


const ScrollableDialog = ({
  dialogOpen,
  onDialogClose,
  dialogHeader,
  scrollableContent,
  scrollableContentId,
  dialogStyles,
  sxClassess,
  dialogFooter
}: Props) => {


  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (dialogOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [dialogOpen]);
  return (
    <Dialog
      open={dialogOpen}
      onClose={onDialogClose}
      TransitionComponent={Transition}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      style={dialogStyles}
      sx={sxClassess}
    >
      {dialogHeader}
      <DialogContent
        dividers={true}
        id={scrollableContentId}
      >
        {scrollableContent}
      </DialogContent>
      {dialogFooter}
    </Dialog>
  );
}

export default ScrollableDialog;

import React from 'react';
import { withRef } from '@udecode/cn';
import { findNode, getParentNode } from '@udecode/plate-common';
import { PlateElement, isEditorReadOnly } from '@udecode/plate-common/react';
import { useSelected } from 'slate-react';

import { useAppSelector } from '../../store/hooks/redux-hooks';
import { ColumnPlugin } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';
import Menubar from './Menubar/Menubar';
import { PlateCardGroupElement } from '../../plate-config/Plugins/CardGroup/CardGroup.plugin';


const CardGroupElement = withRef<typeof PlateElement>(
  ({ children, editor, element, className, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const block = element as PlateCardGroupElement
    const isReadOnly = isEditorReadOnly(editor)
    const selected = useSelected()

    const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const parentNode = getParentNode(editor, nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
    const itemsToShow = block.children.filter(child => {
      const hasTitle = !!child.title && child.title.trim().length > 0;
      const hasContent = !!child.content && child.content.trim().length > 0;
      if (!hasTitle || !hasContent) {
        return false
      }
      return true
    })

    if (isReadOnly && itemsToShow.length === 0) return null

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={block}
        className={`
        rounded-lg transition-all duration-300 ease-in-out p-2 relative
        flex flex-wrap gap-5 justify-center items-center
        ${!isReadOnly
            ? `${selected
              ? 'border-sky-50 border hover:border-sky-50'
              : 'hover:border-sky-50 border border-transparent'}`
            : ''
          }   
        ${isElColumnChild
            ? isLastColElement ? "mb-0" : "mb-4"
            : `page-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
        ${className}`
        }
        data-plate-selectable="true"
        data-id={block.id}
        {...props}
      >
        {children}
        <Menubar showTrigger={isReadOnly ? false : selected} block={block} />
      </PlateElement>
    )
  })

export default CardGroupElement

import { TElement } from "@udecode/plate-common";
import { createPlatePlugin } from "@udecode/plate-common/react";

export const CardPlugin = createPlatePlugin({
  key: 'card',
  node: {
    type: 'card',
    isElement: true,
    isVoid: true,
  },
})

export interface PlateCardElement extends TElement {
  id: string
  type: typeof CardPlugin.key,
  title: string | null,
  content: string | null,
  emoji: string | null,
  icon: string | null,
  image_external_id: string | null
  image_source: string | null
  image_url: string | null
}

export const createDefaultCardElement = (): TElement => ({
  type: CardPlugin.key,
  children: [{ text: '' }],
  title: null,
  content: null,
  emoji: null,
  icon: null,
  image_external_id: null,
  image_source: null,
  image_url: null
})
import {
  onPageFooterBgColorChange, onPageFooterLogoVisibleChange, onPageFooterMenuDisplayChange,
  onPageFooterTextColorChange, onPageFooterVisibilityChange, onPageNavBgColorChange,
  onPageNavBtnDisplayChange, onPageNavLinksChange, onPageNavLogoVisibleChange,
  onPageNavMenuDisplayChange, onPageNavPositionChange, onPageNavTextColorChange,
  onPageNavVisibilityChange,
  onPageSocialsVisibilityChange
} from "../../page_navigation/page_navigation";
import { revvedupApi } from "../api";
import { NavigationLink, PageStyles } from "../api.types";
import { getPageDataAttributes } from "../data-attributes.util";


export const navBarEndpoints = revvedupApi.injectEndpoints({
  endpoints: (builder) => ({
    updateNavDisplayLogoAttribute: builder.mutation<any, boolean>({
      query: (nav_display_logo) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { nav_display_logo } },
        }
      },
      async onQueryStarted(nav_display_logo, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavLogoVisibleChange(nav_display_logo))
          }
        } catch (err) {
          console.log('Update nav display logo attribute error:', err);
        }
      }
    }),
    updateNavVisibilityAttribute: builder.mutation<any, boolean>({
      query: (nav_enabled) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { nav_enabled } },
        }
      },
      async onQueryStarted(nav_enabled, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavVisibilityChange(nav_enabled))
          }
        } catch (err) {
          console.log('Update nav visibility attribute error:', err);
        }
      }
    }),
    updateNavBgColorAttribute: builder.mutation<any, string | null>({
      query: (nav_background_color) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { nav_background_color } },
        }
      },
      async onQueryStarted(nav_background_color, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavBgColorChange(nav_background_color))
          }
        } catch (err) {
          console.log('Update nav bg color attribute error:', err);
        }
      }
    }),
    updateNavTextColorAttribute: builder.mutation<any, string | null>({
      query: (nav_text_color) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { nav_text_color } },
        }
      },
      async onQueryStarted(nav_text_color, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavTextColorChange(nav_text_color))
          }
        } catch (err) {
          console.log('Update nav bg color attribute error:', err);
        }
      }
    }),
    updateNavPositionAttribute: builder.mutation<any, string>({
      query: (nav_position) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { nav_position } },
        }
      },
      async onQueryStarted(nav_position, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavPositionChange(nav_position))
          }
        } catch (err) {
          console.log('Update nav position attribute error:', err);
        }
      }
    }),
    updateNavBtnDisplayAttribute: builder.mutation<any, boolean>({
      query: (nav_button_display) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { nav_button_display } },
        }
      },
      async onQueryStarted(nav_button_display, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavBtnDisplayChange(nav_button_display))
          }
        } catch (err) {
          console.log('Update nav position attribute error:', err);
        }
      }
    }),
    updateNavMenuDisplayAttribute: builder.mutation<any, boolean>({
      query: (navbar_display_menu) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { navbar_display_menu } },
        }
      },
      async onQueryStarted(navbar_display_menu, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavMenuDisplayChange(navbar_display_menu))
          }
        } catch (err) {
          console.log('Update nav position attribute error:', err);
        }
      }
    }),
    updateFooterDisplayAttribute: builder.mutation<any, boolean>({
      query: (footer_enabled) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { footer_enabled } },
        }
      },
      async onQueryStarted(footer_enabled, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageFooterVisibilityChange(footer_enabled))
          }
        } catch (err) {
          console.log('Update footer visibility attribute error:', err);
        }
      }
    }),
    updateFooterDisplayLogoAttribute: builder.mutation<any, boolean>({
      query: (footer_display_logo) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { footer_display_logo } },
        }
      },
      async onQueryStarted(footer_display_logo, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageFooterLogoVisibleChange(footer_display_logo))
          }
        } catch (err) {
          console.log('Update footer logo visibility attribute error:', err);
        }
      }
    }),
    updateFooterDisplayMenuAttribute: builder.mutation<any, boolean>({
      query: (footer_display_menu) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { footer_display_menu } },
        }
      },
      async onQueryStarted(footer_display_menu, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageFooterMenuDisplayChange(footer_display_menu))
          }
        } catch (err) {
          console.log('Update footer menu visibility attribute error:', err);
        }
      }
    }),
    updateFooterBgColorAttribute: builder.mutation<any, string | null>({
      query: (footer_background_color) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { footer_background_color } },
        }
      },
      async onQueryStarted(footer_background_color, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageFooterBgColorChange(footer_background_color))
          }
        } catch (err) {
          console.log('Update footer bg color attribute error:', err);
        }
      }
    }),
    updateFooterTextColorAttribute: builder.mutation<any, string | null>({
      query: (footer_text_color) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { footer_text_color } },
        }
      },
      async onQueryStarted(footer_text_color, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageFooterTextColorChange(footer_text_color))
          }
        } catch (err) {
          console.log('Update footer text color attribute error:', err);
        }
      }
    }),
    updateNavLinksAttribute: builder.mutation<any, NavigationLink[]>({
      query: (navigation_links_attributes) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { navigation_links_attributes } },
        }
      },
      async onQueryStarted(_navigation_links_attributes, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageNavLinksChange(data as PageStyles))
          }
        } catch (err) {
          console.log('Update nav links attribute error:', err);
        }
      }
    }),
    updateNavSocialsVisibilityAttribute: builder.mutation<any, boolean>({
      query: (social_links_bar) => {
        const requestData = getPageDataAttributes()
        if (!requestData) throw new Error('Page data attributes not found')
        return {
          url: `${requestData.base_url}/pages/${requestData.page_id}/style`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': requestData?.csrf_token as string,
          },
          body: { style: { social_links_bar } },
        }
      },
      async onQueryStarted(social_links_bar, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data) {
            dispatch(onPageSocialsVisibilityChange(social_links_bar))
          }
        } catch (err) {
          console.log('Update nav links attribute error:', err);
        }
      }
    }),
  })
})

export const {
  useUpdateNavDisplayLogoAttributeMutation,
  useUpdateNavVisibilityAttributeMutation,
  useUpdateNavBgColorAttributeMutation,
  useUpdateNavTextColorAttributeMutation,
  useUpdateNavPositionAttributeMutation,
  useUpdateNavBtnDisplayAttributeMutation,
  useUpdateNavMenuDisplayAttributeMutation,
  useUpdateFooterDisplayAttributeMutation,
  useUpdateFooterDisplayLogoAttributeMutation,
  useUpdateFooterDisplayMenuAttributeMutation,
  useUpdateFooterBgColorAttributeMutation,
  useUpdateFooterTextColorAttributeMutation,
  useUpdateNavLinksAttributeMutation,
  useUpdateNavSocialsVisibilityAttributeMutation
} = navBarEndpoints

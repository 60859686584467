import React, { useEffect, useState } from "react";
import { findNode, getParentNode } from "@udecode/plate-common";
import { isEditorReadOnly, PlateElement } from "@udecode/plate-common/react";
import { withRef } from '@udecode/cn';
import { useSelected } from "slate-react";
import validator from 'validator';

import VideoPlayer from "./VideoPlayer/VideoPlayer";
import { PlateVideoElement } from "../../plate-config/Plugins/Video/Video.plugin";
import PreviewSelector from "./PreviewSelector/PreviewSelector";
import { useAppSelector } from "../../store/hooks/redux-hooks";
import { ColumnPlugin } from "../../plate-config/Plugins/ColumnGroup/Column.plugin";
import Menubar from "./Menubar/Menubar";

export const VideoVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const isReadOnly = isEditorReadOnly(editor);
    const selected = useSelected();
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
      if (block.url) {
        if (!validator.isURL(block.url, { require_protocol: true })) {
          editor.setNodes({ url: null } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
        }
      }
    }, [])

    const block = element as PlateVideoElement;
    const nodePath = findNode(editor, { at: [], match: { id: block.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === block.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === block.id;
    const parentNode = getParentNode(editor, nodePath)!
    const parentNodeType = parentNode[0].type as string
    const isElColumnChild = parentNodeType === ColumnPlugin.key
    const isLastColElement = isElColumnChild ? parentNode[0].children[parentNode[0].children.length - 1].id === element.id : false
    const openState = isReadOnly ? false : selected

    const onSizeChange = (width: string) => editor.setNodes({ width } as Partial<Node>, { at: [], match: (n: any) => { return n.id === element.id } })
    const onUrlSubmit = (url: string) => {
      if (validateVideoUrl(url)) {
        editor.setNodes({ url } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
      } else {
        editor.setNodes({ url: null } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
      }
    }
    const onUrlChange = () => setError(false);
    const validateVideoUrl = (url: string) => {
      if (!validator.isURL(url, { require_protocol: true })) {
        setError(true);
        return false;
      }
      setError(false);
      return true;
    };

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        data-id={block.id}
        element={block}
        className={`
        ${className} p-2 rounded-lg transition-all duration-300 ease-in-out
          ${isElColumnChild
            ? isLastColElement ? "mb-0" : "mb-4"
            : `page-block video-block ${isFirstChild ? 'first-section-child' : ""} ${isLastChild ? 'last-section-child' : ""}`
          }
          ${!isReadOnly
            ? `${selected
              ? 'border-sky-50 border hover:border-sky-50'
              : 'hover:border-sky-50 border border-transparent'}`
            : ''
          } 
        `}
        data-plate-selectable
        contentEditable={false}
        suppressContentEditableWarning
        {...props}
      >
        {children}
        {
          isReadOnly
            ? block.url ? <VideoPlayer controlsVisible={openState} element={block} onSizeChange={onSizeChange} /> : null
            : block.url
              ? <VideoPlayer controlsVisible={openState} element={block} onSizeChange={onSizeChange} />
              : <PreviewSelector isElColumnChild={isElColumnChild} />
        }
        <Menubar
          block={block}
          error={error}
          onUrlChange={onUrlChange}
          onUrlSubmit={onUrlSubmit}
          showTrigger={openState}
        />
      </PlateElement>
    )
  }
);

export default VideoVoidElement;

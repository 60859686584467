import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { NavigationLink, PageData, PageStyles, SocialLink } from '../api/api.types';

export interface Router {
  id: string;
  status: string;
  name: string;
  position: number;
  tab_visible: boolean;
  origin_page_id: string | null;
  builder_link: string;
  landing_link: string;
}

export interface NavRouter extends Router {
  isActive: boolean;
}

export interface PageNavigation {
  tab_nav_active: boolean;
  nav_router: NavRouter[];
  all_pages: Router[];
  current_page_id: string | null;
  nav_background_color: string | null;
  nav_display_logo: boolean;
  nav_text_color: string | null;
  nav_enabled: boolean;
  logo_url: string | null;
  seller_id: string | null;
  room_slug: string | null;
  template: boolean;
  nav_position: string | null;
  footer_enabled: boolean;
  footer_display_logo: boolean;
  footer_background_color: string | null;
  footer_text_color: string | null;
  details_path: string | null;
  page_status: string;
  footer_display_menu: boolean;
  nav_button_display: boolean;
  nav_button: NavigationLink;
  nav_menu_buttons: NavigationLink[];
  footer_menu_buttons: NavigationLink[];
  navbar_display_menu: boolean;
  social_links: SocialLink[];
  social_links_bar: boolean;
}

const initialState: PageNavigation = {
  tab_nav_active: false,
  nav_router: [],
  all_pages: [],
  nav_background_color: null,
  nav_display_logo: false,
  nav_text_color: null,
  nav_enabled: false,
  logo_url: null,
  seller_id: null,
  room_slug: null,
  current_page_id: null,
  template: false,
  nav_position: null,
  footer_enabled: true,
  footer_display_logo: true,
  footer_background_color: null,
  footer_text_color: null,
  details_path: null,
  page_status: 'draft',
  footer_display_menu: true,
  nav_button_display: true,
  navbar_display_menu: true,
  nav_button: {
    background_color: null,
    href: null,
    kind: 'button',
    position: 0,
    text: null,
    text_color: null,
    url_type: null,
    anchor_page_id: null,
  },
  nav_menu_buttons: [],
  footer_menu_buttons: [],
  social_links: [],
  social_links_bar: false,
}


export const pageNavigationSlice = createSlice({
  name: 'page_navigation',
  initialState,
  reducers: {
    setPageNav: (state, action: PayloadAction<PageData>) => {
      const { room_pages, styles, room_slug, id, template, status, details_path } = action.payload;
      const nav_router = room_pages.reduce((acc: NavRouter[], page) => {
        if (page.tab_visible) {
          acc.push({
            ...page,
            isActive: id === page.id,
          })
        }
        return acc
      }, [])

      return {
        tab_nav_active: !!nav_router.length || !!styles?.seller?.logo_url || template,
        nav_router,
        nav_background_color: styles.nav_background_color ? styles.nav_background_color : null,
        nav_display_logo: styles.nav_display_logo,
        nav_text_color: styles.nav_text_color ? styles.nav_text_color : null,
        nav_enabled: styles.nav_enabled,
        logo_url: styles?.seller?.logo_url,
        seller_id: styles?.seller?.id,
        all_pages: room_pages,
        current_page_id: id,
        room_slug,
        template,
        page_status: status,
        footer_enabled: !!styles.footer_enabled,
        footer_display_logo: !!styles.footer_display_logo,
        footer_background_color: styles.footer_background_color ? styles.footer_background_color : null,
        footer_text_color: styles.footer_text_color ? styles.footer_text_color : null,
        footer_display_menu: !!styles.footer_display_menu,
        nav_position: styles.nav_position ? styles.nav_position : null,
        nav_button_display: !!styles.nav_button_display,
        nav_button: styles.navigation_links.find(link => link.kind === 'button') || initialState.nav_button,
        nav_menu_buttons: styles.navigation_links.filter(link => link.kind === 'navbar') || [],
        footer_menu_buttons: styles.navigation_links.filter(link => link.kind === 'footer') || [],
        navbar_display_menu: !!styles.navbar_display_menu,
        details_path,
        social_links: styles.social_links || [],
        social_links_bar: styles.social_links_bar,
      }
    },
    onPageNavBgColorChange: (state, action: PayloadAction<string | null>) => ({ ...state, nav_background_color: action.payload }),
    onPageNavTextColorChange: (state, action: PayloadAction<string | null>) => ({ ...state, nav_text_color: action.payload }),
    onPageNavLogoVisibleChange: (state, action: PayloadAction<boolean>) => ({ ...state, nav_display_logo: action.payload }),
    onPageNavVisibilityChange: (state, action: PayloadAction<boolean>) => ({ ...state, nav_enabled: action.payload }),
    onPageNavPositionChange: (state, action: PayloadAction<string>) => ({ ...state, nav_position: action.payload }),
    onPageNavButtonChange: (state, action: PayloadAction<NavigationLink>) => ({ ...state, nav_button: action.payload }),
    onPageFooterBgColorChange: (state, action: PayloadAction<string | null>) => ({ ...state, footer_background_color: action.payload }),
    onPageFooterTextColorChange: (state, action: PayloadAction<string | null>) => ({ ...state, footer_text_color: action.payload }),
    onPageFooterLogoVisibleChange: (state, action: PayloadAction<boolean>) => ({ ...state, footer_display_logo: action.payload }),
    onPageFooterVisibilityChange: (state, action: PayloadAction<boolean>) => ({ ...state, footer_enabled: action.payload }),
    onPageStatusChange: (state, action: PayloadAction<string>) => ({
      ...state,
      page_status: action.payload,
      nav_router: !!state.nav_router.length
        ? state.nav_router.map(router => router.id === state.current_page_id ? { ...router, status: action.payload } : router)
        : []
    }),
    onPageNavBtnDisplayChange: (state, action: PayloadAction<boolean>) => ({ ...state, nav_button_display: action.payload }),
    onPageNavMenuDisplayChange: (state, action: PayloadAction<boolean>) => ({ ...state, navbar_display_menu: action.payload }),
    onPageFooterMenuDisplayChange: (state, action: PayloadAction<boolean>) => ({ ...state, footer_display_menu: action.payload }),
    onPageNavLinksChange: (state, action: PayloadAction<PageStyles>) => {
      return {
        ...state,
        nav_button: {
          ...state.nav_button,
          ...action.payload.navigation_links.find(link => link.kind === 'button') || []
        },
        nav_menu_buttons: action.payload.navigation_links.filter(link => link.kind === 'navbar') || [],
        footer_menu_buttons: action.payload.navigation_links.filter(link => link.kind === 'footer') || []
      }
    },
    onPageSocialsVisibilityChange: (state, action: PayloadAction<boolean>) => ({ ...state, social_links_bar: action.payload }),
  }
})

export const {
  setPageNav, onPageNavBgColorChange, onPageNavTextColorChange, onPageNavLogoVisibleChange, onPageNavVisibilityChange,
  onPageNavPositionChange, onPageNavButtonChange, onPageFooterBgColorChange, onPageNavBtnDisplayChange, onPageNavLinksChange,
  onPageFooterTextColorChange, onPageFooterLogoVisibleChange, onPageFooterVisibilityChange, onPageFooterMenuDisplayChange,
  onPageStatusChange, onPageNavMenuDisplayChange, onPageSocialsVisibilityChange,
} = pageNavigationSlice.actions

export default pageNavigationSlice.reducer

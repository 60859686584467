import React, { useState } from "react";
import * as Menubar from "@radix-ui/react-menubar";

import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import {
  useUpdateNavBgColorAttributeMutation, useUpdateNavBtnDisplayAttributeMutation,
  useUpdateNavDisplayLogoAttributeMutation,
  useUpdateNavMenuDisplayAttributeMutation, useUpdateNavPositionAttributeMutation,
  useUpdateNavTextColorAttributeMutation, useUpdateNavVisibilityAttributeMutation
} from "../../../../store/reducers/api/navbar/navbar.api";
import ToolbarColorSelector from "../../../../shared/ToolbarControls/ToolbarColorSelector";
import ToolbarSelector from "../../../../shared/ToolbarControls/ToolbarSelector";
import BlockMenubarHeader from "../../../../shared/BlockMenubarControls/BlockMenubarHeader";
import BlockMenubarToggle from "../../../../shared/BlockMenubarControls/BlockMenubarToggle";
import BlockSidebarTrigger from "../../../../shared/BlockMenubarControls/BlockSidebarTrigger";

type Props = {
  showTrigger: boolean;
}

const NavigationSettings: React.FC<Props> = ({ showTrigger }) => {
  const [value, setValue] = useState<string>("");
  const navPageConfig = useAppSelector(state => state.page_navigation)
  const [updateNavVisibilityAttribute] = useUpdateNavVisibilityAttributeMutation()
  const [updateNavDisplayLogoAttribute] = useUpdateNavDisplayLogoAttributeMutation()
  const [updateNavBgColorAttribute] = useUpdateNavBgColorAttributeMutation()
  const [updateNavTextColorAttribute] = useUpdateNavTextColorAttributeMutation()
  const [updateNavPositionAttribute] = useUpdateNavPositionAttributeMutation()
  const [updateNavBtnDisplayAttribute] = useUpdateNavBtnDisplayAttributeMutation()
  const [updateNavMenuDisplayAttribute] = useUpdateNavMenuDisplayAttributeMutation()

  const showMenu = value ? true : showTrigger

  const onNavbarVisibilityChange = async (nav_enabled: boolean) => await updateNavVisibilityAttribute(nav_enabled)
  const onLogoVisibleChange = async (nav_display_logo: boolean) => await updateNavDisplayLogoAttribute(nav_display_logo)
  const onBackgroundColorChange = async (nav_background_color: string | null) => await updateNavBgColorAttribute(nav_background_color)
  const onTextColorChange = async (nav_text_color: string | null) => await updateNavTextColorAttribute(nav_text_color)
  const onNavPositionChange = async (value: string) => await updateNavPositionAttribute(value)
  const onShowMenuChange = async (value: boolean) => await updateNavMenuDisplayAttribute(value)
  const onShowButtonChange = async (value: boolean) => await updateNavBtnDisplayAttribute(value)
  const preventDefault = (e: Event) => e.preventDefault()

  return (
    <Menubar.Root
      value={value}
      onValueChange={setValue}
      className={`${showMenu ? "top-[4px] left-[-48px] block_sidebar_wrapper" : "hidden"}`}
      id="nav_settings_menu"
    >
      <Menubar.Menu value="nav_settings_menu">
        <Menubar.Trigger>
          <BlockSidebarTrigger icon="settings" tooltip="Settings" container="nav_settings_menu" />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content sticky="always" hideWhenDetached className="block_sidebar_wrapper-content" side="right" align="start" alignOffset={-8} sideOffset={8} >
            <Menubar.Item className="block_sidebar_item" onSelect={preventDefault}>
              <BlockMenubarHeader label="Navigation Settings" onClose={setValue.bind(setValue, '')} />
            </Menubar.Item>
            <Menubar.Item className="block_sidebar_item" onSelect={preventDefault}>
              <BlockMenubarToggle
                value={navPageConfig.nav_enabled}
                onChange={onNavbarVisibilityChange}
                label="Show Navigation Bar"
              />
            </Menubar.Item>
            {navPageConfig.nav_enabled &&
              <>
                <Menubar.Separator className="block_sidebar_separator" />
                <Menubar.Item className="block_sidebar_item" onSelect={preventDefault}>
                  <BlockMenubarToggle
                    value={navPageConfig.navbar_display_menu}
                    onChange={onShowMenuChange}
                    label="Show Menu"
                  />
                </Menubar.Item>
                <Menubar.Item className="block_sidebar_item" onSelect={preventDefault}>
                  <BlockMenubarToggle
                    value={navPageConfig.nav_display_logo}
                    onChange={onLogoVisibleChange}
                    label="Show Logo"
                  />
                </Menubar.Item>
                <Menubar.Item className="block_sidebar_item" onSelect={preventDefault}>
                  <BlockMenubarToggle
                    value={navPageConfig.nav_button_display}
                    onChange={onShowButtonChange}
                    label="Show Button"
                  />
                </Menubar.Item>
                <Menubar.Separator className="block_sidebar_separator" />
                <Menubar.Item className="block_sidebar_item MEE width" onSelect={preventDefault}>
                  <ToolbarColorSelector
                    label="Background Color"
                    color={navPageConfig.nav_background_color}
                    onColorChange={onBackgroundColorChange}
                    onColorReset={onBackgroundColorChange.bind(onBackgroundColorChange, null)}
                    wrapperClassName="w-full"
                  />
                </Menubar.Item>
                <Menubar.Item className="block_sidebar_item" onSelect={preventDefault}>
                  <ToolbarColorSelector
                    label="Text Color"
                    color={navPageConfig.nav_text_color}
                    onColorChange={onTextColorChange}
                    onColorReset={onTextColorChange.bind(onTextColorChange, null)}
                  />
                </Menubar.Item>
                <Menubar.Separator className="block_sidebar_separator" />
                <Menubar.Item className="block_sidebar_item" onSelect={preventDefault}>
                  <ToolbarSelector
                    activeValue={navPageConfig.nav_position || 'scrollable'}
                    label="Type"
                    onValueChange={onNavPositionChange}
                    data={[
                      { key: 'fixed', value: 'Fixed' },
                      { key: 'scrollable', value: 'Scrollable' }
                    ]}
                  />
                </Menubar.Item>
              </>
            }
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>
  );
}


export default NavigationSettings;
import React from "react";

import { useAppDispatch, useAppSelector } from "../../../store/hooks/redux-hooks";
import { setEditorContainerWidth } from "../../../store/reducers/page_addendums/page_addendums";
import PhIcon from "../../../shared/Icon/PhIcon";
import Tooltip from "../../../shared/ToolTip/Tooltip";


const PageWidthSelector: React.FC = ({ }) => {
  const dispatch = useAppDispatch();
  const editorContainerWidth = useAppSelector((state) => state.page_addendums.editorContainerWidth);

  const onSetEditorContainerWidth = (width: 'laptop' | 'tablet' | 'mobile') => dispatch(setEditorContainerWidth(width));

  return (
    <section className="w-[20%] flex justify-center">
      <div className="bg-gray-100 border border-[#DFD9D980] p-1 flex gap-2 rounded-xl">
        <Tooltip content={'Laptop'} side={'bottom'} sideOffset={5} container="nav_tooltip_anchor" >
          <button
            className={`
              flex items-center justify-center w-8 h-8 rounded-lg 
              ${editorContainerWidth === 'laptop' ? "bg-secondary-dark" : "bg-transparent hover:bg-white"}
              `}
            onClick={onSetEditorContainerWidth.bind(onSetEditorContainerWidth, 'laptop')}
          >
            <PhIcon name="laptop" size={18} className={editorContainerWidth === 'laptop' ? "text-white" : "text-black"} />
          </button>
        </Tooltip>
        <Tooltip content={'Tablet'} side={'bottom'} sideOffset={5} container="nav_tooltip_anchor" >
          <button
            className={`
              flex items-center justify-center w-8 h-8 rounded-lg 
               ${editorContainerWidth === 'tablet' ? "bg-secondary-dark" : "bg-transparent hover:bg-white"}
               `}
            onClick={onSetEditorContainerWidth.bind(onSetEditorContainerWidth, 'tablet')}
          >
            <PhIcon name="tablet" size={18} className={editorContainerWidth === 'tablet' ? "text-white" : "text-black"} />
          </button>
        </Tooltip>
        <Tooltip content={'Mobile'} side={'bottom'} sideOffset={5} container="nav_tooltip_anchor" >
          <button
            className={`
              flex items-center justify-center w-8 h-8 rounded-lg 
               ${editorContainerWidth === 'mobile' ? "bg-secondary-dark" : "bg-transparent hover:bg-white"}
               `}
            onClick={onSetEditorContainerWidth.bind(onSetEditorContainerWidth, 'mobile')}
          >
            <PhIcon name="smartPhone" size={18} className={editorContainerWidth === 'mobile' ? "text-white" : "text-black"} />
          </button>
        </Tooltip>
      </div>
    </section>
  );
}

export default PageWidthSelector;
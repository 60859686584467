import React, { useState } from "react";

import { Popover, PopoverContent, PopoverTrigger } from "../../../../../../../shared/Popover/Popover";
import { PagePalette } from "../../../../../../../store/reducers/page_colors/page_colors";
import { useUpdateCustomColorsMutation } from "../../../../../../../store/reducers/api/colors/colors.api";
import { HEX_SELECTOR_INIT_COLORS, prepareColorAttributesForInsert } from "../../../../../../../store/reducers/page_colors/page_color.config";
import HexColorPicker from "../HexColorPicker/HexColorPicker";
import ColorSelectorButton from "../../../../../../../shared/Button/ColorSelectorButton";
import ColorSelectorDashedButton from "../../../../../../../shared/Button/ColorSelectorDashedButton";
import ColorSelectorDashedDiv from "../../../../../../../shared/Button/ColorSelectorDashedDiv";

type Props = {
  activePalette: PagePalette,
}

const AddNewColor = ({ activePalette }: Props) => {

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [isColorSelectOpen, setIsColorSelectOpen] = useState(false)
  const [updateCustomColors] = useUpdateCustomColorsMutation();

  const initialColors = HEX_SELECTOR_INIT_COLORS.filter(color => !activePalette.colors.includes(color))

  const onColorPaletteChange = async (color: string) => {
    setIsColorSelectOpen(false)
    setPopoverOpen(false)
    await updateCustomColors(prepareColorAttributesForInsert(color, activePalette.colorData || []))
  }
  const preventDefault = (e: Event) => e.preventDefault();

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal={true}>
      <PopoverTrigger>
        <ColorSelectorDashedDiv
          iconName="plus"
          iconClassName="text-black"
        />
      </PopoverTrigger>
      <PopoverContent
        onOpenAutoFocus={preventDefault}
        onCloseAutoFocus={preventDefault}
        side="bottom"
        align="end"
        className="color_popover_content"
      >
        {isColorSelectOpen
          ? (
            <HexColorPicker
              initialColor={null}
              handleUpdateColor={onColorPaletteChange}
              goBackHandle={setIsColorSelectOpen.bind(setIsColorSelectOpen, false)}
            />
          )
          : (
            <>
              <span className="brand-text text-xs">Add Colors</span>
              <div className="flex flex-wrap mt-2 items-center">
                {initialColors.map((color, i) =>
                  <ColorSelectorButton
                    backgroundColor={color}
                    onClick={onColorPaletteChange.bind(onColorPaletteChange, color)}
                    key={i} />
                )}
                <ColorSelectorDashedButton
                  onClick={setIsColorSelectOpen.bind(setIsColorSelectOpen, true)}
                  iconName="paintBrush"
                  iconClassName="text-black"
                />
              </div>
            </>
          )}
      </PopoverContent>
    </Popover>
  )
}

export default AddNewColor;
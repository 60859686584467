import React from "react";

import { PagePalette } from "../../../../../../store/reducers/page_colors/page_colors";
import AddNewColor from "./AddNewColor/AddNewColor";
import EditExistingColor from "./EditExistingColor/EditExistingColor";
import ColorSelectorButton from "../../../../../../shared/Button/ColorSelectorButton";

type Props = {
  activePalette: PagePalette
}

const CustomColors: React.FC<Props> = ({
  activePalette
}) => {

  const isColorLimitReached = activePalette.colors.length >= 8

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap gap-1">
        {activePalette.colors.map((backgroundColor, i) =>
          <EditExistingColor
            key={i}
            color={backgroundColor}
            activePalette={activePalette}
          />
        )}
        <ColorSelectorButton
          wrapperClassName="group/fixed-btn"
          backgroundColor="black"
          iconName="lock"
          iconClassName="text-white opacity-0 group-hover/fixed-btn:opacity-100"
        />
        <ColorSelectorButton
          wrapperClassName="group/fixed-btn"
          backgroundColor="white"
          iconName="lock"
          iconClassName="text-black opacity-0 group-hover/fixed-btn:opacity-100"
        />
        {
          isColorLimitReached ?
            <div className=" flex w-full mt-2 justify-center items-center">
              <span className="brand-text text-xs">Color limit reached</span>
            </div>
            : <AddNewColor activePalette={activePalette} />}
      </div>
    </div>

  );
}

export default CustomColors;
import React, { useState } from "react";
import validator from 'validator';

type Props = {
  label: string;
  onChange: (val: string | null) => void;
  value: string | null;
  placeholder?: string;
  maxLength?: number;
  isUrl?: boolean;
  variant?: 'sidebar' | 'block';
  disabled?: boolean;
}

const BlockMenubarInput: React.FC<Props> = ({ label, onChange, value, placeholder, maxLength, isUrl, disabled }) => {
  const [val, setVal] = useState(value || "");

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value)
    if (isUrl && !validator.isURL(e.target.value, { require_protocol: true })) {
      return onChange(null)
    }
    onChange(e.target.value)
  };
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => e.stopPropagation()

  return (
    <div className="w-full flex flex-col gap-2">
      <span className="font-medium cursor-default text-sm brand-text" >
        {label}
      </span>
      <input
        value={val}
        type="text"
        className={`
          brand_input text-base
          ${maxLength === val.length ? "error" : ""}
          ${isUrl && val.length > 0 && !validator.isURL(val, { require_protocol: true }) ? "error" : ""}
           `}
        placeholder={placeholder}
        onChange={onChangeHandler}
        maxLength={maxLength}
        // it should prevent loosing focus in radix-ui elements
        onKeyDown={onKeyDownHandler}
        disabled={disabled}
      />
      {maxLength === val.length &&
        <span className="brand_input-error">{`0 characters left (max ${maxLength})`}</span>
      }
      {isUrl && val.length > 0 && !validator.isURL(val, { require_protocol: true }) &&
        <span className="brand_input-error">Link address is not correct</span>
      }
    </div>
  );
}

export default BlockMenubarInput;
import { TDescendant, TElement, TNodeEntry, findNode } from "@udecode/plate-common"
import { Node } from "slate";
import { createPlatePlugin, ParagraphPlugin, PlateEditor } from "@udecode/plate-common/react";
import { v4 as uuidv4 } from 'uuid';
import { BulletedListPlugin, NumberedListPlugin } from "@udecode/plate-list/react";
import { HEADING_KEYS } from "@udecode/plate-heading";

import { createDefaultParagraphElement } from "../DefaultMockups/DefaultMockups";
import { textNodeTypes } from "../../../utils/plate.util";
import { ButtonPlugin } from "../Button/Button.plugin";
import { ColumnPlugin, PlateColumnElement } from "../ColumnGroup/Column.plugin";
import { RecordingPlugin } from "../Recording/Recording.plugin";
import { ColumnGroupPlugin, PlateColumnGroupElement } from "../ColumnGroup/ColumnGroup.plugin";
import { VideoPlugin } from "../Video/Video.plugin";
import { EmbedPlugin } from "../Embed/Embed.plugin";
import { AiTextBlockPlugin, PlateElementAiTextBlock } from "../AiTextBlock/AiTextBlock.plugin";
import { styleCheckArr } from "../../../plate-components/ColumnGroup/ColumnLayouts/ColumnLayouts";
import { HorizontalRulePlugin } from "../HrLine/HrLine.plugin";


const extendEditor = ({ editor }) => {

  const { apply, normalizeNode, insertFragment } = editor as PlateEditor;

  // CRITICAL NOTE: do not use this method without critical need,it will break the editor!!!
  editor.insertFragment = (fragment: TDescendant[]) => {
    const fragmentToAdd = [...fragment]
    // ________________________________ Plate Plugins Serialization Start ________________________________
    // We can't actually copy/paste sections, but we can copy/paste elements inside sections
    // So we need to remove sections from the fragment and extract their children
    const fragmentWithoutSections = fragmentToAdd.map(node => {
      if (node.type === SectionPlugin.key) {
        return node.children
      }
      return node
    }).flat() as TDescendant[]
    // Add blank element before list, so editor could normalize it
    const fragmentWithNormalizedLists = [...fragmentWithoutSections].map((node, i, a) => {
      if (node?.type === BulletedListPlugin.key || node.type === NumberedListPlugin.key) {
        return [{ text: "" }, node]
      }
      return node
    }).flat() as TDescendant[]
    //  we don't need two blank elements in a row
    const fragmentWithBlankElementNormalized = [...fragmentWithNormalizedLists].map((node, i, a) => {
      if (i === 0) {
        return node
      }
      if (!node.type && node?.text === '' && !a[i - 1].type && a[i - 1].text === '') {
        return []
      }
      return node
    }).flat() as TDescendant[]
    // ________________________________ Plate Plugins Serialization End ________________________________
    insertFragment(fragmentWithBlankElementNormalized);
  }

  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    // ------------------------------ GLOBAL EDITOR CHILDREN NORMALIZATION PROCESS ------------------------------
    // first level elements should only be sections
    editor.children.map((firstLevelNode) => {
      if (firstLevelNode.type !== SectionPlugin.key) {
        editor.removeNodes({ at: [editor.children.indexOf(firstLevelNode)] })
      }
    })
    // ------------------------------ GLOBAL EDITOR CHILDREN NORMALIZATION PROCESS ------------------------------

    // ------------------------------ SECTION NORMALIZATION PROCESS ------------------------------
    // Normalize section properties that are not set (could come from API)
    if (node.type === SectionPlugin.key) {
      const section = node as PlateSectionElement;
      const propsToUpdate = {
        ...(!section.padding && { padding: 'medium' }),
        ...(section.animationCount === undefined && { animationCount: 0 }),
        ...(typeof section.tint_opacity !== 'number' && { tint_opacity: 50 }),
        ...(typeof section.background_blur !== 'number' && { background_blur: 0 }),
        ...(typeof section.card_opacity !== 'number' && { card_opacity: 50 }),
        ...(!section.tint_kind && { tint_kind: 'regular' }),
        ...(!section.image_size && { image_size: 'adjustable' }),
        ...(!section.animate_type && { animate_type: 'no-animation' }),
      } as Partial<Node>;
      editor.setNodes(propsToUpdate, { at: [], match: (n: any) => n.id === section.id })
    }
    // Add empty paragraph if section has no children (I don't use insertNode because it will 
    // trigger normalizeNode again and editor will add empty block)
    if (node.type === SectionPlugin.key) {
      const section = node as PlateSectionElement;
      if (section.children.length === 0) {
        node.children = [{ type: ParagraphPlugin.key, children: [{ text: '' }], id: uuidv4() }]
      }
    }
    // Nested elements of group nodes should not exist without parent group node
    if (node.type === SectionPlugin.key) {
      const section = node as PlateSectionElement;
      section.children.map((sectionChild) => {
        if (sectionChild.type === ColumnPlugin.key ||
          sectionChild.type === ButtonPlugin.key ||
          sectionChild.type === SectionPlugin.key
        ) {
          if (sectionChild.type === SectionPlugin.key) {
            editor.setNodes({ delete: true } as Partial<Node>, { at: [], match: (n: any) => n.id === sectionChild.id });
          }
          editor.removeNodes({ at: [], match: (n: any) => n.id === sectionChild.id });
        }
      })
    }
    // fixed sections should not have any children except recordings
    if (node.type === SectionPlugin.key) {
      const section = node as PlateSectionElement;
      const isFixedSection = section.fixed_kind === "recording"
      if (isFixedSection) {
        section.children.forEach((child) => {
          if (child?.type !== RecordingPlugin.key) {
            editor.removeNodes({ at: [...path, section.children.indexOf(child)] });
          }
        })
      }
    }
    // ------------------------------ SECTION NORMALIZATION PROCESS ------------------------------

    // ------------------------------ SECTION CHILDS MARKS NORMALIZATION PROCESS ------------------------------
    // If section node has align property we need to add align marks to all text nodes inside the section
    if (textNodeTypes.includes(node.type as string) && !node.align) {
      const section = findNode(editor, { at: [path[0]], match: { type: SectionPlugin.key } });
      if (section) {
        const align = section[0].font_alignment;
        align && editor.setNodes(
          { align } as Partial<Node>,
          { at: [], match: (n: any) => n.id === node.id }
        )
      }
    }
    // ------------------------------ SECTION CHILDS MARKS NORMALIZATION PROCESS ------------------------------
    normalizeNode([node, path]);
  };

  editor.apply = (unit: any) => {
    // ------------------------------ SECTION OPERATIONS START ------------------------------
    // Allow to delete only marked sections
    if (unit.type === "remove_node" && unit.node.type === SectionPlugin.key && unit.node.delete === true) {
      return apply(unit);
    }
    // Prevent removing unmarked sections (in case some mess between blocks)
    if (unit.type === "remove_node" && unit.node.type === SectionPlugin.key) {
      // If such event was triggered, it means that section.children array are empty, so add blank paragraph:
      if (unit.node.children.length === 0) {
        editor.insertNodes(createDefaultParagraphElement(), { at: [...unit.path, 0] });
      }
      return
    }
    // Prevent merging sections (we don't want to merge sections)
    if (unit.type === "merge_node" && unit.properties?.type === SectionPlugin.key) {
      return
    }
    // Prevent moving sections to other sections (we don't want to move sections inside sections)
    // unit.path?.length === 1 - we are always sure that 1 level elements - sections
    if (unit.type === "move_node" && unit.path?.length === 1 && unit.newPath.length > 1) {
      return
    }

    // We need to be sure that fixed section can't be deleted
    if (unit.type === "remove_node" && unit.type === SectionPlugin.key) {
      const isFixedSection = unit.node.fixed_kind === "recording"
      if (isFixedSection) {
        return
      }
    }
    // prevent node spliting
    if (unit.type === "split_node" && unit.properties.type === SectionPlugin.key) {
      return
    }
    // ------------------------------ SECTION OPERATIONS END ------------------------------
    return apply(unit);
  };

  return editor
}

export const SectionPlugin = createPlatePlugin({
  key: 'section',
  node: {
    type: 'section',
    isElement: true,
  },
  // As major plugin, it should have highest priority
  priority: 1000,
  extendEditor: extendEditor,
  // CRITICAL NOTE: it's not actual for sections, it's actual for global editor state
  normalizeInitialValue: (value) => {
    let normalizedNode = [...value.value] as any as PlateSectionElement[]
    // Step 1: Remove nodes that are not sections (first level elements should only be sections)
    normalizedNode = normalizedNode.filter((node) => node.type === SectionPlugin.key)
    // Step 2: Add missing default properties to sections (in case if editor will create new section or API will return section without some properties)
    normalizedNode = normalizedNode.map(section => {
      return {
        ...section,
        padding: section.padding || 'medium',
        animationCount: section.animationCount || 0,
        tint_opacity: typeof section.tint_opacity === 'number' ? section.tint_opacity : 50,
        background_blur: typeof section.background_blur === 'number' ? section.background_blur : 0,
        card_opacity: typeof section.card_opacity === 'number' ? section.card_opacity : 50,
        // New property "style" should replace old property width. If width is large, style should be full_width
        style: !!section.style ? section.style : (section?.width === 'large' ? 'full_width' : 'adjustable'),
        width: null,
        tint_kind: section.tint_kind || 'regular',
        image_size: section.image_size || 'adjustable',
        animate_type: section.animate_type || 'no-animation',
        ...(section.animate_type !== 'no-animation' && {
          animate_direction: section.animate_direction || 'right',
          animate_speed: section.animate_speed || 'medium',
          animate_style: section.animate_style || 'fade',
        })
      }
    })
    // Step 3: section children normalization process
    normalizedNode.forEach((node) => {
      // Step 1: Remove all children that are not elements
      node.children = node.children.filter(child => child.type)
      // Step 2: Check if there are no children in section, add default paragraph
      if (node.children.length === 0) {
        node.children.push({ type: ParagraphPlugin.key, children: [{ text: '' }], id: uuidv4() })
      }
      // Step 3: Remove all childrens from columns that are not elements
      node.children = node.children.filter(child => child.type).map(child => {
        if (child.type === ColumnGroupPlugin.key) {
          const columnGroup = { ...child } as PlateColumnGroupElement
          columnGroup.children.forEach((column: any) => {
            column.children = column.children.filter(columnChild => columnChild.type)
            if (column.children.length === 0) {
              column.children.push({ type: ParagraphPlugin.key, children: [{ text: '' }], id: uuidv4() })
            }
          }
          )
        }
        // ---------------------- Bugfixes --------------------------------
        // CRITICAL NOTE: this is a temporary solution. Ask backend to fix this issue
        // --- fix for old data types that wasn't changed during migration process ---
        if (child.type === 'h') {
          child = { ...child, type: HEADING_KEYS.h1 }
        }
        // --- fix for old data types that wasn't changed during migration process ---

        // --- fix for ai text block list items min/max values ---
        // user had ability to save incorrect values for list items min/max 
        if (child.type === AiTextBlockPlugin.key) {
          let block = { ...child } as PlateElementAiTextBlock
          if (block.details?.kind === "list") {
            if (block.details.list_items_min !== null && block.details.list_items_max !== null) {
              if (block.details.list_items_min > block.details.list_items_max) {
                block = { ...block, details: { ...block.details, list_items_max: block.details.list_items_max + 1, list_items_min: block.details.list_items_max } }
              }
            }
          }
          child = block
        }

        if (child.type === ColumnGroupPlugin.key) {
          const colGroup = { ...child } as PlateColumnGroupElement
          const colGroupChilds = [...colGroup.children].map((colChild) => {
            const colEl = { ...colChild } as PlateColumnElement
            const colElChilds = [...colEl.children].map((colElChild) => {
              if (colElChild.type === AiTextBlockPlugin.key) {
                let block = { ...colElChild } as PlateElementAiTextBlock
                if (block.details?.kind === "list") {
                  if (block.details.list_items_min !== null && block.details.list_items_max !== null) {
                    if (block.details.list_items_min > block.details.list_items_max) {
                      block = { ...block, details: { ...block.details, list_items_max: block.details.list_items_min + 1 } }
                    }
                  }
                }
                return block
              }
              return colElChild
            })
            return { ...colEl, children: colElChilds }
          })
          child = { ...colGroup, children: colGroupChilds }
        }
        // --- fix for ai text block list items min/max values ---
        // ---------------------- Bugfixes --------------------------------

        // ---------------------- Migrations --------------------------------
        // CRITICAL NOTE: this is a temporary solution. Ask backend to fix this issue

        if (child.type === ColumnGroupPlugin.key) {
          const colGroup = { ...child } as PlateColumnGroupElement
          // Should respect only structure of string with 1-3 slashes and not === to layoutConfigStyleTypes
          const stylePropCheck = !colGroup.style || !/^\d+(\/\d+){1,3}$/.test(colGroup.style)
          if (stylePropCheck) {
            // Save prev width values and use them as default
            colGroup.style = [...colGroup.children].map(colChild => Math.round(colChild.width as number)).join('/')
          }
          const colGroupChilds = [...colGroup.children].map((colChild) => {
            const colEl = { ...colChild } as PlateColumnElement
            return {
              id: colEl.id,
              type: colEl.type,
              children: colEl.children,
              image_source: colEl.image_source || null,
              image_url: colEl.image_url || null,
              image_external_id: colEl.image_external_id || null,
              bg_color: colEl.bg_color || null,
              bg_opacity: typeof colEl.bg_opacity === 'number' ? colEl.bg_opacity : 50,
              align: colEl.align || 'top',
            }
          })
          child = { ...colGroup, children: colGroupChilds }
        }
        // --- added ability to resize video and embed elements ---
        if (child.type === VideoPlugin.key) {
          child.width = child.width || '100%'
          child.align = child.align || 'center'
        }
        if (child.type === EmbedPlugin.key) {
          child.width = child.width || '100%'
          child.align = child.align || 'center'
        }
        if (child.type === HorizontalRulePlugin.key) {
          child.style = child.style || 'straight'
        }
        // --- added ability to resize video and embed elements ---
        // ---------------------- Migrations --------------------------------
        return child
      })
    })
    return normalizedNode
  }
})

export interface PlateSectionElement extends TElement {
  id: string
  name: string | null
  position: number
  padding: string | null
  width: string | null
  tint_color: string | null
  tint_kind: string | null
  tint_opacity: number | null
  background_blur: number
  fixed_kind: string | null
  image_id: string
  image_source: string
  image_external_id: string
  image_size: string
  background_image_url: string
  animate_direction: string | null
  animate_speed: string | null
  animate_style: string | null
  animate_type: string | null
  card_color: string | null
  card_opacity: number | null
  deleted_at?: string | null
  created_at?: string
  updated_at?: string
  hidden?: boolean
  animationCount: number
  font_size: string | null
  font_alignment: string | null
  font_color: string | null
  style: string | null
}

export const createDefaultSectionElement = (): TElement => ({
  type: SectionPlugin.key,
  children: [createDefaultParagraphElement()],
  padding: 'medium',
  tint_kind: 'regular',
  image_size: 'adjustable',
  animate_type: 'no-animation',
  style: "adjustable",
  tint_opacity: 50,
  background_blur: 0,
  card_opacity: 50,
  animationCount: 0
})
import React, { useState, memo, useEffect } from "react"

import ScrollableDialog from "../ScrollableDialog/ScrollableDialog";
import ImagePickerHeader from "./ImagePickerHeader/ImagePickerHeader";
import { UnsplashImage } from "./unsplash.types";
import { BrandImage, UploadedImage } from "../../store/reducers/api/api.types";
import { useAddUserImageMutation } from "../../store/reducers/api/images/images";
import UnsplashTab from "./UnsplashTab/UnsplashTab";
import UploadTab from "./UploadTab/UploadTab";


type Props = {
  backgroundImageSource: string;
  onImageChange: (image: string, imageSource: string, imageId: string) => void;
  onProgrammaticImageSet?: (image_url: string, image_source: string | null) => void;
  onImageUnset: () => void;
  onModalClose: () => void;
  brandImages?: boolean;
  modalOpen: boolean;
  currentImageUrl?: string;
}


const ImagePicker = memo(({
  backgroundImageSource, brandImages = false, onImageChange, currentImageUrl,
  onModalClose, modalOpen, onImageUnset, onProgrammaticImageSet
}: Props) => {

  const [query, setQuery] = useState('')
  const [tab, setTab] = useState("unsplash")
  const [uploadUserImage] = useAddUserImageMutation()

  useEffect(() => {
    if (backgroundImageSource) {
      backgroundImageSource === 'unsplash' ? setTab('unsplash') : setTab('upload')
    }
  }, [backgroundImageSource])

  const tabs = [
    { name: "Browse Unsplash Images", value: "unsplash" },
    { name: "Uploaded Images", value: "upload" },
  ]

  const uploadImage = async (file: File) => {
    const formData = new FormData()
    formData.append("image[uploaded_image]", file)
    await uploadUserImage(formData).then((response) => {
      if ("data" in response) {
        onImageChange(response.data.urls.regular, "upload", response.data.id)
      }
    })
    onModalClose()
  }

  const setImageFromUnsplash = (image: UnsplashImage) => {
    onImageChange(image.urls.regular, "unsplash", image.id)
    onModalClose()
  }

  const setUploadedImage = (image: UploadedImage) => {
    onImageChange(image.urls.regular, "upload", image.id)
    onModalClose()
  }

  const setImageFromBrand = (image: BrandImage) => {
    onImageChange(image.url, "brand", image.id)
    onModalClose()
  }

  const setProgrammaticImage = (image_url: string, image_source: string | null) => {
    onProgrammaticImageSet && onProgrammaticImageSet(image_url, image_source)
    onModalClose()
  }

  const onImageRemove = () => {
    onImageUnset()
    onModalClose()
  }

  const ScrollableContent = () => {
    switch (tab) {
      case "unsplash":
        return <UnsplashTab query={query} setImageFromUnsplash={setImageFromUnsplash} />
      case "upload":
        return <UploadTab
          setUploadedImage={setUploadedImage}
          uploadImage={uploadImage}
          brandImages={brandImages}
          setImageFromBrand={setImageFromBrand}
          setProgrammaticImage={setProgrammaticImage}
          programmaticImage={!!onProgrammaticImageSet}
        />
      default:
        return null
    }
  }

  return (
    <ScrollableDialog
      dialogHeader={
        <ImagePickerHeader
          activeTab={tab}
          tabs={tabs}
          onModalClose={onModalClose}
          setActiveTabHandler={setTab}
          currentImageUrl={currentImageUrl}
          onImageUnset={onImageRemove}
          query={query}
          setQuery={setQuery}
        />
      }
      dialogOpen={modalOpen}
      onDialogClose={onModalClose}
      scrollableContent={<ScrollableContent />}
      scrollableContentId="scrollable-dialog-content-id"
      dialogStyles={{ margin: '20px', marginTop: '70px' }}
      sxClassess={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "960px",
            maxWidth: "100%",
            height: '690px',
            maxHeight: '100%',
            margin: '0',
          },
          "& .MuiDialog-paper": {
            borderRadius: "32px",
            boxShadow: "0px 8px 24px 0px #00000026",
            outline: "6px solid #FFFFFF80",
            background: '#F8F5F5'
          },
          "& .MuiDialogContent-root": {
            padding: "0",
            borderTop: "unset",
            borderBottom: "unset",
          },
        },
      }}
    />
  )
})

export default ImagePicker
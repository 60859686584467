import { TElement, TNodeEntry } from "@udecode/plate-common";
import { createPlatePlugin, PlateEditor } from "@udecode/plate-common/react";

import { PlateButtonElement, ButtonPlugin, createDefaultButtonElement } from "./Button.plugin";


const extendEditor = ({ editor }) => {
  const { normalizeNode } = editor as PlateEditor;
  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    if (node.type === ButtonGroupPlugin.node.type) {
      // Rule: group should be deleted if it has no children
      const block = node as PlateButtonGroupElement;
      if (block.children.length === 0) {
        editor.removeNodes({ at: path });
        return;
      }
      // Children control - only columns allowed
      block.children.map((child) => {
        if (child.type !== ButtonPlugin.node.type) {
          editor.removeNodes({ at: [], match: (n: any) => n.id === child.id });
        }
      });
    }
    normalizeNode([node, path]);
  };
  return editor
}

export const ButtonGroupPlugin = createPlatePlugin({
  key: 'button_group',
  node: {
    type: 'button_group',
    isElement: true,
  },
  extendEditor: extendEditor,
  priority: 900
})


export interface PlateButtonGroupElement extends TElement {
  id: string
  type: typeof ButtonGroupPlugin.key
  children: PlateButtonElement[]
  align: string
  button_size: string
}

export const createDefaultButtonGroupElement = (): TElement => ({
  type: ButtonGroupPlugin.key,
  children: [createDefaultButtonElement()],
  align: 'left',
  button_size: 'medium',
})

export const createDefaultButtonGroupWith2BtnElement = (): TElement => ({
  type: ButtonGroupPlugin.key,
  children: [createDefaultButtonElement(), createDefaultButtonElement()],
  align: 'left',
  button_size: 'medium',
})

export const createDefaultButtonGroupWith3BtnElement = (): TElement => ({
  type: ButtonGroupPlugin.key,
  children: [createDefaultButtonElement(), createDefaultButtonElement(), createDefaultButtonElement()],
  align: 'left',
  button_size: 'medium',
})
import React, { useState } from 'react';
import * as Menubar from "@radix-ui/react-menubar";

import FixedToolbarButton from './FixedToolbarControls/FixedToolbarButton';
import FixedToolbarTrigger from './FixedToolbarControls/FixedToolbarTrigger';


const FixedToolbar: React.FC = () => {
  const [value, setValue] = useState<string>("");

  const preventDefault = (e: Event) => e.preventDefault()
  const preventPointerMove = (e: React.PointerEvent) => e.preventDefault()

  // NOTE: button id's used in fixed draggables.
  return (
    <Menubar.Root value={value} onValueChange={setValue} className="menubar_fixed" id='menubar_fixed' >
      <Menubar.Menu value="fixed-text-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="textAa" tooltip={'Text'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton id='paragraph' iconName='paragraph' label='Paragraph' />
              <FixedToolbarButton id='heading1' iconName='textHOne' label='Heading 1' />
              <FixedToolbarButton id='heading2' iconName='textHTwo' label='Heading 2' />
              <FixedToolbarButton id='blockquote' iconName='quotes' label='Blockquote' />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
      <Menubar.Menu value="card-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="stack" tooltip={'Cards'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton id='testimonials' iconName='testimonials' label='Testimonials' />
              <FixedToolbarButton id='cards' iconName='cards' label='Cards' />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
      <Menubar.Menu value="fixed-media-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="image" tooltip={'Media'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton id='image' iconName='image' label='Image' />
              <FixedToolbarButton id='video' iconName='videoCamera' label='Video' />
              <FixedToolbarButton id='embed' iconName='code' label='Embed' />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
      <Menubar.Menu value="fixed-list-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="list" tooltip={'Lists'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton id='bulletList' iconName='listBullets' label='Bullet List' />
              <FixedToolbarButton id='numberedList' iconName='listNumbers' label='Numbered List' />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
      <Menubar.Menu value="fixed-col-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="columns" tooltip={'Columns'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton id='2columns' iconName='numberSquareTwo' label='2 Column' />
              <FixedToolbarButton id='3columns' iconName='numberSquareThree' label='3 Column' />
              <FixedToolbarButton id='4columns' iconName='numberSquareFour' label='4 Column' />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
      <Menubar.Menu value="fixed-dash-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="minus" tooltip={'Lines'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton id='hr' iconName='minus' label='Straight' />
              <FixedToolbarButton id='hr-dashed' iconName='dashedLine' label='Dashed' />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
      <Menubar.Menu value="fixed-btn-nodes">
        <Menubar.Trigger onPointerEnter={preventPointerMove} >
          <FixedToolbarTrigger icon="arrowSquareOut" tooltip={'Buttons'} />
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content className="menubar_fixed-content" side="left" align="start" sideOffset={10}>
            <Menubar.Item
              onSelect={preventDefault}
              onPointerEnter={preventPointerMove}
              onPointerMove={preventPointerMove}
              onPointerLeave={preventPointerMove}
              className="menubar_item flex gap-2 flex-wrap items-center justify-between"
            >
              <FixedToolbarButton id='button' iconName='numberSquareOne' label='1 Button' />
              <FixedToolbarButton id='2buttons' iconName='numberSquareTwo' label='2 Button' />
              <FixedToolbarButton id='3buttons' iconName='numberSquareThree' label='3 Button' />
            </Menubar.Item>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>
  );
}

export default FixedToolbar;
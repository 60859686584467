import React, { useEffect, useRef, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import PhIcon from "../../../Icon/PhIcon";

type Props = {
  onHrefChange: (id: string) => void,
  url_type: string,
  href: string,
  anchorPageId: string | null
}

const AnchorSelector: React.FC<Props> = ({ onHrefChange, url_type, href, anchorPageId }) => {
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState<number | undefined>();
  const sections = useAppSelector((state) => state.page_sections.sections);
  const nav_router = useAppSelector((state) => state.page_navigation.nav_router);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const updateWidth = () => {
    if (elementRef.current) {
      setWidth(elementRef.current.offsetWidth);
    }
  };
  const preventDefault = (e: Event) => e.preventDefault();

  const routerData = nav_router.map(router => ({
    id: router.id as string,
    name: router.name as string,
    status: router.status as string
  })).filter(router => router.status === 'active');
  const anchorPage = routerData.find(val => val.id === anchorPageId);
  const sectionData: { id: string, name: string }[] = sections.map((section: any, i) => ({
    id: section.id as string,
    name: !!section.name ? !!section.name.trim() ? section.name : `Section ${i + 1}` : `Section ${i + 1}`,
  }))
  const activeType = url_type;
  const activeSection = sectionData.find(val => val.id === href)
  const activeSectionValue = activeSection ? activeSection.name : anchorPage ? `Section on Page: ${anchorPage.name}` : 'Select section';
  const activePage = routerData.find(val => val.id === href)
  const activePageValue = !!routerData.length ? activePage ? activePage.name : 'Select page' : 'No active pages'
  const activeValue = activeType === 'section' ? activeSectionValue : activePageValue;
  const selectorDisabled = activeType === 'page' && activePageValue === 'No active pages';
  const isAnotherPageAnchor = activeSectionValue.startsWith('Section on Page:');

  return (
    <div className="flex flex-col w-full gap-2">
      <span className="font-medium text-sm brand-text cursor-default">
        {activeType === 'page' ? 'Page' : "Anchor"}
      </span>
      <DropdownMenu.Root open={selectorDisabled ? false : open} onOpenChange={setOpen}>
        <DropdownMenu.Trigger asChild  >
          <div
            className={`
            p-2 rounded-xl border h-11 bg-white flex justify-between gap-2 items-center outline-none hover:border-gray-400 w-full
            ${selectorDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
            ${open ? "active_input text-gray-500" : "border-gray-300 brand-text"}
            `}
            ref={elementRef}
          >
            <span className="brand-text text-base font-normal capitalize">{activeValue.length > 26 ? `${activeValue.slice(0, 26)}...` : activeValue}</span>
            <PhIcon name="caretDown" size={16} className="brand-text" />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="dropdown_content max-h-[180px] z-[52]"
            align="center"
            style={{ width }}
          >
            {
              activeType === 'section'
                ?
                <>
                  {
                    isAnotherPageAnchor &&
                    <DropdownMenu.Item className="dropdown_item" onSelect={preventDefault}>
                      <span className="brand-text text-base">{activeSectionValue}</span>
                      <div> <PhIcon name="check" size={16} className="brand-text" /> </div>
                    </DropdownMenu.Item>
                  }
                  {sectionData.map(({ id, name }) => {
                    return (
                      <DropdownMenu.Item key={id} className="dropdown_item" onSelect={onHrefChange.bind(onHrefChange, id)}>
                        <span className="brand-text text-base">{name}</span>
                        {id === href && <div> <PhIcon name="check" size={16} className="brand-text" /> </div>}
                      </DropdownMenu.Item>
                    )
                  })}
                </>
                :
                <>
                  {routerData.map(({ id, name }) => (
                    <DropdownMenu.Item
                      key={id}
                      className="dropdown_item"
                      onSelect={onHrefChange.bind(onHrefChange, id)}>
                      <div className="flex items-center justify-between w-full">
                        <span className="brand-text text-base">{name}</span>
                        {id === href && <div> <PhIcon name="check" size={16} className="brand-text" /> </div>}
                      </div>
                    </DropdownMenu.Item>
                  ))}
                </>
            }
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
}

export default AnchorSelector;
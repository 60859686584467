import React, { useState, useEffect, useRef } from 'react';

interface TooltipCursorProps {
  content: string;
  children: React.ReactNode;
  delay: number;
}


const TooltipCursor: React.FC<TooltipCursorProps> = ({ children, content, delay }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltipContent, setShowTooltipContent] = useState(false);

  const tooltipRef = useRef<HTMLDivElement>(document.createElement('div'));

  useEffect(() => {
    let timeoutId;

    if (isTooltipVisible) {
      timeoutId = setTimeout(() => {
        setShowTooltipContent(true);
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isTooltipVisible, delay]);

  const handleMouseMove = (event: React.MouseEvent) => {
    const { clientX, clientY } = event;

    const tooltipWidth = tooltipRef.current?.offsetWidth || 0;
    const tooltipHeight = tooltipRef.current?.offsetHeight || 0;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let tooltipX = clientX - 45;
    let tooltipY = clientY + 25;

    if (tooltipX + tooltipWidth > viewportWidth) {
      tooltipX = clientX - tooltipWidth - 10;
    }
    if (tooltipY + tooltipHeight > viewportHeight) {
      tooltipY = viewportHeight - tooltipHeight - 10;
    }

    setTooltipPosition({ x: tooltipX, y: tooltipY });
  };

  const handleMouseEnter = () => {
    setTooltipVisible(true);
    setShowTooltipContent(false);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };
  return (
    <div
      className="min-w-min"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isTooltipVisible && (
        <div
          ref={tooltipRef}
          className={`
            fixed p-1 text-sm text-gray-300 shadow-md bg-black outline outline-gray-900 rounded-lg
            ${showTooltipContent ? 'opacity-100' : 'opacity-0'}
              `}
          style={{
            top: tooltipPosition.y,
            left: tooltipPosition.x,
            zIndex: '2147483647'
          }}
        >
          {content}
        </div>
      )}
      {children}
    </div>
  );
};



export default TooltipCursor;
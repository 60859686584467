import React from 'react';
import { select, getNodeParent } from '@udecode/plate-common';
import { useEditorRef, focusEditor } from '@udecode/plate-common/react';
import { setAlign, Alignment, } from '@udecode/plate-alignment';
import { ListItemContentPlugin } from '@udecode/plate-list/react';
import { getListItemEntry } from '@udecode/plate-list';
import * as MenuBar from "@radix-ui/react-menubar";

import BlockMenubarTrigger from '../../../shared/BlockMenubarControls/BlockMenubarTrigger';
import BlockMenubarContent from '../../../shared/BlockMenubarControls/BlockMenubarContent';
import BlockMenubarOptionList from '../../../shared/BlockMenubarControls/BlockMenubarOptionList';


type Props = {
  value?: string;
}

const Align: React.FC<Props> = ({ value }) => {
  const editor = useEditorRef();

  const nodeParent = editor.selection?.anchor?.path ? getNodeParent(editor, editor.selection?.anchor.path) : null;
  const nodeParentTypeCheck = !!nodeParent
    ? nodeParent.type === ListItemContentPlugin.key
      ? getListItemEntry(editor, { at: editor.selection?.anchor?.path })?.listItem[0]
      : nodeParent
    : null;

  const onAlignChange = (align: string) => {
    setAlign(editor, { value: align as Alignment });
    select(editor, editor.selection!);
    focusEditor(editor);
  };

  const align = nodeParentTypeCheck ? nodeParentTypeCheck.align ? nodeParentTypeCheck.align as string : 'left' : 'left';
  const alignOptions: any = [
    { key: "left", iconName: "textAlignLeft" },
    { key: "center", iconName: "textAlignCenter" },
    { key: "right", iconName: "textAlignRight" }
  ]

  return (
    <MenuBar.Menu value="float_align">
      <BlockMenubarTrigger
        isDropdown
        iconBeforeLabel={alignOptions.find(item => item.key === align)?.iconName}
        isOpened={value === 'float_align'}
        tooltip="Font Align"
        containerId="floating-toolbar"
      />
      <MenuBar.Portal>
        <BlockMenubarContent>
          <BlockMenubarOptionList
            data={alignOptions}
            onOptionClick={onAlignChange}
            activeKey={align}
          />
        </BlockMenubarContent>
      </MenuBar.Portal>
    </MenuBar.Menu>
  )
}

export default Align;
import React from 'react'

import { useAppSelector } from '../../store/hooks/redux-hooks';
import PhIcon from '../Icon/PhIcon';
import { textColorFromHex } from '../../utils/color.util';


type Props = {
  color: string | null;
  onColorChange: (color: string) => void;
  onColorReset?: () => void;
  label?: string;
}

const BlockMenubarColors: React.FC<Props> = ({ color, onColorChange, label, onColorReset }) => {

  const { activePalette } = useAppSelector(state => state.page_colors)

  const colors = [...activePalette.colors, "#000000", "#ffffff"]

  const isColorActive = (backgroundColor: string, index: number, color: string): boolean => {
    switch (true) {
      case backgroundColor === color || `color-${index}` === color:
        return true;
      case backgroundColor === "#000000" && color === "color-black":
        return true;
      case backgroundColor === "#ffffff" && color === "color-white":
        return true;
      case backgroundColor === "transparent" && color === "color-transparent":
        return true;
      default:
        return false;
    }
  }

  const colorCheck = (color: string, position: number) => {
    let colorClass: string
    switch (color) {
      case "#000000":
        colorClass = "color-black"
        break;
      case "#ffffff":
        colorClass = "color-white"
        break;
      case "transparent":
        colorClass = "color-transparent"
        break;
      default:
        colorClass = `color-${position}`
        break;
    }
    return colorClass
  }
  const onColorChangeHandle = (color: string, position: number) => onColorChange(colorCheck(color, position))

  return (
    <div className="w-full flex flex-col gap-2">
      {label && <span className="font-medium text-sm brand-font-family text-gray-700 cursor-default">{label}</span>}
      <section className={`flex gap-1 flex-wrap w-[176px]`}>
        {colors.map((backgroundColor, i) =>
          <div className="p-[2.5px] h-8 w-8" key={i}>
            <button
              className="rounded-full border border-gray-300 flex items-center justify-center w-[27.5px] h-[27.5px]"
              style={{ backgroundColor: backgroundColor }}
              onClick={onColorChangeHandle.bind(onColorChangeHandle, backgroundColor, i)}
            >
              {isColorActive(backgroundColor, i, color!) &&
                <PhIcon name={'check'} size={16} className={`text-${textColorFromHex(backgroundColor)}`} />
              }
            </button>
          </div>
        )}
        {onColorReset &&
          <div className="p-[2.5px] h-8 w-8">
            <button
              className="rounded-full border border-gray-300 flex items-center chessboard-background justify-center w-[27.5px] h-[27.5px]"
              onClick={onColorReset}
            >
              <div className="w-[1px] h-full bg-[#F46262] transform rotate-45 rounded-full" />
            </button>
          </div>}
      </section>
    </div>
  )
}

export default BlockMenubarColors
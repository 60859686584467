import React, { useState } from "react";

import PhIcon from "../Icon/PhIcon";

type Props = {
  value: string | null;
  onValueSubmit: (value: string) => void;
  onValueChange?: (value?: string) => void;
  placeholder?: string;
  error?: {
    message: string;
    link?: string;
  };
}

const MediaInput: React.FC<Props> = ({ value, onValueSubmit, placeholder, onValueChange, error }) => {

  const [inputValue, setInputValue] = useState(value || '');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onValueChange && onValueChange(e.target.value);
  }
  const onValueSubmitHandle = () => onValueSubmit(inputValue);

  return (
    <div className="flex w-full items-center relative">
      <input
        type="text"
        value={inputValue}
        onChange={onChange}
        className={`
          text-base unset_input brand-text placeholder:text-gray-400 p-1 outline-none border-none focus:border-none rounded
          focus:outline-none focus:shadow-none focus:drop-shadow-none focus:ring-0 min-w-[342px] focus:bg-gray-100
          `}
        placeholder={placeholder}
      />
      <div onClick={onValueSubmitHandle} className="mx-1 cursor-pointer" >
        <PhIcon name="check" size={16} className="brand-text" />
      </div>
      {error?.message &&
        <div
          className="p-1 text-xs flex gap-2 absolute top-[40px] left-[200px] text-nowrap 
                  bg-[#FB2C2C33] rounded-lg transform -translate-x-1/2 brand-text"
          contentEditable={false}
          suppressContentEditableWarning
        >
          <PhIcon name="sealWarning" size={16} className="text-[#F46262]" />
          {error.message}
          {error.link
            ?
            <span className="brand-text">Please, check Instructions <a href={error.link} target="blank">here</a></span>
            :
            null
          }
        </div>
      }
    </div>
  )
}

export default MediaInput;